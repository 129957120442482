import { message } from 'antd';
import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useFilteredProxyList } from '../../../state/proxy/proxy-list.atom';
import { selectAllProxies, updateSelectedProxies, toggleIsProxySelected, useSelectedProxies } from '../../../state/proxy/selected-proxies.atom';
import {
  switchConfirmBlockVisible,
  switchIsSelectProxyModeOpened,
  useIsSelectProxyModeOpened,
  useProxySelectMenuPosition,
  useIsProxyContextMenuVisible, hideProxyContextMenu,
} from '../../../state/proxy-select-menu.atom';
import { PROXY_MODES_WITH_ACTIONS_ALLOWED } from '../constants/settings';
import { copyProxies } from '../proxy-helpers';
import { DividerEl, ProxyContextMenuEl } from '../styles/context-menu';

const ContextMenu: FC = () => {
  const isProxyContextMenuVisible = useIsProxyContextMenuVisible();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const position = useProxySelectMenuPosition();
  const { selectedProxy, selectedProxies } = useSelectedProxies();

  const filteredProxies = useFilteredProxyList();
  const { t: translation } = useTranslation();

  useEffect(() => {
    const handleClick = (): void => {
      hideProxyContextMenu();
    };

    const handleOpenContextMenu = (event: MouseEvent): void => {
      const profilesTableEl = document.querySelector('.proxy-popper-container');
      if (profilesTableEl && !profilesTableEl.contains(event.target as Node)) {
        hideProxyContextMenu();
      }
    };

    document.addEventListener('contextmenu', handleOpenContextMenu);
    document.addEventListener('click', handleClick);

    return (): void => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleOpenContextMenu);
    };
  }, []);

  if (!(isProxyContextMenuVisible && selectedProxy)) {
    return null;
  }

  const areActionsAllowed = PROXY_MODES_WITH_ACTIONS_ALLOWED.includes(selectedProxy.mode);

  const handleSelectProxy = (): void => {
    toggleIsProxySelected(selectedProxy.id);
    updateSelectedProxies({
      lastSelectedProxy: selectedProxy.id,
    });

    if (!isSelectProxyModeOpened) {
      switchIsSelectProxyModeOpened(true);
    }
  };

  const handleDeleteProxy = (): void => {
    switchConfirmBlockVisible(true);
  };

  const copyProxy = (): void => {
    copyProxies([selectedProxy]);
    message.success(translation('base.copiedText'));
  };

  const handleSelectAll = (): void => {
    const proxies = filteredProxies.filter((el) => !!el.host);
    if (selectedProxies.length === proxies.length) {
      updateSelectedProxies({
        selectedProxies: [],
      });
    } else {
      selectAllProxies();
    }
  };

  return ReactDOM.createPortal(
    <ProxyContextMenuEl left={position.x} top={position.y}>
      <ul>
        {(areActionsAllowed) ? (
          <>
            <li onClick={handleSelectProxy}>
              {translation('proxies.selectProxy')}
            </li>
            {/*
            TODO: check proxy
            <li>
              {t('proxies.checkProxy')}
            </li>
            */}
            <li onClick={copyProxy}>
              {translation('proxies.copyProxy')}
            </li>
            <li onClick={handleDeleteProxy}>
              {translation('proxies.deleteProxy')}
            </li>
            <DividerEl />
          </>
        ) : null}
        <li onClick={handleSelectAll}>
          {translation('proxies.selectAllProxies')}
        </li>
      </ul>
    </ProxyContextMenuEl>,
    document.body,
  );
};

export default ContextMenu;
