import { Icon, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IProxy } from '../../../../interfaces';
import ProxyChecker from '../components/proxy-checker';
import {
  CheckProxyButtonDiv,
  ElementContainer,
  LoaderDiv,
  SaveAndNextBtn,
  Subtitle,
} from '../domElements';
import { IProxyChecked } from '../interfaces';
import { checkProxy, PROXY_CHECKED_INIT } from '../utils/check-proxy';

declare interface IHttpSocksProxyForm {
  proxy: IProxy;
  updateProxy: (partialProxy: Partial<IProxy>) => void;
  isCheckInterrupted: boolean;
  setIsCheckInterrupted: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
}

const HttpSocksProxyForm: React.FC<IHttpSocksProxyForm> = (props) => {
  const { proxy, updateProxy, isCheckInterrupted, setIsCheckInterrupted, isDisabled } = props;

  const [proxyChecked, setProxyChecked] = useState<IProxyChecked>(PROXY_CHECKED_INIT);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (isCheckInterrupted) {
      setIsLoading(false);
    }
  }, [isCheckInterrupted]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>, field: 'username' | 'password' | 'changeIpUrl'): void => {
    updateProxy({ [field]: target.value?.trim() });
  };

  const prepareToProxyChecking = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();

    setIsCheckInterrupted(false);
    setProxyChecked(PROXY_CHECKED_INIT);

    const options: any = {
      proxy,
      setProxyChecked,
    };

    const processCbs = (): void => {
      setIsLoading(true);
    };

    const resCbs = (): void => {
      setIsLoading(false);
    };

    checkProxy(options, processCbs, resCbs, translation);
  };

  const getCheckResult = (): JSX.Element | null => {
    if (proxyChecked.status !== 'success' || isLoading || isCheckInterrupted) {
      return null;
    }

    return (
      <ProxyChecker
        isLoading={isLoading}
        proxyChecked={proxyChecked}
        geolocationShowOption={false}
        profileProxy={proxy}
        initialCheck={true}
        setCurrentDisplay={(): void => {
          void 0;
        }}
        setProxyChecked={(): void => {
          void 0;
        }}
      />
    );
  };

  const getFailCheckResult = (): JSX.Element | null => {
    if (proxyChecked.status !== 'fail' || isCheckInterrupted) {
      return null;
    }

    return (
      <div style={{ color: 'red' }}>
        &nbsp;
        {proxyChecked.error}
      </div>
    );
  };

  const getLoadingSpinner = (): JSX.Element | null => {
    if (!isLoading) {
      return null;
    }

    return (
      <LoaderDiv>
        <Icon type='loading' />
      </LoaderDiv>
    );
  };

  return (
    <>
      <ElementContainer>
        <Subtitle>
          {translation('base.login')}
        </Subtitle>
        <Input
          placeholder={translation('editProfile.proxy.loginInputPlaceholder') || ''}
          style={{ width: 278 }}
          value={proxy.username}
          onChange={(event): void => handleChange(event, 'username')}
          disabled={isDisabled}
        />
      </ElementContainer>
      <ElementContainer>
        <Subtitle>
          {translation('base.password')}
        </Subtitle>
        <Input
          placeholder={translation('editProfile.proxy.passwordInputPlaceholder') || ''}
          style={{ width: 278 }}
          value={proxy.password}
          onChange={(event): void => handleChange(event, 'password')}
          disabled={isDisabled}
        />
      </ElementContainer>
      <ElementContainer>
        <Subtitle>
          {translation('proxyForm.changeIPURL')}
        </Subtitle>
        <Input
          placeholder={translation('proxyForm.changeIP') || ''}
          style={{ width: 278 }}
          value={proxy.changeIpUrl}
          onChange={(event): void => handleChange(event, 'changeIpUrl')}
          disabled={isDisabled}
        />
      </ElementContainer>
      <CheckProxyButtonDiv>
        <SaveAndNextBtn
          href=''
          type='primary'
          disabled={isDisabled || isLoading}
          onClick={prepareToProxyChecking}
        >
          {translation('editProfile.proxy.checkProxyBtnText')}
        </SaveAndNextBtn>
        {getFailCheckResult()}
      </CheckProxyButtonDiv>
      {getLoadingSpinner()}
      {getCheckResult()}
    </>
  );
};

export default HttpSocksProxyForm;
