import React, { FC, useContext } from 'react';

import {  IFolderRole } from './index';
import { canManageAdminInFolder, isEqualOrHigherRole, lowerRole, maxRole, minRole } from './roles-logic';
import { LimitedWorkspaceRole } from '../../../../interfaces/workspaces';
import { workspaceContext } from '../../../../state';
import { GologinCheckbox } from '../../../../ui/gologin-checkbox';

export interface IRoleCheckbox {
  role: LimitedWorkspaceRole;
  folders: IFolderRole[];
  onChange: (changedFolders: IFolderRole[]) => void;
}

const AllRoleCheckbox: FC<IRoleCheckbox> = (props) => {
  const { role, onChange, folders } = props;

  const workspaceCtx = useContext(workspaceContext);

  const isChecked = !!folders.length && folders.every(folder => isEqualOrHigherRole(folder.role, role));
  const { hasHigher, hasLower } = folders.reduce<{ hasHigher: boolean; hasLower: boolean }>((acc, folder) => {
    const isEqualOrHigher = isEqualOrHigherRole(folder.role, role);
    if (isEqualOrHigher) {
      acc.hasHigher = true;
    } else {
      acc.hasLower = true;
    }

    return acc;
  }, { hasHigher: false, hasLower: false });

  const isIndeterminate = hasHigher && hasLower;

  const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const changeMemberFolderRole = (folder: IFolderRole): IFolderRole => {
      if ((folder.role === 'admin' || role === 'admin') && !canManageAdminInFolder(workspaceCtx, folder.name)) {
        return folder;
      }

      const newRole = event.target.checked ?
        maxRole(folder.role, role) :
        lowerRole(minRole(folder.role, role));

      return { ...folder, role: newRole };
    };

    onChange(folders.map(changeMemberFolderRole));
  };

  return (
    <GologinCheckbox
      checked={isChecked}
      indeterminate={isIndeterminate}
      onChange={onChangeEvent}
    />
  );
};

export default AllRoleCheckbox;
