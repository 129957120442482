import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { IProxy } from '../../../../../interfaces';
import { useFreeProxies } from '../../../../../state/proxy/free-proxies.atom';
import { ProxyCheckTooltipView, showProxyCheckTooltip } from '../../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { getProxyList, updateProxyItem } from '../../../../../state/proxy/proxy-list.atom';
import { mapAndSetProxiesTableList } from '../../../../../state/proxy/proxy-table/proxies-table-list.atom';
import { sendChangeIpRequest } from '../../../../quickProfiles/api';
import { ChangeIpButton, ProxyInfoRowWrapper, ProxyOriginText } from '../styles';

interface IProxyOriginInfo {
  proxy: IProxy;
  proxyTooltipView: ProxyCheckTooltipView;
  profileId?: string;
}

const ProxyOriginInfo: React.FC<IProxyOriginInfo> = (props) => {
  const { proxy, proxyTooltipView, profileId = '' } = props;

  const freeProxies = useFreeProxies();

  if (!(proxy.host && proxy.status)) {
    return null;
  }

  let { host } = proxy;
  if (proxy.mode === 'gologin') {
    const freeProxy = freeProxies.find(({ region }) => region === proxy.autoProxyRegion);
    if (freeProxy) {
      ({ host } = freeProxy);
    }
  }

  const changeIp: React.MouseEventHandler = async (event): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    if (!proxy.changeIpUrl) {
      return;
    }

    addProxyStatuses([proxy], [profileId]);

    await sendChangeIpRequest(proxy.changeIpUrl).catch(() => null);
    const proxyChecked = await updateProxyStatuses([proxy], profileId).catch(() => null);

    removeProxyStatuses([proxy], [profileId]);
    if (!proxyChecked) {
      return;
    }

    const currentProxyList = getProxyList();
    const currentProxyFromList = currentProxyList.find(proxyFromList => proxyFromList.id === proxy.id);
    const proxyUpdated = { ...proxy, ...currentProxyFromList, ...proxyChecked, checkDate: moment().toDate() };

    updateProxyItem(proxyUpdated);
    showProxyCheckTooltip({
      proxies: [proxyUpdated],
      profileIds: [profileId],
      view: proxyTooltipView,
      timeout: 2000,
    });

    mapAndSetProxiesTableList((prevProxies) => prevProxies.map((prevProxy) => {
      if (prevProxy.id === proxyUpdated.id) {
        return { ...prevProxy, ...proxyUpdated };
      }

      return prevProxy;
    }));
  };

  return (
    <ProxyInfoRowWrapper style={{ maxWidth: 288 }}>
      <ProxyOriginText>
        {proxy.origin || proxy.lastIp || host}
      </ProxyOriginText>
      {' '}
      {proxy.changeIpUrl ? (
        <ChangeIpButton onClick={changeIp}>
          <Trans i18nKey='proxies.changeIp' />
        </ChangeIpButton>
      ) : null}
    </ProxyInfoRowWrapper>
  );
};

export default ProxyOriginInfo;
