import { message } from 'antd';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import {
  ProxyGroupExpandButton,
  ProxyGroupExpandButtonWrapper,
  ProxyGroupHeaderWrapper,
  ProxyGroupHint,
} from './styles';
import { GeoProxyType } from '../../../../../../common/constants/types';
import { getGeoProxyLastSelectedType } from '../../../../../state/proxy/geoproxy-form-data.atom';
import { updateProxyStatuses } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IGroupedProxy, IProxyGroupHeader as IProxyGroupHeaderEntity } from '../../../../../state/proxy/proxy-groups/interfaces';
import { findUnusedGeoProxy } from '../../../../../state/proxy/proxy-groups/proxy-groups.atom';
import { toggleProxyGroup, useVisibleProxyGroupIds } from '../../../../../state/proxy/proxy-groups/proxy-visible-groups.atom';
import { closeProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { createGeoProxy } from '../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { linkProfileProxy } from '../../../../../state/proxy/proxy-operations/link-proxy.operations';
import { IconArrowDown } from '../../../../../ui/gologin-header/icons/icon-arrow-down';
import ProxyFlag from '../../../proxy-flag';
import { ProxyTitleWrapper } from '../ungrouped-proxy-item/styles';

const GROUP_HEADER_HINTS = {
  viewAvailableServers: 'proxies.groupHeaderHint.viewAvailableServers',
  connectToPrivateServer: 'proxies.groupHeaderHint.connectToPrivateServer',
} as const;

const GROUP_HEADER_HINTS_LIST = Object.values(GROUP_HEADER_HINTS);
type GroupHeaderHint = (typeof GROUP_HEADER_HINTS_LIST)[number];

type ProxyGroupHeaderProps = {
  proxyGroupHeader: IProxyGroupHeaderEntity;
  currentProfileId: string|null;
  // keeping `isCurrentProxyGroup` for now, because the design is not final for this part
  isCurrentProxyGroup: boolean;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
}

const ProxyGroupHeader: React.FC<ProxyGroupHeaderProps> = (props) => {
  const { proxyGroupHeader, currentProfileId = '', isCurrentProxyGroup, availableTypes, style } = props;

  const visibleProxyGroupIds = useVisibleProxyGroupIds();

  const [groupHeaderHintKey, setGroupHeaderHintKey] = useState<GroupHeaderHint|null>(null);

  const finalStyle: React.CSSProperties = {
    ...style,
    paddingLeft: 0,
    height: 38,
    margin: '1px 4px',
  };

  const handleMouseOverRow: React.MouseEventHandler<HTMLDivElement> = () =>
    setGroupHeaderHintKey(GROUP_HEADER_HINTS.connectToPrivateServer);

  const handleMouseOverArrow: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setGroupHeaderHintKey(GROUP_HEADER_HINTS.viewAvailableServers);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => setGroupHeaderHintKey(null);

  const handleGroupRowClick: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    closeProxyManager();
    if (!(currentProfileId && proxyGroupHeader)) {
      return;
    }

    const { groupId } = proxyGroupHeader;
    const unusedGeoProxy = findUnusedGeoProxy(groupId);
    if (unusedGeoProxy) {
      // TODO: consider shared proxies before the proxyGroups release
      const selectedUnusedProxy: IGroupedProxy = { ...unusedGeoProxy, profilesCount: unusedGeoProxy.profilesCount + 1 };
      await Promise.all([
        updateProxyStatuses([selectedUnusedProxy], currentProfileId, false),
        linkProfileProxy(currentProfileId, selectedUnusedProxy),
      ]);

      return;
    }

    const geoproxyTypeAddedLast = getGeoProxyLastSelectedType();
    if (!availableTypes.includes(geoproxyTypeAddedLast)) {
      message.error(<Trans i18nKey='tableProfiles.notification.proxyNotFoundForCountryAndType' />);
      // TODO: sendReactErrorToSentry({ ... }) before the proxyGroups release

      return;
    }

    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      country: proxyGroupHeader.country,
      connectionType: geoproxyTypeAddedLast,
      profileId: currentProfileId,
      checkTooltipView: 'selector-profile-table', // TODO: test before the proxyGroups release
    });

    if (typeof geoProxyFormSubmitResult === 'string') {
      message.error(geoProxyFormSubmitResult);
    }
  };

  const handleGroupArrowClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    toggleProxyGroup(proxyGroupHeader.groupId);
  };

  return (
    <ProxyGroupHeaderWrapper
      style={finalStyle}
      isAlwaysOpaque={true}
      onClick={handleGroupRowClick}
      onMouseOver={handleMouseOverRow}
      onMouseLeave={handleMouseLeave}
    >
      <ProxyGroupExpandButtonWrapper>
        <ProxyGroupExpandButton
          onClick={handleGroupArrowClick}
          onMouseOver={handleMouseOverArrow}
          isRotated={visibleProxyGroupIds.includes(proxyGroupHeader.groupId)}
        >
          <IconArrowDown
            padding={0}
            styleType='darkGray'
            iconColor='var(--767676-proxy-group-header-arrow)'
          />
        </ProxyGroupExpandButton>
      </ProxyGroupExpandButtonWrapper>
      <ProxyFlag
        countryCode={proxyGroupHeader.country}
        width={18}
      />
      <ProxyTitleWrapper newStyle={true}>
        <Trans i18nKey={`proxy.countries.${proxyGroupHeader.country.toLowerCase()}`} />
      </ProxyTitleWrapper>
      {groupHeaderHintKey ? (
        <ProxyGroupHint>
          <Trans i18nKey={groupHeaderHintKey} />
        </ProxyGroupHint>
      ) : null}
    </ProxyGroupHeaderWrapper>
  );
};

export default ProxyGroupHeader;
