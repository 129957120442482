import React from 'react';
import { useTranslation } from 'react-i18next';

import ProxyOriginInfo from './proxy-origin-info';
import { getProxyInfoCountry } from './utils';
import { IProxy } from '../../../../../interfaces';
import { ProxyCheckTooltipView } from '../../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { ProxyInfoRowWrapper } from '../styles';

interface IProxyInfoSuccess {
  proxy: IProxy;
  proxyTooltipView: ProxyCheckTooltipView;
  profileId?: string;
}

const ProxyInfoSuccess: React.FC<IProxyInfoSuccess> = (props) => {
  const { proxy, proxyTooltipView, profileId } = props;

  const { t: translation } = useTranslation();

  const proxyInfoCountry = getProxyInfoCountry(proxy, translation);

  return (
    <>
      {proxyInfoCountry ? (
        <ProxyInfoRowWrapper>
          {proxyInfoCountry}
        </ProxyInfoRowWrapper>
      ) : null}
      <ProxyOriginInfo
        proxy={proxy}
        proxyTooltipView={proxyTooltipView}
        profileId={profileId}
      />
    </>
  );
};

export default ProxyInfoSuccess;
