import styled from '@emotion/styled';
import { Button, Table } from 'antd';

export const DEFAULT_COOKIES_BORDER_COLOR = 'var(--D9D9D9)';

export const TableProfile = styled(Table)`
  thead > tr > th {
    background-color: var(--FFFFFF);
  }
  .ant-table-thead > tr > th {
    border-bottom: 0 solid var(--E8E8E7);
  }
`;

export const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ContainerNameCol = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: baseline;
`;

export const ButtonImport = styled(Button)`
  font-size: 12px;
  color: var(--1FC47D);
  border-color: var(--1FC47D);
  border-radius: 2px;
  :hover {
    opacity: 0.7;
  }
`;

export const CookiesTextArea = styled('textarea')`
  background: var(--FFFFFF);
  width: 100%;
  height: 150px;
  resize: none;
  padding: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  &:focus {
    outline: none !important;
  }
  &::placeholder {
    font-size: 12px;
    color: ${DEFAULT_COOKIES_BORDER_COLOR};
  }
`;

export const DrugAndDropCookiesContainer = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px dashed ${DEFAULT_COOKIES_BORDER_COLOR};
  border-radius: 3px;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
`;

export const JsonTitleTxt = styled('ins')`
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px dashed var(--000000);
`;
