import React from 'react';

import { ItemRowActive, PriceText } from './styles';
import { GeoProxyType } from '../../../../../common/constants/types';
import { IGeolocationProxyPrices } from '../../../../interfaces';
import { getPaymentURL } from '../api';

const isElectron = !!window.require;

type MenuItemsProps = {
  price: IGeolocationProxyPrices;
  geoProxyType: GeoProxyType;
  count: number;
  onClose: () => void;
}

const PriceItem: React.FC<MenuItemsProps> = (props) => {
  const { price, geoProxyType, count, onClose } = props;

  const calculatePrice = (): string => {
    const proxyPrice = price[geoProxyType];

    return (proxyPrice * count / 100).toFixed(0);
  };

  const handleMenuClick = async (): Promise<void> => {
    onClose();

    const { checkoutUrl } = await getPaymentURL({
      dataGbCount: count,
      dataType: geoProxyType,
      path: window.location.hash,
    });

    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutUrl);

      return;
    }

    window.location.replace(checkoutUrl);
  };

  return (
    <ItemRowActive onClick={handleMenuClick}>
      {count}
      &nbsp;GB
      <PriceText>
        $
        {calculatePrice()}
      </PriceText>
    </ItemRowActive>
  );
};

export default PriceItem;

