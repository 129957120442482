import Popper from '@material-ui/core/Popper';
import React from 'react';

import ProxyInfo from './proxy-info';
import { IProxy } from '../../../interfaces';
import {
  ProxyCheckTooltipView,
  useIsProxyCheckTooltipVisible,
  useProxyCheckTooltipProxies,
} from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { TooltipEl, TooltipWrapperEl } from '../styles/check-proxy-btn';

interface ICheckProxyTooltip {
  proxy: IProxy;
  profileId?: string;
  proxyTooltipView: ProxyCheckTooltipView;
  tooltipRef: React.RefObject<HTMLDivElement>;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
}

const CheckProxyTooltip: React.FC<ICheckProxyTooltip> = (props) => {
  const { proxy, profileId = '', proxyTooltipView, tooltipRef, selectorContainerElement } = props;

  const isTooltipVisible = useIsProxyCheckTooltipVisible(proxy, profileId, proxyTooltipView);
  const proxies = useProxyCheckTooltipProxies();
  const { containerElement: selectorContainerElementFromState } = useProxyManagerState();
  const theme = useCurrentTheme();
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  // we need selectorContainerElement if proxy-manager is not opened at all
  const refElement = selectorContainerElement ? selectorContainerElement.current : selectorContainerElementFromState;
  if (!(proxies.length && isTooltipVisible && tooltipRef.current && refElement)) {
    return null;
  }

  return (
    <Popper
      open={isTooltipVisible}
      anchorEl={tooltipRef.current}
      style={{ zIndex: 1070 }}
      placement='top'
    >
      <TooltipWrapperEl>
        <TooltipEl isDarkTheme={isDarkTheme}>
          <ProxyInfo
            proxy={proxy}
            profileId={profileId}
            proxyTooltipView={proxyTooltipView}
            selectorContainerElement={refElement}
          />
        </TooltipEl>
      </TooltipWrapperEl>
    </Popper>
  );
};

export default CheckProxyTooltip;
