import React, { useRef, useState } from 'react';

import CheckProxyStatusDot from './check-proxy-status-dot';
import { CheckProxyButtonWrapper } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IProxy } from '../../../interfaces';
import {
  ProxyCheckTooltipView,
  hideProxyCheckTooltip,
  showProxyCheckTooltip,
  useIsProxyCheckTooltipVisible,
} from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { useIsProxyChecking } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import CheckProxyTooltip from '../check-proxy-tooltip';

interface ICheckProxyBtn {
  proxy: IProxy;
  profileId: string;
  proxyTooltipView: ProxyCheckTooltipView;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
  isSharedProxy?: boolean;
}

const CheckProxyBtn: React.FC<ICheckProxyBtn> = (props) => {
  const { proxy, profileId, proxyTooltipView, selectorContainerElement, isSharedProxy = false } = props;

  const isChecking = useIsProxyChecking(proxy, profileId);
  const isTooltipVisible = useIsProxyCheckTooltipVisible(proxy, profileId, proxyTooltipView);

  const [isSpinnerHovered, setIsSpinnerHovered] = useState<boolean>(false);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (): void => {
    setIsSpinnerHovered(true);
    showProxyCheckTooltip({
      profileIds: [profileId || ''],
      proxies: [proxy],
      view: proxyTooltipView,
    });
  };

  const handleMouseLeave = (): void => {
    setIsSpinnerHovered(false);
    hideProxyCheckTooltip();
  };

  const isButtonCheckHidden = proxy.mode === 'none';
  if (isButtonCheckHidden) {
    return (
      <CheckProxyButtonWrapper
        isTooltipVisible={isTooltipVisible}
        newStyle={!!NEW_FEATURES.header}
      />
    );
  }

  const isFreeOrTor = ['gologin', 'tor'].includes(proxy.mode);
  const isSpinnerVisible = !isFreeOrTor && (isChecking || isSpinnerHovered);
  const isCheckButtonVisible = isChecking || isTooltipVisible || proxyTooltipView !== 'selector-profile-table';

  return (
    <CheckProxyButtonWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(event): void => event.stopPropagation()}
      ref={tooltipRef}
      newStyle={!!NEW_FEATURES.header}
      isTooltipVisible={isTooltipVisible}
      isCheckButtonVisible={isCheckButtonVisible}
    >
      <CheckProxyStatusDot
        proxy={proxy}
        isSpinnerVisible={isSpinnerVisible}
        isChecking={isChecking}
        profileId={profileId}
        proxyTooltipView={proxyTooltipView}
        isSharedProxy={isSharedProxy}
      />
      <CheckProxyTooltip
        proxy={proxy}
        profileId={profileId}
        proxyTooltipView={proxyTooltipView}
        tooltipRef={tooltipRef}
        selectorContainerElement={selectorContainerElement}
      />
    </CheckProxyButtonWrapper>
  );
};

export default CheckProxyBtn;
