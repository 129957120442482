import React, { FC } from 'react';

import { ProxyCheckboxWrapperEl } from './styles/proxy-item-checkbox';
import { IProxy } from '../../../../interfaces';
import { useFilteredProxyList } from '../../../../state/proxy/proxy-list.atom';
import { useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { GologinCheckbox } from '../../../../ui/gologin-checkbox';
import { selectProxiesWithShift } from '../../proxy-helpers';

interface IProps {
  proxy: IProxy;
}

const ProxyItemCheckbox: FC<IProps> = (props) => {
  const { proxy } = props;
  const { lastSelectedProxy, selectedProxies } = useSelectedProxies();
  const filteredProxies = useFilteredProxyList();

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    selectProxiesWithShift({
      shiftKey: event.nativeEvent.shiftKey,
      selectedProxies,
      proxiesToSelectFrom: filteredProxies,
      proxy,
      lastSelectedProxy,
    });
  };

  return (
    <ProxyCheckboxWrapperEl>
      <GologinCheckbox onChange={onCheck} checked={selectedProxies.includes(proxy.id)} />
    </ProxyCheckboxWrapperEl>
  );
};

export default ProxyItemCheckbox;
