import { atom, getDefaultStore, useAtomValue } from 'jotai';

import {
  EMPTY_PROXY_GROUPS,
  EMPTY_PROXY_LIST_ROW,
  PROXY_GROUP_ADD_BUTTON_ID_POSTFIX,
  PROXY_GROUP_HEADER_ID_POSTFIX,
  PROXY_MANAGER_LIST_SECTION_ID_POSTFIX,
} from './constants';
import {
  getIsProxyGroup,
  IGroupedProxy,
  IProxyGroup,
  IProxyGroupsObject,
  IProxyManagerListEntity,
  IProxyManagerListEntityBase,
  IUngroupedProxy,
  ProxyGroupMode,
} from './interfaces';
import { proxyGroupsSorterPersistentAtom } from './proxy-groups-sorting.atom';
import { proxySelectionDatesPersistentAtom } from './proxy-selection-dates.atom';
import { visibleProxyGroupModesAtom } from './proxy-visible-group-modes.atom';
import { visibleProxyGroupIdsAtom } from './proxy-visible-groups.atom';
import { GEOPROXY_MODE } from '../../../../common/constants/constants';
import { GeoProxyType, determineIsGologinProxy } from '../../../../common/constants/types';
import {
  CURRENT_PROFILE_GEOPROXY_COUNTRY_EMPTY_WARNING,
  CURRENT_PROFILE_GEOPROXY_COUNTRY_EMPTY_WARNING_MESSAGE,
  PROXY_GROUP_ID_TAG,
  PROXY_GROUP_NOT_FOUND_THEREFORE_CREATED_WARNING,
  PROXY_GROUP_NOT_FOUND_THEREFORE_CREATED_WARNING_MESSAGE,
  PROXY_ID_TAG,
} from '../../../features/proxy/constants';
import { getProxyGroupId } from '../../../features/proxy/utils/proxy-id';
import { IProxy } from '../../../interfaces';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { NEW_FEATURES } from '../../feature-toggle/new-features';
import { currentProfileProxyAtom } from '../current-profile-proxy.atom';
import { geoProxyCountriesAtom } from '../geoproxy-countries.atom';
import { searchedProxiesAtom } from '../proxy-search.atom';
import { isSelectProxyModeOpenedAtom } from '../proxy-select-menu.atom';

interface IGeoProxyEmptyGroupParams extends Pick<IProxyManagerListEntityBase, 'groupId'|'country'|'selectionDate'> {
  types: GeoProxyType[];
}

const generateGeoProxyEmptyGroup = ({
  groupId,
  country,
  selectionDate,
  types,
}: IGeoProxyEmptyGroupParams): IProxyGroup => {
  const proxyManagerEntityBase: Omit<IProxyManagerListEntityBase, 'id'> = {
    groupId,
    country,
    mode: GEOPROXY_MODE,
    selectionDate,
  };

  return {
    groupId,
    header: {
      ...proxyManagerEntityBase,
      id: groupId + PROXY_GROUP_HEADER_ID_POSTFIX,
      types,
    },
    proxies: [],
    addButton: {
      ...proxyManagerEntityBase,
      id: groupId + PROXY_GROUP_ADD_BUTTON_ID_POSTFIX,
      types,
    },
  };
};

const proxyGroupsAtom = atom<IProxyGroupsObject>((get) => {
  const searchedProxies = get(searchedProxiesAtom);
  if (!searchedProxies.length) {
    return EMPTY_PROXY_GROUPS;
  }

  const proxyGroupsSorter = get(proxyGroupsSorterPersistentAtom);
  const selectionDates = get(proxySelectionDatesPersistentAtom);
  const geoProxyCountries = get(geoProxyCountriesAtom);

  const emptyGeoProxyGroups = geoProxyCountries.reduce<Record<string, IProxyGroup>>((acc, countryObject) => {
    const geoProxyCountry = countryObject.countryCode;
    const groupId = getProxyGroupId(GEOPROXY_MODE, geoProxyCountry);
    const selectionDate = selectionDates[groupId] || 0;

    acc[groupId] = generateGeoProxyEmptyGroup({
      groupId,
      country: geoProxyCountry,
      selectionDate,
      types: countryObject.types,
    });

    return acc;
  }, {});

  const proxyGroups = searchedProxies.reduce<IProxyGroupsObject>((acc, proxy) => {
    if (proxy.isInvisible) {
      return acc;
    }

    const country = proxy.country || proxy.autoProxyRegion || proxy.torProxyRegion || '';
    const groupId = getProxyGroupId(proxy.mode, country);
    const selectionDate = selectionDates[proxy.id] || selectionDates[groupId] || 0;
    const proxyBase: Omit<IGroupedProxy, 'groupId'> = { ...proxy, selectionDate, country };

    if (proxy.mode === GEOPROXY_MODE) {
      const groupedProxy: IGroupedProxy = { ...proxyBase, groupId };
      const proxyGroup = acc.geolocationProxies[groupId];
      if (!proxyGroup) {
        sendReactErrorToSentry({
          transactionName: PROXY_GROUP_NOT_FOUND_THEREFORE_CREATED_WARNING,
          message: PROXY_GROUP_NOT_FOUND_THEREFORE_CREATED_WARNING_MESSAGE,
          tags: [
            [PROXY_GROUP_ID_TAG, groupId],
            ['groups-length', Object.keys(acc.geolocationProxies).length],
          ],
          level: 'warning',
        });

        acc.geolocationProxies[groupId] = generateGeoProxyEmptyGroup({
          groupId,
          country,
          selectionDate,
          types: [],
        });
      }

      acc.geolocationProxies[groupId].proxies = [...acc.geolocationProxies[groupId].proxies, groupedProxy]
        .sort((current, next) => next[proxyGroupsSorter.field] - current[proxyGroupsSorter.field]);

      return acc;
    }

    const ungroupedProxy: IUngroupedProxy = { ...proxyBase, groupId: null };
    switch (proxy.mode) {
      case 'gologin':
        acc.gologinProxies.push(ungroupedProxy);
        break;
      case 'tor':
        acc.torProxies.push(ungroupedProxy);
        break;
      default:
        acc.userProxies.push(ungroupedProxy);
        break;
    }

    return acc;
    // do not do `...EMPTY_PROXY_GROUPS` in the accumulator, as it causes cloning proxies in the list
  }, { geolocationProxies: emptyGeoProxyGroups, gologinProxies: [], torProxies: [], userProxies: [] });

  return proxyGroups;
});

const moveProxyToBeginning = <ProxyType extends IUngroupedProxy|IGroupedProxy|IProxy>(proxies: ProxyType[], proxyToMove: ProxyType): ProxyType[] => {
  const filteredProxies = proxies.filter(({ id }) => proxyToMove.id !== id);

  return [proxyToMove, ...filteredProxies];
};

const removeCurrentProxyFromGroups = (proxyGroups: IProxyGroupsObject, currentProxy: IProxy): IProxyGroupsObject => {
  const updatedProxyGroups = { ...proxyGroups };
  const country = currentProxy.country || currentProxy.autoProxyRegion || currentProxy.torProxyRegion || '';
  const proxyGroupMode: ProxyGroupMode = determineIsGologinProxy(currentProxy) ? `${currentProxy.mode}Proxies` : 'userProxies';
  if (proxyGroupMode !== 'geolocationProxies') {
    updatedProxyGroups[proxyGroupMode] = updatedProxyGroups[proxyGroupMode].filter(({ id }) => currentProxy.id !== id);

    return updatedProxyGroups;
  }

  if (!country) {
    sendReactErrorToSentry({
      transactionName: CURRENT_PROFILE_GEOPROXY_COUNTRY_EMPTY_WARNING,
      message: CURRENT_PROFILE_GEOPROXY_COUNTRY_EMPTY_WARNING_MESSAGE,
      tags: [[PROXY_ID_TAG, currentProxy.id]],
      level: 'warning',
    });
  }

  const groupId = getProxyGroupId(currentProxy.mode, country);

  const proxyGroupsObject: IProxyGroupsObject = {
    ...updatedProxyGroups,
    geolocationProxies: {
      ...updatedProxyGroups.geolocationProxies,
      [groupId]: {
        ...updatedProxyGroups.geolocationProxies[groupId],
        proxies: [...updatedProxyGroups.geolocationProxies[groupId].proxies]
          .filter(({ id }) => id !== currentProxy.id),
      },
    },
  };

  return proxyGroupsObject;
};

// TODO: remove `IProxy` from `IProxy|IProxyManagerListEntity` here and below,
// when 100% users are migrated to groups
const proxyManagerListEntitiesAtom = atom<(IProxy|IProxyManagerListEntity|null)[]>((get) => {
  const isSelectProxyModeOpened = get(isSelectProxyModeOpenedAtom);
  const currentProxy = get(currentProfileProxyAtom);

  const isCurrentProxyPresentAndVisible = currentProxy && currentProxy.mode !== 'none';

  if (!NEW_FEATURES.proxyGroups) {
    let searchedProxies = get(searchedProxiesAtom);
    if (isCurrentProxyPresentAndVisible) {
      searchedProxies = moveProxyToBeginning(searchedProxies, currentProxy);
    }

    // to keep the last proxy visible right above the proxy-manager multi-select panel
    return isSelectProxyModeOpened ? [...searchedProxies, EMPTY_PROXY_LIST_ROW] : searchedProxies;
  }

  const proxyGroupsSorter = get(proxyGroupsSorterPersistentAtom);
  const visibleProxyGroupModes = get(visibleProxyGroupModesAtom);
  const visibleProxyGroupIds = get(visibleProxyGroupIdsAtom);
  let proxyGroups = get(proxyGroupsAtom);
  if (isCurrentProxyPresentAndVisible) {
    proxyGroups = removeCurrentProxyFromGroups(proxyGroups, currentProxy);
  }

  const { geolocationProxies, torProxies, gologinProxies, userProxies } = proxyGroups;
  const geoProxyEntities = Object.values(geolocationProxies);

  const allProxies = Object
    .entries({ userProxies, geolocationProxies: geoProxyEntities, torProxies, gologinProxies })
    .reduce<(IUngroupedProxy | IProxyGroup)[]>((acc, [proxyGroupMode, proxyGroupModeProxies]) => {
      if (visibleProxyGroupModes.includes(proxyGroupMode)) {
        acc.push(...proxyGroupModeProxies);
      }

      return acc;
    }, []).flat();

  const sortedProxies = allProxies.sort((current, next) => {
    let [currentSortValue, nextSortValue] = [0, 0];
    if (getIsProxyGroup(current)) {
      currentSortValue = current.header[proxyGroupsSorter.field];
    } else {
      currentSortValue = current[proxyGroupsSorter.field];
    }

    if (getIsProxyGroup(next)) {
      nextSortValue = next.header[proxyGroupsSorter.field];
    } else {
      nextSortValue = next[proxyGroupsSorter.field];
    }

    return proxyGroupsSorter.order === 'descend' ? nextSortValue - currentSortValue : currentSortValue - nextSortValue;
  });

  let flatSortedEntities = sortedProxies.reduce<IProxyManagerListEntity[]>((acc, proxyEntity) => {
    if (!getIsProxyGroup(proxyEntity)) {
      acc.push(proxyEntity);

      return acc;
    }

    const isGroupOpen = visibleProxyGroupIds.includes(proxyEntity.header.groupId);
    const entitiesToAdd = isGroupOpen ? [proxyEntity.header, ...proxyEntity.proxies, proxyEntity.addButton] : [proxyEntity.header];
    acc.push(...entitiesToAdd);

    return acc;
  }, []);

  if (isCurrentProxyPresentAndVisible) {
    const country = currentProxy.country || currentProxy.autoProxyRegion || currentProxy.torProxyRegion || '';
    flatSortedEntities = [
      { ...currentProxy, country, groupId: null },
      { id: `recent${PROXY_MANAGER_LIST_SECTION_ID_POSTFIX}`, title: 'proxies.recent' },
      ...flatSortedEntities,
    ];
  }

  // to keep the last proxy visible right above the proxy-manager multi-select panel
  return isSelectProxyModeOpened ? [...flatSortedEntities, EMPTY_PROXY_LIST_ROW] : flatSortedEntities;
});

export const getProxyGroups = (): IProxyGroupsObject => getDefaultStore().get(proxyGroupsAtom);
export const useProxyGroups = (): IProxyGroupsObject => useAtomValue(proxyGroupsAtom);

export const useProxyManagerListEntities = (): (IProxy|IProxyManagerListEntity|null)[] => useAtomValue(proxyManagerListEntitiesAtom);
export const getProxyManagerListEntities = (): (IProxy|IProxyManagerListEntity|null)[] => getDefaultStore().get(proxyManagerListEntitiesAtom);

export const findUnusedGeoProxy = (groupId: string): IGroupedProxy|null => {
  const { geolocationProxies } = getProxyGroups();
  const [unusedProxy] = geolocationProxies[groupId].proxies.filter((proxyToCheck) => proxyToCheck.profilesCount <= 0);
  if (!unusedProxy) {
    return null;
  }

  return unusedProxy;
};
