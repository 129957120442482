import React, { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import EmptyList from './empty-list';
import ProxyRow from './proxy-row';
import { ProxyListContentEl } from './styles/proxy-list';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { getIsProxyManagerListSection } from '../../../../state/proxy/proxy-groups/interfaces';
import { useProxyManagerListEntities } from '../../../../state/proxy/proxy-groups/proxy-groups.atom';
import { setProxyManagerCurrentProxy } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import {
  switchIsSelectProxyModeOpened,
  useIsProxyContextMenuVisible,
  hideProxyContextMenu,
} from '../../../../state/proxy-select-menu.atom';
import ProxyListMenu from '../../components/proxy-list-menu';
import ProxyContextMenu from '../../proxy-context-menu';
import { getIsProxyArchived } from '../../proxy-helpers';
import { GROUPED_PROXY_MANAGER_SIZE, UNGROUPED_PROXY_MANAGER_SIZE } from '../styles';

export interface IProxyList {
  currentProxy?: IProxy;
  localProxyElementLocation: string | null;
}

const ProxyList: React.FC<IProxyList> = (props) => {
  const { currentProxy, localProxyElementLocation } = props;

  const proxyManagerListEntities = useProxyManagerListEntities();
  const { selectedProxies } = useSelectedProxies();
  const isProxyContextMenuVisible = useIsProxyContextMenuVisible();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentProxy?.mode !== 'none') {
      setProxyManagerCurrentProxy(currentProxy || null);
    }

    return () => {
      if (!getIsProxyArchived(currentProxy)) {
        setProxyManagerCurrentProxy(currentProxy || null);
      }
    };
  }, []);

  useEffect(() => {
    if (!selectedProxies.length) {
      switchIsSelectProxyModeOpened(false);
    }
  }, [selectedProxies]);

  useEffect(() => {
    const handleScroll = (): void => {
      hideProxyContextMenu();
    };

    if (ref?.current) {
      if (isProxyContextMenuVisible) {
        ref.current.addEventListener('scroll', handleScroll);
      } else {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    }

    return (): void => {
      if (ref?.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isProxyContextMenuVisible]);

  if (!proxyManagerListEntities.length) {
    return <EmptyList proxySelectorLocation={localProxyElementLocation} />;
  }

  const itemCount = proxyManagerListEntities.length;
  let defaultTtemSize = 41;
  let [PROXY_MANAGER_WIDTH, PROXY_MANAGER_HEIGHT] = [UNGROUPED_PROXY_MANAGER_SIZE.width, UNGROUPED_PROXY_MANAGER_SIZE.height];
  if (NEW_FEATURES.proxyGroups) {
    defaultTtemSize = 39;
    [PROXY_MANAGER_WIDTH, PROXY_MANAGER_HEIGHT] = [GROUPED_PROXY_MANAGER_SIZE.width, GROUPED_PROXY_MANAGER_SIZE.height];
  }

  // TODO: refactor before the proxyGroups release
  const getItemSize = (itemIndex: number): number => {
    if (!NEW_FEATURES.proxyGroups) {
      return defaultTtemSize;
    }

    const currentEntity = proxyManagerListEntities[itemIndex];
    if (!currentEntity) {
      return defaultTtemSize;
    }

    if (getIsProxyManagerListSection(currentEntity)) {
      return 31;
    }

    return defaultTtemSize;
  };

  return (
    <>
      <ProxyListContentEl>
        <div style={{ width: PROXY_MANAGER_WIDTH }}>
          <VariableSizeList
            height={PROXY_MANAGER_HEIGHT}
            width={PROXY_MANAGER_WIDTH}
            itemCount={itemCount}
            itemData={{
              proxyManagerEntities: proxyManagerListEntities,
              currentProxy,
              localProxyElementLocation,
            }}
            itemSize={getItemSize}
            outerRef={ref}
            style={{ margin: NEW_FEATURES.proxyGroups ? '3px 0' : 0 }}
          >
            {ProxyRow}
          </VariableSizeList>
        </div>
        <ProxyListMenu />
      </ProxyListContentEl>
      <ProxyContextMenu />
    </>
  );
};

export default ProxyList;
