import React from 'react';
import { Trans } from 'react-i18next';

import TooltipWrapper from '../../../ui/tooltip-custom/tooltip-wrapper';
import { ProxyListItemProfilesCountEl } from '../styles/proxy-profiles-count-block';

type ProxyProfilesCountBlockProps = {
  profilesCount: number;
}

const ProxyProfilesCountBlock: React.FC<ProxyProfilesCountBlockProps> = (props) => {
  const { profilesCount } = props;

  const tooltipValue = <Trans i18nKey='proxies.profilesCounterTooltip' values={{ count: profilesCount }} />;

  return (
    <TooltipWrapper isTransition={true} value={tooltipValue}>
      <ProxyListItemProfilesCountEl>
        {profilesCount}
      </ProxyListItemProfilesCountEl>
    </TooltipWrapper>
  );
};

export default ProxyProfilesCountBlock;
