import { css } from '@emotion/core';

let flagsBasePath = '/flags/';
const isElectron = !!window.require;
if (isElectron) {
  const nodeJsPath = require('path');
  const flagsBasePathSlash = nodeJsPath.sep;
  flagsBasePath = nodeJsPath.join(__dirname, 'flags').split(flagsBasePathSlash).join('/') + '/';
}

export const flagIcons = css`
  .fib{background-size:contain;background-position:50%;background-repeat:no-repeat}
  .fi{
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  .fi:before{content:'\\00a0'}

  .fi-ad{background-image:url('${flagsBasePath}ad.svg')}
  .fi-ae{background-image:url('${flagsBasePath}ae.svg')}
  .fi-af{background-image:url('${flagsBasePath}af.svg')}
  .fi-ag{background-image:url('${flagsBasePath}ag.svg')}
  .fi-al{background-image:url('${flagsBasePath}al.svg')}
  .fi-am{background-image:url('${flagsBasePath}am.svg')}
  .fi-ao{background-image:url('${flagsBasePath}ao.svg')}
  .fi-ar{background-image:url('${flagsBasePath}ar.svg')}
  .fi-at{background-image:url('${flagsBasePath}at.svg')}
  .fi-au{background-image:url('${flagsBasePath}au.svg')}
  .fi-aw{background-image:url('${flagsBasePath}aw.svg')}
  .fi-az{background-image:url('${flagsBasePath}az.svg')}
  .fi-ba{background-image:url('${flagsBasePath}ba.svg')}
  .fi-bb{background-image:url('${flagsBasePath}bb.svg')}
  .fi-bd{background-image:url('${flagsBasePath}bd.svg')}
  .fi-be{background-image:url('${flagsBasePath}be.svg')}
  .fi-bf{background-image:url('${flagsBasePath}bf.svg')}
  .fi-bg{background-image:url('${flagsBasePath}bg.svg')}
  .fi-bh{background-image:url('${flagsBasePath}bh.svg')}
  .fi-bi{background-image:url('${flagsBasePath}bi.svg')}
  .fi-bj{background-image:url('${flagsBasePath}bj.svg')}
  .fi-bn{background-image:url('${flagsBasePath}bn.svg')}
  .fi-bo{background-image:url('${flagsBasePath}bo.svg')}
  .fi-br{background-image:url('${flagsBasePath}br.svg')}
  .fi-bs{background-image:url('${flagsBasePath}bs.svg')}
  .fi-bt{background-image:url('${flagsBasePath}bt.svg')}
  .fi-bv{background-image:url('${flagsBasePath}bv.svg')}
  .fi-bw{background-image:url('${flagsBasePath}bw.svg')}
  .fi-by{background-image:url('${flagsBasePath}by.svg')}
  .fi-bz{background-image:url('${flagsBasePath}bz.svg')}
  .fi-ca{background-image:url('${flagsBasePath}ca.svg')}
  .fi-cd{background-image:url('${flagsBasePath}cd.svg')}
  .fi-cf{background-image:url('${flagsBasePath}cf.svg')}
  .fi-cg{background-image:url('${flagsBasePath}cg.svg')}
  .fi-ch{background-image:url('${flagsBasePath}ch.svg')}
  .fi-ci{background-image:url('${flagsBasePath}ci.svg')}
  .fi-cl{background-image:url('${flagsBasePath}cl.svg')}
  .fi-cm{background-image:url('${flagsBasePath}cm.svg')}
  .fi-cn{background-image:url('${flagsBasePath}cn.svg')}
  .fi-co{background-image:url('${flagsBasePath}co.svg')}
  .fi-cr{background-image:url('${flagsBasePath}cr.svg')}
  .fi-cu{background-image:url('${flagsBasePath}cu.svg')}
  .fi-cv{background-image:url('${flagsBasePath}cv.svg')}
  .fi-cw{background-image:url('${flagsBasePath}cw.svg')}
  .fi-cy{background-image:url('${flagsBasePath}cy.svg')}
  .fi-cz{background-image:url('${flagsBasePath}cz.svg')}
  .fi-de{background-image:url('${flagsBasePath}de.svg')}
  .fi-dj{background-image:url('${flagsBasePath}dj.svg')}
  .fi-dk{background-image:url('${flagsBasePath}dk.svg')}
  .fi-dm{background-image:url('${flagsBasePath}dm.svg')}
  .fi-do{background-image:url('${flagsBasePath}do.svg')}
  .fi-dz{background-image:url('${flagsBasePath}dz.svg')}
  .fi-ec{background-image:url('${flagsBasePath}ec.svg')}
  .fi-ee{background-image:url('${flagsBasePath}ee.svg')}
  .fi-eg{background-image:url('${flagsBasePath}eg.svg')}
  .fi-er{background-image:url('${flagsBasePath}er.svg')}
  .fi-es{background-image:url('${flagsBasePath}es.svg')}
  .fi-et{background-image:url('${flagsBasePath}et.svg')}
  .fi-fi{background-image:url('${flagsBasePath}fi.svg')}
  .fi-fj{background-image:url('${flagsBasePath}fj.svg')}
  .fi-fm{background-image:url('${flagsBasePath}fm.svg')}
  .fi-fr{background-image:url('${flagsBasePath}fr.svg')}
  .fi-ga{background-image:url('${flagsBasePath}ga.svg')}
  .fi-uk,
  .fi-gb{
    background-image:url('${flagsBasePath}gb.svg')
  }
  .fi-gd{background-image:url('${flagsBasePath}gd.svg')}
  .fi-ge{background-image:url('${flagsBasePath}ge.svg')}
  .fi-gh{background-image:url('${flagsBasePath}gh.svg')}
  .fi-gm{background-image:url('${flagsBasePath}gm.svg')}
  .fi-gn{background-image:url('${flagsBasePath}gn.svg')}
  .fi-gq{background-image:url('${flagsBasePath}gq.svg')}
  .fi-gr{background-image:url('${flagsBasePath}gr.svg')}
  .fi-gt{background-image:url('${flagsBasePath}gt.svg')}
  .fi-gw{background-image:url('${flagsBasePath}gw.svg')}
  .fi-gy{background-image:url('${flagsBasePath}gy.svg')}
  .fi-hk{background-image:url('${flagsBasePath}hk.svg')}
  .fi-hn{background-image:url('${flagsBasePath}hn.svg')}
  .fi-hr{background-image:url('${flagsBasePath}hr.svg')}
  .fi-ht{background-image:url('${flagsBasePath}ht.svg')}
  .fi-hu{background-image:url('${flagsBasePath}hu.svg')}
  .fi-id{background-image:url('${flagsBasePath}id.svg')}
  .fi-ie{background-image:url('${flagsBasePath}ie.svg')}
  .fi-il{background-image:url('${flagsBasePath}il.svg')}
  .fi-in{background-image:url('${flagsBasePath}in.svg')}
  .fi-iq{background-image:url('${flagsBasePath}iq.svg')}
  .fi-ir{background-image:url('${flagsBasePath}ir.svg')}
  .fi-is{background-image:url('${flagsBasePath}is.svg')}
  .fi-it{background-image:url('${flagsBasePath}it.svg')}
  .fi-jm{background-image:url('${flagsBasePath}jm.svg')}
  .fi-jo{background-image:url('${flagsBasePath}jo.svg')}
  .fi-jp{background-image:url('${flagsBasePath}jp.svg')}
  .fi-ke{background-image:url('${flagsBasePath}ke.svg')}
  .fi-kg{background-image:url('${flagsBasePath}kg.svg')}
  .fi-kh{background-image:url('${flagsBasePath}kh.svg')}
  .fi-ki{background-image:url('${flagsBasePath}ki.svg')}
  .fi-km{background-image:url('${flagsBasePath}km.svg')}
  .fi-kp{background-image:url('${flagsBasePath}kp.svg')}
  .fi-kr{background-image:url('${flagsBasePath}kr.svg')}
  .fi-kw{background-image:url('${flagsBasePath}kw.svg')}
  .fi-kz{background-image:url('${flagsBasePath}kz.svg')}
  .fi-la{background-image:url('${flagsBasePath}la.svg')}
  .fi-lb{background-image:url('${flagsBasePath}lb.svg')}
  .fi-lc{background-image:url('${flagsBasePath}lc.svg')}
  .fi-li{background-image:url('${flagsBasePath}li.svg')}
  .fi-lk{background-image:url('${flagsBasePath}lk.svg')}
  .fi-lr{background-image:url('${flagsBasePath}lr.svg')}
  .fi-ls{background-image:url('${flagsBasePath}ls.svg')}
  .fi-lt{background-image:url('${flagsBasePath}lt.svg')}
  .fi-lu{background-image:url('${flagsBasePath}lu.svg')}
  .fi-lv{background-image:url('${flagsBasePath}lv.svg')}
  .fi-ly{background-image:url('${flagsBasePath}ly.svg')}
  .fi-ma{background-image:url('${flagsBasePath}ma.svg')}
  .fi-mc{background-image:url('${flagsBasePath}mc.svg')}
  .fi-md{background-image:url('${flagsBasePath}md.svg')}
  .fi-me{background-image:url('${flagsBasePath}me.svg')}
  .fi-mg{background-image:url('${flagsBasePath}mg.svg')}
  .fi-mh{background-image:url('${flagsBasePath}mh.svg')}
  .fi-mk{background-image:url('${flagsBasePath}mk.svg')}
  .fi-ml{background-image:url('${flagsBasePath}ml.svg')}
  .fi-mm{background-image:url('${flagsBasePath}mm.svg')}
  .fi-mn{background-image:url('${flagsBasePath}mn.svg')}
  .fi-mr{background-image:url('${flagsBasePath}mr.svg')}
  .fi-mt{background-image:url('${flagsBasePath}mt.svg')}
  .fi-mu{background-image:url('${flagsBasePath}mu.svg')}
  .fi-mv{background-image:url('${flagsBasePath}mv.svg')}
  .fi-mw{background-image:url('${flagsBasePath}mw.svg')}
  .fi-mx{background-image:url('${flagsBasePath}mx.svg')}
  .fi-my{background-image:url('${flagsBasePath}my.svg')}
  .fi-mz{background-image:url('${flagsBasePath}mz.svg')}
  .fi-na{background-image:url('${flagsBasePath}na.svg')}
  .fi-ne{background-image:url('${flagsBasePath}ne.svg')}
  .fi-ng{background-image:url('${flagsBasePath}ng.svg')}
  .fi-ni{background-image:url('${flagsBasePath}ni.svg')}
  .fi-nl{background-image:url('${flagsBasePath}nl.svg')}
  .fi-no{background-image:url('${flagsBasePath}no.svg')}
  .fi-np{background-image:url('${flagsBasePath}np.svg')}
  .fi-nr{background-image:url('${flagsBasePath}nr.svg')}
  .fi-nz{background-image:url('${flagsBasePath}nz.svg')}
  .fi-om{background-image:url('${flagsBasePath}om.svg')}
  .fi-pa{background-image:url('${flagsBasePath}pa.svg')}
  .fi-pe{background-image:url('${flagsBasePath}pe.svg')}
  .fi-pg{background-image:url('${flagsBasePath}pg.svg')}
  .fi-ph{background-image:url('${flagsBasePath}ph.svg')}
  .fi-pk{background-image:url('${flagsBasePath}pk.svg')}
  .fi-pl{background-image:url('${flagsBasePath}pl.svg')}
  .fi-ps{background-image:url('${flagsBasePath}ps.svg')}
  .fi-pt{background-image:url('${flagsBasePath}pt.svg')}
  .fi-pw{background-image:url('${flagsBasePath}pw.svg')}
  .fi-py{background-image:url('${flagsBasePath}py.svg')}
  .fi-qa{background-image:url('${flagsBasePath}qa.svg')}
  .fi-ro{background-image:url('${flagsBasePath}ro.svg')}
  .fi-rs{background-image:url('${flagsBasePath}rs.svg')}
  .fi-ru{background-image:url('${flagsBasePath}ru.svg')}
  .fi-rw{background-image:url('${flagsBasePath}rw.svg')}
  .fi-sa{background-image:url('${flagsBasePath}sa.svg')}
  .fi-sb{background-image:url('${flagsBasePath}sb.svg')}
  .fi-sc{background-image:url('${flagsBasePath}sc.svg')}
  .fi-sd{background-image:url('${flagsBasePath}sd.svg')}
  .fi-se{background-image:url('${flagsBasePath}se.svg')}
  .fi-sg{background-image:url('${flagsBasePath}sg.svg')}
  .fi-si{background-image:url('${flagsBasePath}si.svg')}
  .fi-sk{background-image:url('${flagsBasePath}sk.svg')}
  .fi-sl{background-image:url('${flagsBasePath}sl.svg')}
  .fi-sm{background-image:url('${flagsBasePath}sm.svg')}
  .fi-sn{background-image:url('${flagsBasePath}sn.svg')}
  .fi-so{background-image:url('${flagsBasePath}so.svg')}
  .fi-sr{background-image:url('${flagsBasePath}sr.svg')}
  .fi-ss{background-image:url('${flagsBasePath}ss.svg')}
  .fi-st{background-image:url('${flagsBasePath}st.svg')}
  .fi-sv{background-image:url('${flagsBasePath}sv.svg')}
  .fi-sx{background-image:url('${flagsBasePath}sx.svg')}
  .fi-sy{background-image:url('${flagsBasePath}sy.svg')}
  .fi-sz{background-image:url('${flagsBasePath}sz.svg')}
  .fi-td{background-image:url('${flagsBasePath}td.svg')}
  .fi-tg{background-image:url('${flagsBasePath}tg.svg')}
  .fi-th{background-image:url('${flagsBasePath}th.svg')}
  .fi-tj{background-image:url('${flagsBasePath}tj.svg')}
  .fi-tl{background-image:url('${flagsBasePath}tl.svg')}
  .fi-tm{background-image:url('${flagsBasePath}tm.svg')}
  .fi-tn{background-image:url('${flagsBasePath}tn.svg')}
  .fi-to{background-image:url('${flagsBasePath}to.svg')}
  .fi-tr{background-image:url('${flagsBasePath}tr.svg')}
  .fi-tt{background-image:url('${flagsBasePath}tt.svg')}
  .fi-tv{background-image:url('${flagsBasePath}tv.svg')}
  .fi-tz{background-image:url('${flagsBasePath}tz.svg')}
  .fi-ua{background-image:url('${flagsBasePath}ua.svg')}
  .fi-ug{background-image:url('${flagsBasePath}ug.svg')}
  .fi-us{background-image:url('${flagsBasePath}us.svg')}
  .fi-uy{background-image:url('${flagsBasePath}uy.svg')}
  .fi-uz{background-image:url('${flagsBasePath}uz.svg')}
  .fi-va{background-image:url('${flagsBasePath}va.svg')}
  .fi-vc{background-image:url('${flagsBasePath}vc.svg')}
  .fi-ve{background-image:url('${flagsBasePath}ve.svg')}
  .fi-vi{background-image:url('${flagsBasePath}vi.svg')}
  .fi-vn{background-image:url('${flagsBasePath}vn.svg')}
  .fi-vu{background-image:url('${flagsBasePath}vu.svg')}
  .fi-ws{background-image:url('${flagsBasePath}ws.svg')}
  .fi-ye{background-image:url('${flagsBasePath}ye.svg')}
  .fi-za{background-image:url('${flagsBasePath}za.svg')}
  .fi-zm{background-image:url('${flagsBasePath}zm.svg')}
  .fi-zw{background-image:url('${flagsBasePath}zw.svg')}
  .fi-eu{background-image:url('${flagsBasePath}eu.svg')}
`;
