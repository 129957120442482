import { IProxy, ProxyMode } from '../../app/interfaces';

export enum GeoProxyType {
  Resident = 'resident',
  Mobile = 'mobile',
  DataCenter = 'dataCenter',
}

type TruthyProxyMode = Exclude<ProxyMode, 'none'>;
type TruthyProxy = Omit<IProxy, 'mode'> & {
  mode: TruthyProxyMode;
};

export const getIsProxyTruthy = (proxy: IProxy): proxy is TruthyProxy =>
  proxy && proxy.mode !== 'none' && (['tor', 'gologin'].includes(proxy.mode) || !!proxy.id);

type TorOrFreeProxyMode = Extract<ProxyMode, 'tor'|'gologin'>;
export type TorOrFreeProxy = Omit<IProxy, 'mode'> & {
  mode: TorOrFreeProxyMode;
};

export const getIsTorOrFreeProxy = (proxy: IProxy): proxy is TorOrFreeProxy => proxy && ['tor', 'gologin'].includes(proxy.mode);

type GologinProxyMode = Extract<ProxyMode, 'tor'|'gologin'|'geolocation'>;
type GologinProxy = Omit<IProxy, 'mode'> & {
  mode: GologinProxyMode;
};

export const getIsGologinProxy = (proxy: IProxy): proxy is GologinProxy => ['tor', 'gologin', 'geolocation'].includes(proxy.mode);

export const getIsGeoProxyType = (value: string): value is GeoProxyType => (<any>Object).values(GeoProxyType).includes(value);

// TODO: remove `residential` in favor of `resident` by replacing the enum with `GeoProxyType`
export enum GeoProxyPromocodesType {
  Resident = 'residential',
  Mobile = 'mobile',
  DataCenter = 'dataCenter',
}
