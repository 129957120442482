import moment from 'moment';
import React from 'react';

import { IProxy, IArchivedProxy } from '../../../interfaces';
import { ProxyCheckTooltipView, showProxyCheckTooltip } from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { updateProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IconSpin } from '../../../ui/gologin-header/icons';
import { getProxyStatus, isArchivedProxy, restoreProxy } from '../proxy-helpers';
import { ProxyStatusDot } from '../styles/check-proxy-btn';

interface ICheckProxyStatusDot {
  proxy: IProxy|IArchivedProxy;
  isSpinnerVisible: boolean;
  isChecking: boolean;
  proxyTooltipView: ProxyCheckTooltipView;
  profileId?: string;
  isSharedProxy?: boolean;
}

const CheckProxyStatusDot: React.FC<ICheckProxyStatusDot> = (props) => {
  const { proxy, isSpinnerVisible, isChecking, proxyTooltipView, profileId, isSharedProxy = false } = props;

  if (!isSpinnerVisible) {
    const status = isArchivedProxy(proxy) ? '' : getProxyStatus(proxy);

    return <ProxyStatusDot status={status} className='proxy-status-dot' />;
  }

  const handleClick = async (): Promise<void> => {
    let proxyToCheck = proxy;
    if (isArchivedProxy(proxy)) {
      proxyToCheck = await restoreProxy(proxy);
    }

    const proxyChecked = await updateProxyStatuses([proxyToCheck], profileId, isSharedProxy);
    const proxyCheckedFull: IProxy = { ...proxyToCheck, ...proxyChecked, checkDate: moment().toDate() };
    if (proxyTooltipView !== 'proxy-manager-edit-form') {
      showProxyCheckTooltip({
        profileIds: [profileId || ''],
        proxies: [proxyCheckedFull],
        view: proxyTooltipView,
        timeout: 2000,
      });
    }
  };

  return (
    <IconSpin
      hasSpin={isChecking}
      size={16}
      iconColor='var(--767676-proxy-manager-check-proxy-button)'
      padding={0}
      onClick={handleClick}
    />
  );
};

export default CheckProxyStatusDot;
