import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getBasicTableEntities, getBasicTableEntityIds, getBasicTableProfileIds, getBasicTableProfiles, getProfileByBasicProfilesIdx, getProfilesList, isGroupHeader, useBasicTableProfiles, useProfilesList } from './profiles-list.atom';
import { closeProfilesSettings, getProfilesSettingsState, updateProfilesSettingsProfileIds } from './profiles-settings-atom';
import { calculateProfileDeleteOrLeave } from '../features/modalsComponents/components/remove-profile-modal';
import { IProfile } from '../interfaces';
import { NEW_FEATURES } from './feature-toggle/new-features';
import { IGroupHeader } from '../interfaces/group-header.interface';

const profilesTableSelectedIdsAtom = atom<string[]>([]);
const profilesTableLastSelectedIdx = atom<number|null>(null);

export const useProfilesTableSelectedIds = (): string[] => useAtomValue(profilesTableSelectedIdsAtom);

export const getProfilesTableSelectedIds = (): string[] => getDefaultStore().get(profilesTableSelectedIdsAtom);
const setProfilesTableSelectedIds = (newSelectedIds: string[]): void => getDefaultStore().set(profilesTableSelectedIdsAtom, newSelectedIds);

export const getProfilesTableLastSelectedIdx = (): number|null => getDefaultStore().get(profilesTableLastSelectedIdx);
const setProfilesTableLastSelectedIdx = (newLastSelectedIdx: number): void => getDefaultStore().set(profilesTableLastSelectedIdx, newLastSelectedIdx);

export const updateProfilesTableSelectedIds = (newSelectedIds: string[]): void => {
  setProfilesTableSelectedIds(newSelectedIds);
  const { isUsingSelectedProfiles } = getProfilesSettingsState();
  if (isUsingSelectedProfiles && newSelectedIds.length) {
    updateProfilesSettingsProfileIds(newSelectedIds);
  } else if (isUsingSelectedProfiles) {
    closeProfilesSettings();
  }
};

export const reselectCurrentProfileTableIds = (): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  updateProfilesTableSelectedIds([...selectedProfileIds]);
};

export const selectProfileIds = (profileIdsToSelect: string[]): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  const newSelectedProfileIds = new Set([...selectedProfileIds, ...profileIdsToSelect]);
  updateProfilesTableSelectedIds([...newSelectedProfileIds]);
};

const unselectProfileIds = (profileIdsToUnselect: string[]): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  updateProfilesTableSelectedIds(selectedProfileIds.filter((selProfileId) => !profileIdsToUnselect.includes(selProfileId)));
};

const handleToggleProfileIsSelected = (profileId: string, newIsSelected: boolean): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  if (newIsSelected && !selectedProfileIds.includes(profileId)) {
    selectProfileIds([profileId]);

    return;
  }

  if (!newIsSelected && selectedProfileIds.includes(profileId)) {
    unselectProfileIds([profileId]);
  }
};

const toggleRangeByIdx = (minIdx: number, maxIdx: number): void => {
  const basicTableEntities = getBasicTableEntities();
  const entitiesToSelect = basicTableEntities.slice(minIdx, maxIdx + 1);
  const profileIdsToSelect = entitiesToSelect
    .filter(ent => !isGroupHeader(ent))
    .map(({ id }) => id);

  const selectedProfileIds = getProfilesTableSelectedIds();

  const selectedProfileIdsSet = new Set(selectedProfileIds);
  const areAllSelected = profileIdsToSelect.every(profileId => selectedProfileIdsSet.has(profileId));

  if (areAllSelected) {
    unselectProfileIds(profileIdsToSelect);

    return;
  }

  selectProfileIds(profileIdsToSelect);
};

export const handleToggleProfileIsSelectedByIdx = (profileIdx: number, newIsSelected: boolean, isShiftActive: boolean): void => {
  const lastSelectedIdx = getProfilesTableLastSelectedIdx();
  setProfilesTableLastSelectedIdx(profileIdx);

  const profile = getProfileByBasicProfilesIdx(profileIdx);
  if (!profile) {
    return;
  }

  if (isShiftActive && lastSelectedIdx !== null) {
    const minIdx = Math.min(profileIdx, lastSelectedIdx);
    const maxIdx = Math.max(profileIdx, lastSelectedIdx);
    toggleRangeByIdx(minIdx, maxIdx);

    return;
  }

  const profileId = profile.id;
  handleToggleProfileIsSelected(profileId, newIsSelected);
};

export const resetProfilesTableSelectedIds = (): void => {
  const selectedIds = getProfilesTableSelectedIds();
  if (!selectedIds.length) {
    return;
  }

  updateProfilesTableSelectedIds([]);
};

export const getProfilesTableSelectedProfiles = (): IProfile[] => {
  const profilesList = getProfilesList();
  const selectedProfileIds = getProfilesTableSelectedIds();

  return profilesList.filter(profile => selectedProfileIds.includes(profile.id));
};

const useProfilesTableSelectedRowIndexes = (): number[] => {
  const basicTableProfiles = useBasicTableProfiles();
  const basicTableProfileIds = basicTableProfiles.map((basicTableProfile) => getDefaultStore().get(basicTableProfile.atom).id);
  const selectedProfileIds = useProfilesTableSelectedIds();

  return basicTableProfileIds.reduce<number[]>((acc, basicProfileId, idx) => {
    if (selectedProfileIds.includes(basicProfileId)) {
      acc.push(idx);
    }

    return acc;
  }, []);
};

const EMPTY_ARRAY: number[] = [];

// needed for old antd profiles table as prop
// returns static array for new table to not cause re-render of entire table
export const useOldProfilesTableSelectedRowIndexes = (): number[] => {
  const selectedRowIndexes = useProfilesTableSelectedRowIndexes();
  if (NEW_FEATURES.header) {
    return EMPTY_ARRAY;
  }

  return selectedRowIndexes;
};

export const useIsProfileSelected = (profileIdx: number): boolean => useProfilesTableSelectedRowIndexes().includes(profileIdx);

export const getSelectedProfilesByAction = (action: 'delete'|'leave'): Array<{ id: string; shareId: string | undefined }> =>
  getProfilesTableSelectedProfiles()
    .filter((profile) => calculateProfileDeleteOrLeave(profile) === action)
    .map((profile) => ({
      id: profile.id,
      shareId: profile.shareId,
    }));

export const getProfilesTableSelectedProfilesActions = (): Array<'Delete'|'Leave'> => {
  const selectedProfiles = getProfilesTableSelectedProfiles();
  const profilesRoles = selectedProfiles.map((profile) => profile.role);

  const profilesActions: Array<'Delete'|'Leave'> = [];
  if (profilesRoles.includes('owner')) {
    profilesActions.push('Delete');
  }

  if (profilesRoles.includes('administrator')
    || profilesRoles.includes('redactor')
    || profilesRoles.includes('guest')) {
    profilesActions.push('Leave');
  }

  return profilesActions;
};

export const getProfilesTableSelectedProfilesCommonExtensions = (): string[] => {
  const selectedProfiles = getProfilesTableSelectedProfiles();

  const allExtensionsArray: string[][] = selectedProfiles
    .map(profile => (profile.chromeExtensions || []).concat(profile.userChromeExtensions || []));

  return allExtensionsArray[0]?.reduce((acc: string[], extensionId: string) => {
    if (allExtensionsArray.every(extensionArray => extensionArray.includes(extensionId))) {
      acc.push(extensionId);
    }

    return acc;
  }, []) || [];
};

export type SelectionStatus = 'none' | 'some' | 'all';

export const determineAllProfilesSelectionStatus = (selectedProfileIds: string[], profileIds: string[]): SelectionStatus => {
  const countSelected = profileIds.reduce((acc, profileId) => {
    const isSelected = selectedProfileIds.includes(profileId);

    return isSelected ? acc + 1 : acc;
  }, 0);

  if (!countSelected) {
    return 'none';
  }

  if (countSelected === profileIds.length) {
    return 'all';
  }

  return 'some';
};

export const useAllProfilesSelectionStatus = (): SelectionStatus => {
  const selectedProfileIds = useProfilesTableSelectedIds();
  const profilesList = useProfilesList();
  const profileIds = profilesList.map(profile => profile.id);

  return determineAllProfilesSelectionStatus(selectedProfileIds, profileIds);
};

export const selectAllProfiles = (): void => {
  const basicTableProfileIds = getBasicTableProfileIds();
  setProfilesTableSelectedIds(basicTableProfileIds);
};

export const toggleAllProfilesSelected = (): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  const profilesList = getProfilesList();
  const profileIds = profilesList.map(profile => profile.id);

  const selectionStatus = determineAllProfilesSelectionStatus(selectedProfileIds, profileIds);
  if (selectionStatus === 'all') {
    resetProfilesTableSelectedIds();

    return;
  }

  selectAllProfiles();
};

export const toggleProfilesSelected = (profileIds: string[]): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();

  const selectionStatus = determineAllProfilesSelectionStatus(selectedProfileIds, profileIds);
  if (selectionStatus === 'all') {
    unselectProfileIds(profileIds);

    return;
  }

  selectProfileIds(profileIds);
};
