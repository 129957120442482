import React, { useRef, useState } from 'react';

import ProxySectionContextMenuPopover from './proxy-section-context-menu-popover';
import { IconMeatballsWrapper } from './styles';
import { IconMeatballs } from '../../../../../../ui/gologin-header/icons';

const ICON_ACTIVE_COLOR = 'var(--222222-proxy-manager-list-item)';

type PopoverAchorElement = HTMLDivElement | null;

export const ProxySectionContextMenu: React.FC = () => {
  const [popoverAchorElement, setPopoverAchorElement] = useState<PopoverAchorElement>(null);

  const buttonWrapperRef = useRef<PopoverAchorElement>(null);

  const handleClick = (): void => {
    setPopoverAchorElement(previousValue => previousValue ? null : buttonWrapperRef.current);
  };

  const handleClose = (): void => {
    setPopoverAchorElement(null);
  };

  return (
    <>
      <IconMeatballsWrapper ref={buttonWrapperRef}>
        <IconMeatballs
          padding={0}
          iconColor={popoverAchorElement ? ICON_ACTIVE_COLOR : 'var(--B5B5BA-proxy-profiles-counter)'}
          iconHoveredColor={ICON_ACTIVE_COLOR}
          onClick={handleClick}
        />
      </IconMeatballsWrapper>
      <ProxySectionContextMenuPopover
        anchorElement={popoverAchorElement}
        handleClose={handleClose}
      />
    </>
  );
};
