import moment from 'moment';

import { createProxiesRequest } from '../../../features/proxy/api';
import { getCreateProxyValidationError, isCreateProxiesValidationError } from '../../../features/proxy/proxy-helpers';
import { E_PROXY_FOLDERS } from '../../../features/proxy/proxy-page/constants/proxy-folders';
import { areProxyFieldsEqual } from '../../../features/proxy/utils/find-proxy-in-list';
import { IProxy } from '../../../interfaces';
import { isProxyDataValid } from '../../../utils/proxy.validation-message';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { ProxyCheckTooltipView, showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, updateProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { updateProxyItem, updateProxyItems, upsertOneProxy } from '../proxy-list.atom';
import { mapAndSetProxiesTableList } from '../proxy-table/proxies-table-list.atom';
import { getSelectedProxyFolder, setSelectedProxyFolder } from '../proxy-table/proxy-folders.atom';
import { updateProxiesTableSelectedIds } from '../proxy-table/proxy-table-selected-ids.atom';

// eslint-disable-next-line no-shadow
export enum MULTIPLE_PROXIES_ADD_ERRORS {
  couldntParseProxiesError = 'couldntParseProxiesError',
  limitReachedError = 'limitReachedError',
  invalidProxiesError = 'invalidProxiesError',
}

export interface IMultipleProxiesAddError {
  message: MULTIPLE_PROXIES_ADD_ERRORS;
  count?: number;
  isCustomError?: boolean;
}

interface IMultipleProxiesAddResultSuccess {
  proxies: IProxy[];
  error: null;
}

interface IMultipleProxiesAddResultError {
  error: IMultipleProxiesAddError;
}

export type IMultipleProxiesAddResult = IMultipleProxiesAddResultSuccess | IMultipleProxiesAddResultError;

const getUniqueProxies = (proxies: IProxy[]): IProxy[] => proxies.reduce<IProxy[]>((acc, proxyToAdd) => {
  const isProxyAlreadyAdded = acc.some(addedProxy => areProxyFieldsEqual(addedProxy, proxyToAdd));
  if (isProxyAlreadyAdded) {
    return acc;
  }

  acc.push(proxyToAdd);

  return acc;
}, []);

const validateMultipleProxies = (proxies: IProxy[], isProxyPage: boolean): IMultipleProxiesAddResult => {
  if (!proxies.length) {
    return {
      error: { message: MULTIPLE_PROXIES_ADD_ERRORS.couldntParseProxiesError },
    };
  }

  const uniqueProxies = getUniqueProxies(proxies);

  const proxiesMultipleAddLimit = isProxyPage ? 30 : 50;
  const slicedProxes = uniqueProxies.slice(0, proxiesMultipleAddLimit);
  if (slicedProxes.length < uniqueProxies.length) {
    return {
      error: { message: MULTIPLE_PROXIES_ADD_ERRORS.limitReachedError, count: proxiesMultipleAddLimit },
    };
  }

  const proxiesValidated = slicedProxes.filter((proxyToValidate) => isProxyDataValid({
    host: proxyToValidate.host,
    port: proxyToValidate.port.toString(),
    shouldThrowNotification: false,
  }));

  if (proxiesValidated.length < slicedProxes.length) {
    return {
      error: { message: MULTIPLE_PROXIES_ADD_ERRORS.invalidProxiesError, count: slicedProxes.length - proxiesValidated.length },
    };
  }

  return { proxies: slicedProxes, error: null };
};

const upsertProxyAfterCreating = (createdProxies: IProxy[]): void => {
  createdProxies.forEach(upsertOneProxy);
  mapAndSetProxiesTableList((prevProxies) => {
    const newProxies = [...prevProxies];
    newProxies.unshift(...createdProxies);

    return newProxies;
  }, [], false);
};

const updateProxyAfterCreating = (createdProxies: IProxy[], isProxyPage: boolean): void => {
  if (!isProxyPage) {
    return updateProxyItems(createdProxies);
  }

  mapAndSetProxiesTableList((prevProxies) => prevProxies.map(((prevProxy) => {
    const existingProxy = createdProxies.find(({ id }) => id === prevProxy.id);
    if (!existingProxy) {
      return prevProxy;
    }

    return {
      ...prevProxy,
      ...existingProxy,
    };
  })), [], isProxyPage);
};

const updateProxyAfterChecking = (listedProxy: IProxy, checkedProxy: ISetProxyStatusParams, isProxyPage: boolean): IProxy => {
  const proxyToShowInTooltip: IProxy = { ...listedProxy, ...checkedProxy, checkDate: moment().toDate() };
  if (isProxyPage) {
    mapAndSetProxiesTableList((prevProxies) => prevProxies.map(((prevProxy) => {
      if (prevProxy.id !== listedProxy.id) {
        return prevProxy;
      }

      return {
        ...prevProxy,
        ...checkedProxy,
        checkDate: moment().toDate(),
      };
    })), [], isProxyPage);
  } else {
    updateProxyItem(proxyToShowInTooltip);
  }

  return proxyToShowInTooltip;
};

interface IProxyStatusCheckParams {
  proxyToCheck: IProxy;
  selectedProfiles: string[];
  proxyCheckTooltipView: ProxyCheckTooltipView;
  shouldShowCheckInfoTooltip: boolean;
  isProxyPage: boolean;
}

const checkProxyStatus = async ({
  proxyToCheck,
  selectedProfiles,
  proxyCheckTooltipView,
  shouldShowCheckInfoTooltip,
  isProxyPage,
}: IProxyStatusCheckParams): Promise<void> => {
  const checkedProxy = await updateProxyStatuses([proxyToCheck]);
  let proxyToShowInTooltip: IProxy|null = null;
  if (checkedProxy) {
    proxyToShowInTooltip = updateProxyAfterChecking(proxyToCheck, checkedProxy, isProxyPage);
  }

  if (!(proxyToShowInTooltip && shouldShowCheckInfoTooltip)) {
    return;
  }

  showProxyCheckTooltip({
    profileIds: selectedProfiles,
    proxies: [proxyToShowInTooltip],
    view: proxyCheckTooltipView,
    timeout: 2 * 1000,
  });
};

interface IMultipleProxiesAddParams {
  proxyList: IProxy[];
  proxies: IProxy[];
  selectedProfiles?: string[];
  proxyCheckTooltipView?: ProxyCheckTooltipView;
}

export const addMultipleProxies = async ({
  proxyList = [],
  proxies = [],
  selectedProfiles = [],
  proxyCheckTooltipView = '',
}: IMultipleProxiesAddParams): Promise<IMultipleProxiesAddResult> => {
  const isProxyPage = proxyCheckTooltipView === 'proxy-page-table';
  const validatedProxiesResult = validateMultipleProxies(proxies, isProxyPage);
  if (validatedProxiesResult.error) {
    return validatedProxiesResult;
  }

  type IProxyCustomError = { error: IMultipleProxiesAddError };
  const requestedProxies: IProxy[] | IProxyCustomError = await createProxiesRequest(validatedProxiesResult.proxies).catch((error) => {
    const errorMessage = error?.body?.message;
    const isValidationError = isCreateProxiesValidationError(errorMessage);
    if (isValidationError) {
      const proxiesMultipleAddLimit = isProxyPage ? 30 : 50;

      return getCreateProxyValidationError(errorMessage, proxiesMultipleAddLimit);
    }

    return {
      error: {
        message: errorMessage || 'notifications.error.somethingWentWrongAgainLater',
        isCustomError: true,
      },
    };
  });

  const isProxyCreationError = (requestProxiesResult: IProxy[] | IProxyCustomError): requestProxiesResult is IProxyCustomError =>
    !!(requestProxiesResult as IProxyCustomError).error;

  if (isProxyCreationError(requestedProxies)) {
    return requestedProxies;
  }

  if (isProxyPage) {
    const requestedProxyIds = requestedProxies.map(({ id }) => id);
    updateProxiesTableSelectedIds(requestedProxyIds);
  }

  const shouldShowCheckInfoTooltip = requestedProxies.length === 1;
  const { editedProxies, addedProxies } = requestedProxies.reduce<{
    editedProxies: IProxy[];
    addedProxies: IProxy[];
  }>((acc, requestedProxy) => {
    const existingProxy = proxyList.find(listedProxy => listedProxy.id === requestedProxy.id);
    if (existingProxy) {
      acc.editedProxies.push(existingProxy);
    } else {
      acc.addedProxies.push(requestedProxy);
    }

    return acc;
  }, { editedProxies: [], addedProxies: [] });

  if (editedProxies.length) {
    updateProxyAfterCreating(editedProxies, isProxyPage);
  }

  if (addedProxies.length) {
    upsertProxyAfterCreating(addedProxies);
  }

  if (isProxyPage && getSelectedProxyFolder() !== E_PROXY_FOLDERS.allProxies) {
    setSelectedProxyFolder(E_PROXY_FOLDERS.allProxies);
  }

  const updatedProxies = [...addedProxies, ...editedProxies];
  addProxyStatuses(updatedProxies, selectedProfiles);
  updatedProxies.forEach((proxy) => {
    checkProxyStatus({
      proxyToCheck: proxy,
      selectedProfiles,
      proxyCheckTooltipView,
      shouldShowCheckInfoTooltip,
      isProxyPage,
    });
  });

  return {
    proxies: [...addedProxies, ...editedProxies],
    error: null,
  };
};
