import { message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import {
  DEFAULT_PROFILE_CREATE_COUNT_KEY,
} from '../../../../../common/constants/constants';
import { randomWords } from '../../../../features/common/randomizer';
import { history } from '../../../../services';
import { DEFAULT_PROFILE_NAME, importFilesContext, templatesContext, userContext, workspaceContext } from '../../../../state';
import { openProfilesSettingsOfSelectedIds } from '../../../../state/profiles-settings-atom';
import { resetProfilesTableSorting } from '../../../../state/profiles-table/profiles-sort.atom';
import { updateProfilesTableSelectedIds } from '../../../../state/profiles-table-selected-ids.atom';
import { parseNameFormatting, updateTemplateProfileName } from '../../../../utils/parse-name-formatting';
import { IParseNameFormatting } from '../../../../utils/parse-name-formatting/interfaces';
import { OSWithM1, getUserOS } from '../../../../utils/user-os';
import { createProfileByData } from '../../../drag-and-drop-files/api';
import { resetProfilesTable } from '../../../../state/profiles-list.atom';

interface IProfileDataPrepared {
  folder: string;
  dataToCreateProfile: {
    profileName?: string;
  }[];
}

interface ICreateMultipleProfiles {
  profileCount: number;
  setIsShowChangePlanMessage: (values: boolean) => void;
  handleCloseMenu: () => void;
}

export const useCreateMultipleProfiles = (props: ICreateMultipleProfiles): {
  createMultipleProfiles(): Promise<void>;
} => {
  const [userOs, setUserOs] = useState<OSWithM1>();
  const [osSpec, setOsSpec] = useState<string>();

  const { setDropFileStep } = useContext(importFilesContext);
  const templateCtx = useContext(templatesContext);
  const workspaceCtx = useContext(workspaceContext);
  const userCtx = useContext(userContext);

  const { t: translation } = useTranslation();

  const { id: workspaceId, availableWorkspaces } = workspaceCtx;
  const { selectedFolder = '' } = userCtx;

  const { profileCount, setIsShowChangePlanMessage, handleCloseMenu } = props;
  const currentWorkspace = availableWorkspaces.find(workspace => workspace.id === workspaceId) || workspaceCtx;
  const { selectedTemplate } = templateCtx;
  const { dropFiles } = selectedTemplate.profileName;
  const browserName = (NEW_FEATURES.templateProfileName && dropFiles) || DEFAULT_PROFILE_NAME;

  useEffect(() => {
    (async (): Promise<void> => {
      const os = await getUserOS();
      setUserOs(os.name);
      setOsSpec(os.spec);
    })();
  }, []);

  const checkAvailableCountLimit = (): {
    count: number;
    error?: boolean;
  } => {
    const { plan: userPlan, profiles = 0 } = userCtx;
    let maxProfiles = currentWorkspace.planProfilesMax;
    let currentProfilesCount = currentWorkspace.profilesCount;
    let allowedProfilesCount = maxProfiles - currentProfilesCount;

    if (!NEW_FEATURES.workspaces) {
      maxProfiles = userPlan.maxProfiles || 0;
      currentProfilesCount = profiles;
      allowedProfilesCount = maxProfiles - currentProfilesCount;
    }

    if (!allowedProfilesCount) {
      return {
        count: allowedProfilesCount,
      };
    }

    if (profileCount > allowedProfilesCount) {
      return {
        count: allowedProfilesCount,
        error: true,
      };
    }

    return {
      count: profileCount,
    };
  };

  const prepareProfileData = (count: number): IProfileDataPrepared => {
    const dataToCreateNames = new Array(count).fill('').map(() => {
      if (!browserName) {
        return { name: randomWords() };
      }

      return {};
    });

    const formattingParams: IParseNameFormatting = { profiles: dataToCreateNames, nameFormat: browserName };
    const newNames = parseNameFormatting(formattingParams);
    const folder = selectedFolder || localStorage.getItem('SelectedFolder') || '';

    const dataToCreateProfile = newNames.map((item) => ({
      profileName: item.name,
    }));

    return { folder, dataToCreateProfile };
  };

  const createMultipleProfiles = async (): Promise<void> => {
    if (!userOs) {
      return;
    }

    const { count, error } = checkAvailableCountLimit();

    if (!count) {
      setIsShowChangePlanMessage(true);

      return;
    }

    handleCloseMenu();

    localStorage.setItem(DEFAULT_PROFILE_CREATE_COUNT_KEY, count.toString());

    if (history.location.pathname !== '/profileList') {
      history.push('/profileList');
    }

    setDropFileStep('multipleCreating');

    const { folder, dataToCreateProfile } = prepareProfileData(count);
    const newProfileIds = await createProfileByData({
      sources: dataToCreateProfile,
      workspaceId,
      userOs,
      osSpec,
      selectedFolder: folder,
      isCreateMultipleProfiles: true,
    }).catch(() => null);

    if (!newProfileIds) {
      return;
    }

    resetProfilesTable();
    updateProfilesTableSelectedIds(newProfileIds);
    openProfilesSettingsOfSelectedIds();
    setDropFileStep(newProfileIds ? 'loading' : null);
    resetProfilesTableSorting();

    const messageDuration = 5;
    message.success(translation('notifications.success.profilesCreated', { count: newProfileIds.length || 0 }), messageDuration);

    if (browserName) {
      await updateTemplateProfileName(dropFiles, count, templateCtx);
    }

    error && setTimeout(() => {
      message.error(translation('notifications.error.maxProfiles'));
    }, 4000);
  };

  return {
    createMultipleProfiles,
  };
};
