import React, { memo } from 'react';

import { ProxyManagerContainer, ProxyManagerContent, ProxyPopper } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';

interface IProxyPopoverWrapper {
  children: React.ReactNode;
  isWrappedByModal?: boolean;
}

const ProxyPopoverWrapper: React.FC<IProxyPopoverWrapper> = (props) => {
  const { children, isWrappedByModal = false } = props;

  const theme = useCurrentTheme();
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  return (
    <ProxyPopper className='proxy-popper'>
      <ProxyManagerContainer
        className='proxy-popper-container'
        isDarkTheme={isDarkTheme}
        isWrappedByModal={isWrappedByModal}
      >
        <ProxyManagerContent
          newStyle={!!NEW_FEATURES.header}
          isWrappedByModal={isWrappedByModal}
        >
          {children}
        </ProxyManagerContent>
      </ProxyManagerContainer>
    </ProxyPopper>
  );
};

export default memo(ProxyPopoverWrapper);
