import React from 'react';
import { Trans } from 'react-i18next';

import { IconSpin } from '../../../../ui/gologin-header/icons';
import IconProxyPencil from '../../../../ui/icons/IconProxyPencil';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { ProxyButtonEl } from '../../proxy-manager/proxy-list/styles/proxy-list-item';

interface IProxyEditButton {
  isProxyRestoring: boolean;
  handleProxyInfoEditClick?: () => void;
}

const ProxyEditButton: React.FC<IProxyEditButton> = (props) => {
  const { isProxyRestoring, handleProxyInfoEditClick } = props;
  if (isProxyRestoring) {
    return (
      <IconSpin
        hasSpin={true}
        size={16}
        iconColor='var(--767676-proxy-manager-check-proxy-button)'
        padding={0}
      />
    );
  }

  return (
    <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.editProxy' />}>
      <ProxyButtonEl
        onClick={handleProxyInfoEditClick}
        className='proxy-update-btn'
      >
        <IconProxyPencil />
      </ProxyButtonEl>
    </TooltipWrapper>
  );
};

export default ProxyEditButton;
