import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { ProxyFormContainerEl } from './proxy-form/styles';
import { ProxyButtonsContainerEl } from './proxy-selector-buttons/styles';
import { flagIcons } from '../../../../flag-icons';
import { CheckProxyButtonWrapper } from '../check-proxy-button/styles';
import { ProxySearchInputEl } from '../styles/proxy-search-input';

export const ProxyWidthLimitterEl = styled('div')<{ editProxyMode: boolean; takeAllWidth?: boolean }>`
  ${(props): SerializedStyles => css`
    ${(props.editProxyMode) ? `
      max-width: none;
    ` : `
      max-width: 100%;
    `}
  `}

  ${(props): SerializedStyles|'' => props.takeAllWidth ? css`
    width: 100%;
  ` : ''}
`;

export const ProxyWrapperSectionEl = styled('section')<{ editProxyMode: boolean; isInDrawer: boolean; newStyle: boolean; newDrawer: boolean }>`
  display: flex;
  line-height: 20px;
  letter-spacing: 0.25px;

  position: absolute;
  left: 0;
  top: ${(props): string => props.isInDrawer && props.newDrawer ? '-16px' : '0'};
  width: 100%;
  height: 100%;

  ${flagIcons}

  ${(props): SerializedStyles => css`
    z-index: ${(props.editProxyMode) ? '150' : '130'};
  `}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    z-index: ${(props.editProxyMode) ? '103' : '100'};
  ` : null}

  &:hover {
    overflow: visible;
    z-index: ${(props): string => props.newStyle ? '104' : '150'};

    .current-proxy-block {
      .proxy-flag-block,
      .flag-item,
      .proxy-title {
        opacity: 1;
      }
    }

    ${ProxyWidthLimitterEl} {
      max-width: none;
    }

    ${(props): SerializedStyles => css`
      ${ProxyWrapperEl} {
        ${(props.editProxyMode) ? css`
          overflow: visible;

          > .proxy-title {
            max-width: 250px;
          }
        ` : ''}

        ${(!props.editProxyMode) ? css`
          cursor: pointer;
          min-width: unset;
        ` : ''}
      }
    `}

    ${ProxyButtonsContainerEl} {
      visibility: visible;
    }

    ${ProxyFormContainerEl} {
      max-width: 100%;
    }

    ${ProxySearchInputEl} {
      text-overflow: initial
    }
  }
`;

export const ProxyWrapperEl = styled('div')<{
  editProxyMode: boolean;
  isNoProxy: boolean;
  newStyle: boolean;
  isInDrawer: boolean;
  newDrawer: boolean;
}>`
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin-left: -7px;
  min-height: 58px;
  height: 100%;

  background: var(--FFFFFF-proxy-manager-selector);

  .ant-table-row-selected & {
    background: var(--FAFAFA-proxy-manager-selector);
  }

  ${(props): SerializedStyles|null => props.newStyle ? css`
    display: flex;
    min-height: 52px;

    .ant-table-row-selected & {
      background: inherit;
    }

    margin-left: ${!props.isInDrawer ? '8px' : props.newDrawer && props.isNoProxy ? '0' : '16px'};

    ${ProxyHeightLimitterEl} {
      height: ${props.isInDrawer && '52px'};
    }

    ${CheckProxyButtonWrapper} {
      display: ${props.isInDrawer && 'flex'};;
    }
  ` : null}

  tr:hover &,
  .row-active & {
    background: var(--F9F9F9-proxy-manager-selector);
  }

  .ant-drawer-body & {
    background: none;
  }

  .proxy-title {
    min-width: calc(100% - 48px);
  }
`;

export const ProxyHeightLimitterEl = styled('div')<{ isNoProxy: boolean; newStyle: boolean; takeAllWidth?: boolean }>`
  position: relative;
  height: ${(props): string => props.newStyle ? '52px' : '59px'};
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 100%;
  background-color: ${(props): string|null => props.newStyle ? 'inherit' : null};

  ${(props): SerializedStyles => css`
    ${(props.isNoProxy) ? `
      padding: 0;
      padding-right: 16px;
    ` : `
      padding: 16px;
      padding-left: ${props.newStyle ? '16px' : '0'};
    `}
  `}

  ${(props): SerializedStyles|'' => props.takeAllWidth ? css`
    padding-right: 0;
    width: 100%;
  ` : ''}
`;

export const ProxyAreaEl = styled('div')<{ newStyle: boolean; takeAllWidth?: boolean }>`
  align-items: center;
  flex-direction: row;
  max-width: 100%;
  display: flex;

  background-color: ${(props): string|null => props.newStyle ? 'inherit' : null};

  ${(props): SerializedStyles|'' => props.takeAllWidth ? css`
    justify-content: space-between;
    width: 100%;
  ` : ''}
`;

export const CurrentProxyWrapperEl = styled('div')`
  display: flex;
  max-width: 100%;
`;
