import React, { SyntheticEvent } from 'react';
import { Trans } from 'react-i18next';

import { ProxyButtonsContainerEl } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import useDelayedMount from '../../../../hooks/use-should-mount.hook';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxies } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import IconPasteProxy from '../../../../ui/icons/IconPasteProxy';
import IconPlus from '../../../../ui/icons/IconPlus';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { IconBtn } from '../../styles/icon-btns';

interface IProxySelectorButtons {
  isVisible?: boolean;
  profileId: string;
  selectorContainerElement: React.RefObject<HTMLDivElement>;
  localProxySelector?: string;
  isInDrawer?: boolean;
}

const ProxySelectorButtons: React.FC<IProxySelectorButtons> = (props) => {
  const { isVisible, profileId, selectorContainerElement, localProxySelector, isInDrawer } = props;
  const isInNewDrawer = isInDrawer && NEW_FEATURES.drawer;

  const { shouldMount } = useDelayedMount(!!isVisible);
  if (!shouldMount) {
    return null;
  }

  const handleAddClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    openProxyManager({
      containerElement: selectorContainerElement.current,
      modalView: 'proxy-add',
      currentProxy: null,
      modalEditingProxyId: null,
      currentProfileId: profileId,
      proxySelectorLocation: 'no-location',
    });
  };

  const handlePasteClick = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();

    const pastedProxies = await pasteProxies({
      profileId,
      localProxySelectorLocation: 'no-location',
      isInDrawer: !!isInDrawer,
    });

    if (pastedProxies.length > 1) {
      openProxyManager({
        containerElement: selectorContainerElement.current,
        modalView: 'proxy-list',
        currentProxy: null,
        modalEditingProxyId: null,
        currentProfileId: profileId,
        proxySelectorLocation: localProxySelector || 'no-location',
      });
    }
  };

  return (
    <ProxyButtonsContainerEl className='proxy-btns' isVisible={isVisible}>
      <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.addProxy' />}>
        <IconBtn
          style={{ marginRight: 12 }}
          isInNewDrawer={isInNewDrawer}
          onClick={handleAddClick}
        >
          <IconPlus />
        </IconBtn>
      </TooltipWrapper>
      <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.pasteProxies' />}>
        <IconBtn
          isInNewDrawer={isInNewDrawer}
          onClick={handlePasteClick}
        >
          <IconPasteProxy />
        </IconBtn>
      </TooltipWrapper>
    </ProxyButtonsContainerEl>
  );
};

export default ProxySelectorButtons;
