import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProxyButtonsContainerEl = styled('div')<{ isVisible?: boolean }>`
  display: flex;
  margin-top: 6px;
  white-space: nowrap;
  margin-left: 16px;
  visibility: hidden;
  ${(props): SerializedStyles|null => props.isVisible ? css`
    visibility: visible;
  ` : null}
`;
