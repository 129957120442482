import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getTrafficData } from './api';
import ProxyList from './proxy-list';
import { BuyProxyPopoverCustom, Description, ItemsContainer, Title, TrafficRequestErrorText, UnderlinedText } from './styles';
import { IGeolocationProxyFullData } from '../../../interfaces';
import { history } from '../../../services';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { IconSpinner } from '../icons';

interface IBuyProxy {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const BuyProxy: FC<IBuyProxy> = (props) => {
  const [isTimeoutExceed, setIsTimeoutExceed] = useState<boolean>(false);
  const [trafficData, setTrafficData] = useState<IGeolocationProxyFullData | null>(null);

  const theme = useCurrentTheme();

  const { t: translation } = useTranslation();

  const { anchorEl, onClose } = props;
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  useEffect(() => {
    if (anchorEl) {
      setIsTimeoutExceed(false);
      fetchTrafficData().catch(console.error);
    }
  }, [anchorEl]);

  const fetchTrafficData = async (): Promise<void> => {
    const data = await getTrafficData();

    if (typeof data === 'string') {
      setIsTimeoutExceed(true);

      return;
    }

    setTrafficData(data);
  };

  const onClickToNewProfile = (): void => {
    onClose();
    history.push('/newProfile?proxy=geolocation');
  };

  const showContent = (): JSX.Element => {
    if (isTimeoutExceed) {
      return (
        <>
          <TrafficRequestErrorText>
            {translation('modals.proxyTrafficModal.trafficRequestError')}
          </TrafficRequestErrorText>
        </>
      );
    }

    if (!trafficData) {
      return (
        <div style={{ fontSize: 24, textAlign: 'center' }}>
          <IconSpinner
            size={24}
            padding={0}
          />
        </div>
      );
    }

    return (
      <>
        <Title>
          {translation('trafficModal.availableForNow')}
        </Title>
        <ItemsContainer>
          <ProxyList trafficData={trafficData} />
        </ItemsContainer>
        <Description>
          {translation('trafficModal.subject')}
          &nbsp;
          <UnderlinedText onClick={onClickToNewProfile}>
            {translation('trafficModal.subjectLink')}
          </UnderlinedText>
        </Description>
      </>
    );
  };

  return (
    <BuyProxyPopoverCustom
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ zIndex: 1100 }}
      isDarkTheme={isDarkTheme}
    >
      {showContent()}
    </BuyProxyPopoverCustom>
  );
};

export default BuyProxy;

