import styled from '@emotion/styled';
import { Button, Table } from 'antd';

const DEFAULT_BORDER_COLOR = 'var(--D9D9D9)';

export const TextArea = styled('textarea')`
  width: 100%;
  height: 100px;
  resize: none;
  padding: 5px 10px;
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-radius: 3px;
  background: var(--FFFFFF);

  &:focus {
    outline: none !important;
  }
  &::placeholder {
    opacity: 0.5;
    font-color: ${DEFAULT_BORDER_COLOR};
  }
`;

export const DrugAndDropBrowserContainer = styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 10px;
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const Hint = styled('span')`
  font-size: 12px;
  color: var(--000000A6-antd);
`;

export const IconDiv = styled('div')`
  padding: 5px;
`;

export const BoldTextSpan = styled('span')`
  font-weight: bold;
`;

export const TextAreaDiv = styled('div')`
  text-align: center;
`;

export const TemplateLink = styled('a')`
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
`;

export const ImportButtonDiv = styled('div')`
  display: flex;
`;

export const TableProfile = styled(Table)`
  margin-bottom: 10px;
  thead > tr > th {
    background-color: var(--FFFFFF);
  }
  .ant-table-thead > tr > th {
    border-bottom: 0 solid var(--E8E8E7);
  }
`;

export const ContainerNameCol = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: baseline;
  opacity: 0.5;
`;

export const TableFooter = styled('div')`
  display: flex;
  align-items: flex-end;
`;

export const ButtonConfirm = styled(Button)`
  font-size: 12px;
  color: var(--1FC47D);
  border-color: var(--1FC47D);
  border-radius: 2px;
  :hover {
    opacity: 0.7;
  }
  margin-top: 10px;
  margin-left: auto;
  width: 70px;
  height: 30px;
  padding-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderDiv = styled('div')`
  display: inline-block;
  margin-left: 10px;
`;
