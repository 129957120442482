import { message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EmailInfo from './email-info';
import MenuItems from './menu-items';
import { AccountMenuPopoverCustom } from './styles';
import WorkspacesMenuSection from './workspaces-menu-section';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { sendActionAnalytics } from '../../../features/common/api';
import CreateWorkspaceModal from '../../../features/modalsComponents/components/create-workspace';
import InviteMembersModal from '../../../features/modalsComponents/components/invite-members';
import { LogoutNotificationModal } from '../../../features/modalsComponents/components/logout-notification/index.js';
import ProfilesImportManager from '../../../features/modalsComponents/components/profiles-import-manager';
import ShareAccountModal from '../../../features/modalsComponents/components/share-account-modal';
import { ISharedAccount } from '../../../interfaces';
import { userContext, WORKSPACE_DEFAULT_VALUE, workspaceContext } from '../../../state';
import { useCurrentTheme } from '../../../state/theme.atom';
import { getAccessibleFolderName } from '../../../utils/check-folders-to-add-profile';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { openWebsitePricing } from '../../../utils/open-site';
import AccountManager from '../../AccountManager';
import GologinPopover from '../../gologin-popover';
import { GologinPopoverDivider } from '../../gologin-popover/gologin-popover-divider';
import { requestGetSharedAccounts } from '../../Header/api';

interface IAccountMenu {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const AccountMenu: FC<IAccountMenu> = (props) => {
  const { anchorEl, onClose } = props;

  const [inviteMembersModalVisible, setInviteMembersModalVisible] = useState<boolean>(false);
  const [createWorkspaceModalVisible, setCreateWorkspaceModalVisible] = useState<boolean>(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [browserManagerModalVisible, setBrowserManagerModalVisible] = useState<boolean>(false);
  const [visibleInviteAccountModal, setVisibleInviteAccountModal] = useState<boolean>(false);
  const [switchAccountModalVisible, setSwitchAccountModalVisible] = useState<boolean>(false);
  const [importForPaidUsersPopUp, setImportForPaidUsersPopUp] = useState<boolean>(false);
  const [sharedAccounts, setSharedAccounts] = useState<ISharedAccount[]>([]);

  const theme = useCurrentTheme();

  const {
    hasTrial,
    isQuickSettingsEnabled,
    hasSuccessPayment,
  } = useContext(userContext);

  const {
    id: workspaceId,
    folders: workspaceFolders,
    me,
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
    permissions,
    updateAvailableWorkspaces,
    updateWorkspace,
  } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  useEffect(() => {
    getShareInfo();
  }, []);

  const resetWorkspaceContext = (): void => {
    updateAvailableWorkspaces([], true);
    updateWorkspace(WORKSPACE_DEFAULT_VALUE);
  };

  const toggleShareAccountModal = (state: boolean): void => {
    if (state) {
      onCloseAccountMenu();
      sendActionAnalytics('visited add member');
    }

    setVisibleInviteAccountModal(state);
  };

  const getShareInfo = async (): Promise<void> => {
    const mySharedAccounts = await requestGetSharedAccounts().catch(() => []);
    setSharedAccounts(mySharedAccounts);
  };

  const toggleAccountModal = async (state: boolean): Promise<void> => {
    if (state) {
      onCloseAccountMenu();
      await getShareInfo();
    }

    setSwitchAccountModalVisible(state);
  };

  const openBrowserImport = (): void => {
    if (NEW_FEATURES.workspaces) {
      if (workspaceIsUnpaid || workspaceIsTrial) {
        setImportForPaidUsersPopUp(true);

        return;
      }

      const isImportAvailable = checkIsImportAvailable();
      if (!isImportAvailable) {
        message.error(translation('notifications.error.permissionWorkspace'));

        return;
      }
    } else if (!hasSuccessPayment || hasTrial) {
      setImportForPaidUsersPopUp(true);

      return;
    }

    onCloseAccountMenu();
    setBrowserManagerModalVisible(true);
  };

  const redirectToSite = (): void => {
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
    });

    setImportForPaidUsersPopUp(false);
  };

  const checkIsImportAvailable = (): boolean => {
    if (permissions.importProfile) {
      return true;
    }

    const folderToImport = getAccessibleFolderName({
      folders: workspaceFolders,
      permission: 'importProfile',
      limitedAccess: !!me?.limitedAccess,
      hasFirstFolder: true,
      hasSelectedFolder: true,
    });

    return !!folderToImport;
  };

  const onCloseAccountMenu = (): void => {
    setImportForPaidUsersPopUp(false);
    onClose();
  };

  return (
    <>
      <GologinPopover
        anchorEl={anchorEl}
        onClose={onCloseAccountMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        zIndex={1029}
      >
        <EmailInfo />
        <GologinPopoverDivider />
        {NEW_FEATURES.workspaces ? (
          <WorkspacesMenuSection
            onClose={onClose}
            openCreateWorkspace={(): void => setCreateWorkspaceModalVisible(true)}
          />
        ) : null}
        <MenuItems
          onClose={onCloseAccountMenu}
          toggleShareAccountModal={toggleShareAccountModal}
          toggleAccountModal={toggleAccountModal}
          visibleSwitchAccount={!!sharedAccounts.length}
          importForPaidUsersPopUp={importForPaidUsersPopUp}
          setImportForPaidUsersPopUp={setImportForPaidUsersPopUp}
          openBrowserImport={openBrowserImport}
          redirectToSite={redirectToSite}
          openInviteMembers={(): void => setInviteMembersModalVisible(true)}
          openCreateWorkspace={(): void => setCreateWorkspaceModalVisible(true)}
          setIsLogoutModalVisible={setIsLogoutModalVisible}
        />
      </GologinPopover>
      {visibleInviteAccountModal ? (
        <ShareAccountModal
          switchShareAccountModalVisible={visibleInviteAccountModal}
          toggleShareAccountModal={toggleShareAccountModal}
        />
      ) : null}
      {switchAccountModalVisible ? (
        <AccountManager
          toggleAccountModal={(): Promise<void> => toggleAccountModal(false)}
          switchAccountModalVisible={switchAccountModalVisible}
          sharedAccounts={sharedAccounts}
          resetWorkspaceContext={resetWorkspaceContext}
        />
      ) : null}
      <ProfilesImportManager
        profileImportModalVisible={browserManagerModalVisible}
        toggleProfileImportManagerModal={setBrowserManagerModalVisible}
      />
      <InviteMembersModal
        visible={inviteMembersModalVisible}
        onClose={(): void => setInviteMembersModalVisible(false)}
        initWithFolderName={localStorage.getItem('SelectedFolder') || ''}
      />
      <LogoutNotificationModal isLogoutModalVisible={isLogoutModalVisible} setIsLogoutModalVisible={setIsLogoutModalVisible} />
      <CreateWorkspaceModal
        visible={createWorkspaceModalVisible}
        onClose={(): void => setCreateWorkspaceModalVisible(false)}
      />
    </>
  );
};

export default AccountMenu;
