/* eslint-disable max-lines */
import { TFunction } from 'i18next';
import { Atom, atom, getDefaultStore, useAtomValue } from 'jotai';
import { splitAtom } from 'jotai/utils';

import { IGenericFolder, getFolderById, getFoldersList } from './folders/folders-list.atom';
import { resetProfileRunStatuses } from './profile-run-statuses.atom';
import { getProfilesTableProxyIdFilter, resetProfilesTableProxyIdFilter } from './profiles-proxy-filter.atom';
import { closeProfilesSettings } from './profiles-settings-atom';
import { loadGroupsFromLocalStorage, saveGroupsToLocalStorage } from './profiles-table/groups-local-storage';
import { resetProfilesQueryOffset, setShouldProfilesQueryMakeRequests } from './profiles-table/profiles-query';
import { closeProfileTableModal } from './profiles-table-modal.atom';
import { resetProfilesTableSelectedIds, toggleProfilesSelected } from './profiles-table-selected-ids.atom';
import { closeQuickPricing } from './quick-pricing.atom';
import { resetToDefaultSearchState } from './search-state.atom';
import { getHasSelectedFolder, getSelectedFolderId } from './selected-folder.atom';
import { getTagsList } from './tags/tags-list.atom';
import { NEW_FEATURES } from './feature-toggle/new-features';
import { NEW_PROFILE_PAGE_CUSTOM_STATUS_ID, NEW_PROFILE_PAGE_FOLDER_NAME } from '../../common/constants/constants';
import { isNotNull } from '../../common/typescript/predicates';
import { IUpdateProfileStatusEvent } from '../../electron/interfaces/profile.status.manager.interfaces';
import { IProfilesResponse } from '../features/quickProfiles/api';
import { ITag } from '../features/tags/interfaces/tag.interface';
import { IProfile } from '../interfaces';
import { IGroupHeader, GroupHeaderLoadingStatus, IGroupFilterType } from '../interfaces/group-header.interface';
import { IWorkspaceProfilePermissions } from '../interfaces/workspaces';
import { ProfileStatusType } from '../types';
import getProfileWorkspaceFolderIds from '../utils/get-profile-workspace-folder-ids';
import { removeArrayDuplicates } from '../utils/remove-array-duplicates';

const profilesListAtom = atom<IProfile[]>([]);
const profileAtomListAtom = splitAtom(profilesListAtom);

const profilesTableGroupHeadersAtom = atom<IGroupHeader[]>([]);
const profilesTableGroupHeadersListAtom = splitAtom(profilesTableGroupHeadersAtom);

export type GroupField = IGroupFilterType | null;

export const profilesTableGroupFieldAtom = atom<GroupField>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);

  return groupHeaders[0]?.filter.type || null;
});

export const useIsProfilesTableGrouped = (): boolean => {
  const groupField = useAtomValue(profilesTableGroupFieldAtom);

  return !!groupField;
};

export const getIsProfilesTableGrouped = (): boolean => {
  const groupField = getDefaultStore().get(profilesTableGroupFieldAtom);

  return !!groupField;
};

export const useProfilesTableGroupField = (): GroupField => useAtomValue(profilesTableGroupFieldAtom);
export const getProfilesTableGroupField = (): GroupField => getDefaultStore().get(profilesTableGroupFieldAtom);

export const profilesTableHiddenGroupsAtom = atom<IGroupHeader[]>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const initializingGroupIndex = groupHeaders.findIndex((groupHeader) => groupHeader.loadingStatus === 'loading-initiated');

  return groupHeaders.filter((groupHeader, idx) => (!groupHeader.isOpen || idx < initializingGroupIndex) && !isNoEntitiesGroupHeader(groupHeader));
});

export const filterProfileByGroupHeader = (groupHeader: IGroupHeader, profile: IProfile): boolean => {
  const { filter: groupFilter } = groupHeader;
  const { type } = groupFilter;

  switch (type) {
    case 'custom-status':
      if (!groupFilter.customStatusId) {
        return !profile.tags.find(tag => tag.field === type);
      }

      return !!profile.tags.find(tag => tag.field === type && tag.id === groupFilter.customStatusId);
    case 'folder': {
      const profileFolderIds = getProfileWorkspaceFolderIds(profile);
      if (!groupFilter.folderId) {
        return !profileFolderIds.length;
      }

      return !!profileFolderIds.find(folderId => folderId === groupFilter.folderId);
    }
    default:
      console.warn(`unrecognized group type: ${type}`);

      return false;
  }
};

export const NO_CUSTOM_STATUS_GROUP_ID = 'no-custom-status';
export const NO_FOLDER_GROUP_ID = 'no-folder';

const isNoEntitiesGroupHeader = (groupHeader: IGroupHeader): boolean => [NO_CUSTOM_STATUS_GROUP_ID, NO_FOLDER_GROUP_ID].includes(groupHeader.id);

const makeGroupHeadersForCustomStatuses = (allTags: ITag[]): IGroupHeader[] => allTags
  .reduce<IGroupHeader[]>((acc, tag) => {
    if (tag.field !== 'custom-status') {
      return acc;
    }

    acc.push({
      id: tag.id,
      isGroupHeader: true,
      filter: { type: 'custom-status', customStatusId: tag.id },
      isOpen: true,
      loadingStatus: 'unloaded',
      totalProfiles: null,
    });

    return acc;
  }, [])
  .concat([{
    id: NO_CUSTOM_STATUS_GROUP_ID,
    isGroupHeader: true,
    filter: { type: 'custom-status', customStatusId: null },
    isOpen: true,
    loadingStatus: 'unloaded',
    totalProfiles: null,
  }]);

const makeGroupHeadersForFolders = (folders: IGenericFolder[]): IGroupHeader[] => folders
  .reduce<IGroupHeader[]>((acc, folder) => {
    if (folder.shared) {
      return acc;
    }

    acc.push({
      id: folder.id,
      isGroupHeader: true,
      filter: { type: 'folder', folderId: folder.id },
      isOpen: true,
      loadingStatus: 'unloaded',
      totalProfiles: null,
    });

    return acc;
  }, [])
  .concat([{
    id: NO_FOLDER_GROUP_ID,
    isGroupHeader: true,
    filter: { type: 'folder', folderId: null },
    isOpen: true,
    loadingStatus: 'unloaded',
    totalProfiles: null,
  }]);

export const groupProfilesTableByCustomStatus = (): void => {
  const allTags = getTagsList();
  const groupHeaders = makeGroupHeadersForCustomStatuses(allTags);
  setProfilesTableGroupHeaders(groupHeaders);
};

export const generateGroupHeaders = (groupBy?: IGroupFilterType | null): IGroupHeader[] | null => {
  let groupHeaders = null;
  if (groupBy === 'custom-status') {
    const allTags = getTagsList();
    groupHeaders = makeGroupHeadersForCustomStatuses(allTags);
  }

  if (groupBy === 'folder') {
    const allFolders = [...getFoldersList()];
    const selectedFolderId = getSelectedFolderId();
    const selectedFolderIdx = allFolders.findIndex((folder) => folder.id.toString() === selectedFolderId);
    if (selectedFolderIdx !== -1) {
      const [selectedFolder] = allFolders.splice(selectedFolderIdx, 1);
      allFolders.unshift(selectedFolder);
    }

    groupHeaders = makeGroupHeadersForFolders(allFolders);
  }

  return groupHeaders;
};

export const groupProfilesTable = (groupBy?: IGroupFilterType | null): void => {
  if (!groupBy) {
    return;
  }

  const groupHeaders = generateGroupHeaders(groupBy);
  if (!groupHeaders) {
    return;
  }

  setProfilesTableGroupHeaders(groupHeaders);
};

export const reconcileGroups = (): void => {
  const groupBy = getProfilesTableGroupField();
  const prevGroupHeaders = getProfilesTableGroupHeaders();
  const generatedGroupHeaders = generateGroupHeaders(groupBy);
  if (!generatedGroupHeaders) {
    return;
  }

  const newGroupHeaders = generatedGroupHeaders.map((generatedGroupHeader) => {
    const prevGroupHeader = prevGroupHeaders.find(({ id }) => id === generatedGroupHeader.id);
    let groupStatusFields = { ...prevGroupHeader };
    if (!prevGroupHeader) {
      const createdGroupProfiles = getProfilesByGroupHeaderObj(generatedGroupHeader);
      groupStatusFields = { totalProfiles: createdGroupProfiles.length, loadingStatus: 'loaded' };
    }

    return {
      ...generatedGroupHeader,
      ...groupStatusFields,
    };
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export const reconcileCustomStatusGroups = (): void => {
  if (getProfilesTableGroupField() !== 'custom-status') {
    return;
  }

  reconcileGroups();
};

export const reconcileFolderGroups = (): void => {
  if (getProfilesTableGroupField() !== 'folder') {
    return;
  }

  reconcileGroups();
};

export const resetProfilesTableGroups = (): void => {
  setProfilesTableGroupHeaders([]);
};

export const reloadProfilesTableGroups = (): void => {
  // if filters by proxy id - we can't yet show reliable groups
  if (getProfilesTableProxyIdFilter()) {
    resetProfilesTableGroups();

    return;
  }

  const localStorageGroupsState = loadGroupsFromLocalStorage();
  if (!localStorageGroupsState) {
    return;
  }

  const groupHeaders = generateGroupHeaders(localStorageGroupsState.field) || [];
  setProfilesTableGroupHeaders(groupHeaders.map((groupHeader) => {
    const localGroupHeader = localStorageGroupsState.groupHeaders.find((localHeader) => localHeader.id === groupHeader.id);
    const isOpen = localGroupHeader?.isOpen ?? true;

    return {
      ...groupHeader,
      isOpen,
    };
  }));
};

export const resetProfilesTableGroupStatus = (): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const unloadedStatus: GroupHeaderLoadingStatus = 'unloaded';
  const newGroupHeaders = groupHeaders.map((groupHeader) => ({
    ...groupHeader,
    loadingStatus: unloadedStatus,
    totalProfiles: null,
  }));

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export interface IProfileRunStatus {
  id: IProfile['id'];
  status: ProfileStatusType;
  statusMessage?: IUpdateProfileStatusEvent['message'];
  isWeb?: boolean;
  remoteOrbitaUrl?: string;
}

export interface IBasicTableEntity {
  idx: number;
  atom: Atom<IProfile | IGroupHeader>;
}

export interface IBasicTableGroupHeader {
  idx: number;
  atom: Atom<IGroupHeader>;
}

export const isGroupHeader = (dataItem: IProfile | IGroupHeader): dataItem is IGroupHeader => (dataItem as IGroupHeader).isGroupHeader;

export const isBasicTableEntityGroupHeader = (entity: IBasicTableEntity): entity is IBasicTableGroupHeader => {
  const dataObj = getDefaultStore().get(entity.atom);

  return isGroupHeader(dataObj);
};

const basicTableProfilesAtom: Atom<IBasicTableEntity[]> = atom<IBasicTableEntity[]>((get) => {
  let basicTableEntities: IBasicTableEntity[] = [];
  try {
    basicTableEntities = get(basicTableProfilesAtom);
  } catch (error: any) {
    if (error.message !== 'no atom init') {
      throw error;
    }
  }

  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const groupHeaderAtoms = get(profilesTableGroupHeadersListAtom);
  const profiles = get(profilesListAtom);
  const profileAtoms = get(profileAtomListAtom);

  if (!groupHeaders.length) {
    const newBasicTableEntities = profileAtoms.map((profileAtom, idx) => ({ idx, atom: profileAtom }));

    if (basicTableEntities.length !== newBasicTableEntities.length) {
      return newBasicTableEntities;
    }

    basicTableEntities.forEach(basicTableEntity => {
      const { idx, atom: prevAtom } = basicTableEntity;
      const newAtom = newBasicTableEntities[idx].atom;
      const prevAtomValue = getDefaultStore().get(prevAtom);
      const newAtomValue = getDefaultStore().get(newAtom);

      if (prevAtomValue.id !== newAtomValue.id) {
        getDefaultStore().set(prevAtom, newAtomValue);
      }
    });

    return basicTableEntities;
  }

  const groups = groupHeaders.map((groupHeader) => {
    const groupProfileIndexes = profiles.reduce<number[]>((acc, profile, index) => {
      if (filterProfileByGroupHeader(groupHeader, profile)) {
        acc.push(index);
      }

      return acc;
    }, []);

    const actualProfilesCount = groupProfileIndexes.length;
    if (groupHeader.loadingStatus === 'loaded' && groupHeader.totalProfiles !== actualProfilesCount) {
      updateGroupHeader(groupHeader.id, { totalProfiles: actualProfilesCount });
    }

    if (!(groupHeader.isOpen && ['loading', 'loaded'].includes(groupHeader.loadingStatus))) {
      return { ...groupHeader, profileIndexes: [] };
    }

    return { ...groupHeader, profileIndexes: groupProfileIndexes };
  });

  const newBasicTableEntities = groups.reduce<IBasicTableEntity[]>((acc, group, groupIdx) => {
    const { profileIndexes } = group;

    acc.push({ idx: acc.length, atom: groupHeaderAtoms[groupIdx] });
    acc.push(...profileIndexes.map((profileIdx, arrIdx) => ({ idx: acc.length + arrIdx, atom: profileAtoms[profileIdx] })));

    return acc;
  }, []);

  // TODO: update targeted atoms and not whole array all the time
  // it is blocked by group/profiles atoms overriding each other
  // not too easy to fix right now
  return newBasicTableEntities;
});

export const useBasicTableProfiles = (): IBasicTableEntity[] => useAtomValue(basicTableProfilesAtom);
export const getBasicTableProfiles = (): IBasicTableEntity[] => getDefaultStore().get(basicTableProfilesAtom);

export const getBasicTableProfileById = (profileId: string): IBasicTableEntity | null => getBasicTableProfiles().find(
  basicTableProfile => getDefaultStore().get(basicTableProfile.atom)?.id === profileId,
) || null;

export const getProfileByBasicProfilesIdx = (idx: number): IProfile | null => {
  const basicTableProfiles = getBasicTableProfiles();
  const basicTableProfile = basicTableProfiles[idx];
  if (!basicTableProfile?.atom) {
    return null;
  }

  const entityAtom = basicTableProfile.atom;
  const entity = getDefaultStore().get(entityAtom);
  if (!entity || isGroupHeader(entity)) {
    return null;
  }

  return entity;
};

export const getBasicTableProfileIds = (): string[] => getBasicTableProfiles().map(
  basicTableProfile => {
    const basicTableEntity = getDefaultStore().get(basicTableProfile.atom);
    if (basicTableEntity && !isGroupHeader(basicTableEntity)) {
      return basicTableEntity.id;
    }

    return null;
  },
).filter(isNotNull);

export const getBasicTableEntities = (): (IProfile | IGroupHeader)[] => getBasicTableProfiles().map(
  basicTableProfile => getDefaultStore().get(basicTableProfile.atom),
).filter(isNotNull);

export const useProfilesList = (): IProfile[] => useAtomValue(profilesListAtom);
export const getProfilesList = (): IProfile[] => getDefaultStore().get(profilesListAtom);
export const setProfilesList = (newProfiles: IProfile[]): void => getDefaultStore().set(profilesListAtom, newProfiles);

export const appendDeduplicatedProfilesToList = (profilesToAppend: IProfile[]): void => {
  const combinedProfilesList = getProfilesList().concat(profilesToAppend);
  setProfilesList(removeArrayDuplicates(combinedProfilesList));
};

export const useProfilesTableGroupHeaders = (): IGroupHeader[] => useAtomValue(profilesTableGroupHeadersAtom);
export const getProfilesTableGroupHeaders = (): IGroupHeader[] => getDefaultStore().get(profilesTableGroupHeadersAtom);
const setProfilesTableGroupHeaders = (newGroups: IGroupHeader[]): void => {
  if (!NEW_FEATURES.header) {
    getDefaultStore().set(profilesTableGroupHeadersAtom, []);

    return;
  }

  getDefaultStore().set(profilesTableGroupHeadersAtom, newGroups);
  saveGroupsToLocalStorage(newGroups);
  saveFirstGroupToNewProfileStorage();
};

export const calculateIsProfilesTableIniting = (): boolean => {
  if (getBasicTableProfileIds().length) {
    return false;
  }

  const groupHeaders = getProfilesTableGroupHeaders();
  if (!groupHeaders.length) {
    return true;
  }

  return !groupHeaders.some((groupHeader) => (groupHeader.totalProfiles || groupHeader.loadingStatus === 'loaded'));
};

// new profile page is such a mess, it is too costly to pass it in some other way
// specifically because, at the time of this writing, groups reset when you switch to it
const saveFirstGroupToNewProfileStorage = (): void => {
  let folderToSet = null;
  let customStatusToSet = null;

  const [firstGroupHeader] = getProfilesTableGroupHeaders();
  switch (firstGroupHeader?.filter.type) {
    case 'folder': {
      const { folderId } = firstGroupHeader.filter;
      const folder = getFolderById(folderId);
      folderToSet = folder?.name || null;
      break;
    }
    case 'custom-status': {
      const { customStatusId } = firstGroupHeader.filter;
      customStatusToSet = customStatusId;
      break;
    }
    default:
      break;
  }

  if (folderToSet) {
    sessionStorage.setItem(NEW_PROFILE_PAGE_FOLDER_NAME, folderToSet);
  } else {
    sessionStorage.removeItem(NEW_PROFILE_PAGE_FOLDER_NAME);
  }

  if (customStatusToSet) {
    sessionStorage.setItem(NEW_PROFILE_PAGE_CUSTOM_STATUS_ID, customStatusToSet);
  } else {
    sessionStorage.removeItem(NEW_PROFILE_PAGE_CUSTOM_STATUS_ID);
  }
};

export const doesGroupHaveVisibleContent = (groupHeader: IGroupHeader | null): boolean => {
  if (!groupHeader) {
    return false;
  }

  const { isOpen, totalProfiles, loadingStatus } = groupHeader;

  return !!(isOpen && totalProfiles && loadingStatus !== 'loading-initiated');
};

export const getGroupHeaderById = (groupHeaderId: IGroupHeader['id']): IGroupHeader | null => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const groupHeader = groupHeaders.find((groupHeader) => groupHeader.id === groupHeaderId);

  return groupHeader || null;
};

export const updateGroupHeader = (groupHeaderId: IGroupHeader['id'], fieldsToUpdate: Partial<IGroupHeader>): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  setProfilesTableGroupHeaders(groupHeaders.map((groupHeader) => {
    if (groupHeader.id === groupHeaderId) {
      return { ...groupHeader, ...fieldsToUpdate };
    }

    return groupHeader;
  }));
};

export const openProfilesTableGroupHeader = (groupHeaderId: IGroupHeader['id']): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const groupHeaderIdx = groupHeaders.findIndex((groupHeader) => groupHeader.id === groupHeaderId);
  const groupHeader = groupHeaders[groupHeaderIdx];
  if (!groupHeader || groupHeader.isOpen) {
    return;
  }

  setShouldProfilesQueryMakeRequests(false);

  const groupsToUnload = groupHeaders.slice(groupHeaderIdx + 1).map(({ id }) => id);
  if (groupsToUnload.length) {
    unloadGroups(groupsToUnload);
  }

  updateGroupHeader(groupHeaderId, { isOpen: true, loadingStatus: 'loading-initiated' });
  setShouldProfilesQueryMakeRequests(true);

  resetProfilesQueryOffset();
};

export const closeProfilesTableGroupHeader = (groupHeaderId: IGroupHeader['id']): void => {
  const groupHeader = getGroupHeaderById(groupHeaderId);
  if (!groupHeader?.isOpen) {
    return;
  }

  setShouldProfilesQueryMakeRequests(false);
  unloadGroups([groupHeaderId], true);
  if (getBasicTableEntities().length < 30) {
    resetProfilesQueryOffset();
  }

  setShouldProfilesQueryMakeRequests(true);
};

const unloadGroups = (groupHeaderIds: string[], shouldClose = false): void => {
  const unloadedStatus: GroupHeaderLoadingStatus = 'unloaded';
  const newGroupHeaders = getProfilesTableGroupHeaders().map((groupHeader) => {
    if (groupHeaderIds.includes(groupHeader.id)) {
      const fieldsToSet: Partial<IGroupHeader> = { loadingStatus: unloadedStatus };
      if (shouldClose) {
        fieldsToSet.isOpen = false;
      }

      return { ...groupHeader, ...fieldsToSet };
    }

    return groupHeader;
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
  removeGroupProfilesFromList(groupHeaderIds);
};

const removeGroupProfilesFromList = (groupHeaderIds: Array<IGroupHeader['id']>): void => {
  const allProfilesGroups = getAllProfilesGroups();
  const profileIdsToRemove = allProfilesGroups.reduce<string[]>((acc, profileGroups) => {
    const { profileId, profileGroupIds: groupIds } = profileGroups;
    const isInGroups = groupIds.length && groupIds.every((profileGroupId) => groupHeaderIds.includes(profileGroupId));
    if (isInGroups) {
      acc.push(profileId);
    }

    return acc;
  }, []);

  if (!profileIdsToRemove.length) {
    return;
  }

  mapAndSetProfilesList((prevProfiles) =>
    prevProfiles.filter((profile) => !profileIdsToRemove.includes(profile.id)),
  );
};

const firstOpenUnloadedGroupHeaderAtom = atom<IGroupHeader['id'] | null>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const firstUnloadedGroup = groupHeaders.find((groupHeader) =>
    groupHeader.loadingStatus !== 'loaded' && groupHeader.isOpen,
  );

  return firstUnloadedGroup?.id || null;
});

const useFirstOpenUnloadedGroupHeader = (): IGroupHeader['id'] | null => useAtomValue(firstOpenUnloadedGroupHeaderAtom);

export const useShouldGroupBeVisibleInTable = (groupHeaderId: IGroupHeader['id']): boolean => {
  const groupHeaders = useProfilesTableGroupHeaders();
  const firstOpenUnloadedGroupHeaderId = useFirstOpenUnloadedGroupHeader();

  const isNoFolderGroup = groupHeaderId === NO_FOLDER_GROUP_ID;
  const hasSelectedFolder = getHasSelectedFolder();
  if (isNoFolderGroup && hasSelectedFolder) {
    return false;
  }

  if (!firstOpenUnloadedGroupHeaderId || firstOpenUnloadedGroupHeaderId === groupHeaderId) {
    return true;
  }

  const firstToFind = groupHeaders.find((groupHeader) => groupHeader.id === firstOpenUnloadedGroupHeaderId || groupHeader.id === groupHeaderId);
  if (!firstToFind) {
    return false;
  }

  return firstToFind.id !== firstOpenUnloadedGroupHeaderId;
};

export const mapAndSetProfilesList = (mapProfilesList: (prevProfiles: IProfile[]) => IProfile[]): void =>
  getDefaultStore().set(profilesListAtom, mapProfilesList(getDefaultStore().get(profilesListAtom)));

export const editProfilesListFields = (profileIds: string[], newData: Partial<IProfile>): void =>
  mapAndSetProfilesList((profiles) => profiles.map((profile) => {
    if (profileIds.includes(profile.id)) {
      return { ...profile, ...newData };
    }

    return profile;
  }));

export const filterProfilesByPermission = (
  profilesIds: string[],
  permission: keyof IWorkspaceProfilePermissions,
): IProfile[] => getProfilesList()
  .filter(profile => profilesIds.find(profileId => profileId === profile.id && profile.permissions[permission]));

export const resetProfilesList = (): void => {
  setProfilesList([]);
  resetProfilesTableSelectedIds();
  resetProfileRunStatuses();
  resetProfilesQueryOffset();
  resetProfilesTableGroupStatus();
};

export const resetProfilesTable = (): void => {
  resetProfilesList();
  closeProfilesSettings();
  closeProfileTableModal();
  closeQuickPricing();
  resetToDefaultSearchState();
  resetProfilesTableProxyIdFilter();
  resetProfilesTableGroups();
};

const getAllProfilesGroups = (): Array<{ profileId: string; profileGroupIds: string[] }> => {
  const profiles = getProfilesList();
  const groupHeaders = getProfilesTableGroupHeaders();

  return profiles.map((profile) => {
    const profileGroupIds = groupHeaders.reduce<string[]>((acc, groupHeader) => {
      if (filterProfileByGroupHeader(groupHeader, profile)) {
        acc.push(groupHeader.id);
      }

      return acc;
    }, []);

    return {
      profileId: profile.id,
      profileGroupIds,
    };
  });
};

const getProfilesByGroupHeaderObj = (groupHeader: IGroupHeader): IProfile[] => {
  const profiles = getProfilesList();

  return profiles.filter((profile) => filterProfileByGroupHeader(groupHeader, profile));
};

export const getGroupProfiles = (groupHeaderId: string): IProfile[] => {
  const groupHeader = getGroupHeaderById(groupHeaderId);
  if (!groupHeader) {
    return [];
  }

  return getProfilesByGroupHeaderObj(groupHeader);
};

export const getGroupProfilesCount = (groupHeaderId: string): number => getGroupProfiles(groupHeaderId).length;

type IUpdateGroupHeadersStatus = Pick<IProfilesResponse, 'groupsMetadata'>;

export const updateProfilesTableGroupHeadersStatus = (profilesResponse: IUpdateGroupHeadersStatus): void => {
  const { groupsMetadata } = profilesResponse;
  if (!groupsMetadata) {
    return;
  }

  const newGroupHeaders = getProfilesTableGroupHeaders().map((groupHeader) => {
    const { id: groupId, isOpen, loadingStatus: prevLoadingStatus } = groupHeader;
    const isNoEntitiesGroup = isNoEntitiesGroupHeader(groupHeader);
    let groupMetadata = groupsMetadata.find((gm) => gm.groupId === groupId || (!gm.groupId && isNoEntitiesGroup));
    if (!groupMetadata) {
      groupMetadata = { groupId, filteredProfilesCount: 0 };
    }

    const { filteredProfilesCount } = groupMetadata;
    const newProfilesCount = getGroupProfilesCount(groupId);
    let newStatus: GroupHeaderLoadingStatus = isOpen ? 'unloaded' : prevLoadingStatus;
    if (isOpen && newProfilesCount && newProfilesCount < filteredProfilesCount) {
      newStatus = 'loading';
    } else if (isOpen && newProfilesCount >= filteredProfilesCount) {
      newStatus = 'loaded';
    }

    return { ...groupHeader, loadingStatus: newStatus, totalProfiles: filteredProfilesCount };
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export const getProfileNamesForNotifications = (profileIds: string[], translation: TFunction): string => {
  const profiles = getProfilesList().filter(profile => profileIds.includes(profile.id));
  const selectedProfilesName = profiles
    .map(profile => profile?.name.length > 15 ? profile?.name.slice(0, 12) + '...' : profile?.name);

  if (selectedProfilesName.length >= 2) {
    return selectedProfilesName.length + ' ' + translation('base.profiles');
  }

  return selectedProfilesName[0];
};

export const toggleGroupProfilesSelection = (groupHeaderId: string): void => {
  const profileIds = getGroupProfiles(groupHeaderId).map(({ id }) => id);
  openProfilesTableGroupHeader(groupHeaderId);
  toggleProfilesSelected(profileIds);
};
