import * as Sentry from '@sentry/react';

import { IBrowserFingerprintParams, IStartContainer } from './interfaces';
import { IExtraActionInfo } from '../../../common/constants/analytics';
import { API_BASE_URL, FORCE_LOAD_OLD_PRICING_KEY_NAME, LOCAL_GOOGLE_CLIENT_ID_KEY_NAME } from '../../../common/constants/constants';
import { ICookie } from '../../interfaces';
import { ISupportedOrbitaVersions } from '../../interfaces/supported-orbita-versions.interfaces';
import { IWorkspace, IWorkspaceShort, IWorkspaceWithFoldersShort } from '../../interfaces/workspaces';
import { http } from '../../services';
import { sendReactAnalyticsError } from '../../utils/sentry.helper';
import { IAnalyticsPaymentError, IAnalyticsPaymentErrorMode, IPaymentIntent } from '../pricing/interfaces';

export const requestUser = (): Promise<any> => {
  let getUserTransaction: any = {
    finish: () => null,
    traceId: '',
  };

  if (Math.random() <= 0.1) {
    getUserTransaction = Sentry.startTransaction({ name: 'get-user' });
  }

  return http(`${API_BASE_URL}/user`, {
    method: 'GET',
    headers: {
      'transaction-id': getUserTransaction.traceId,
    },
  }).then((res: any) => {
    const userData = res.body;
    localStorage.setItem(FORCE_LOAD_OLD_PRICING_KEY_NAME, userData.forceOldPricing);

    if (userData.googleClientId) {
      localStorage.setItem(LOCAL_GOOGLE_CLIENT_ID_KEY_NAME, userData.googleClientId);
    }

    return userData;
  }).finally(() => getUserTransaction.finish());
};

export const deleteTokenRequest = (tokenId: string): Promise<void> =>
  http(`${API_BASE_URL}/user/token/${tokenId}`, {
    method: 'DELETE',
  });

export const sendActionAnalytics = (action: string, extraInfo?: IExtraActionInfo): Promise<any> => {
  const extraParams = extraInfo ? { ...extraInfo } : {};
  extraParams.appVersion = window?.gologinAppVersion;

  return http(`${API_BASE_URL}/analytics/add_action_analytics`, {
    method: 'POST',
    body: JSON.stringify({ action, ...extraParams }),
  }).catch(sendReactAnalyticsError);
};

export const getSupportedOrbitaVersions = (): Promise<ISupportedOrbitaVersions> =>
  http(`${API_BASE_URL}/gologin-global-settings/supported_orbita_versions`)
    .then((response: Response) => response.body)
    .catch(() => ({ supportedOrbitaVersions: [] }));

export const requestFingerprint = (params: IBrowserFingerprintParams): Promise<any> => {
  const url = new URL(`${API_BASE_URL}/browser/fingerprint`);

  Object.entries(params).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    let valueNormalized = '';

    switch (typeof value) {
      case 'string':
        valueNormalized = value;
        break;
      case 'boolean':
        valueNormalized = value.toString();
        break;
      default:
        break;
    }

    url.searchParams.append(key, valueNormalized);
  });

  return http(url.toString()).then((res: any) => res.body);
};

interface IUserWorkspacesWithFoldersData {
  workspacesWithFolders: IWorkspaceWithFoldersShort[];
}

export const requestUserWorkspacesWithFolders = (): Promise<IUserWorkspacesWithFoldersData> =>
  http(`${API_BASE_URL}/workspaces/many/folders`, {
    method: 'GET',
  }).then((res: any) => res.body);

interface IUserWorkspacesData {
  workspaces: IWorkspaceShort[];
}

export const requestUserWorkspaces = (): Promise<IUserWorkspacesData> =>
  http(`${API_BASE_URL}/workspaces`, {
    method: 'GET',
  }).then((res: any) => res.body);

export const requestWorkspace = (workspaceId: string): Promise<IWorkspace> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}`, {
    method: 'GET',
  }).then((res: any) => res.body);

export const deleteWorkspace = (workspaceId: string): Promise<IWorkspace> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}`, {
    method: 'DELETE',
  }).then((res: any) => res.body);

export const updateDefaultWorkspace = (wid: string): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/default-workspace`, {
    method: 'PATCH',
    body: JSON.stringify({
      wid,
    }),
  });

interface ICreateWorkspace {
  id: string;
  owner: string;
  name: string;
}

interface ISendErrorPaymentAnalytics {
  messageText: unknown;
  extra: IAnalyticsPaymentErrorMode;
  method: IPaymentIntent['paymentMethod'];
  paymentError?: IAnalyticsPaymentError;
}

export const createWorkspace = (name: string | null, isFreePlan = false): Promise<ICreateWorkspace> =>
  http(`${API_BASE_URL}/workspaces`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      isFreePlan,
    }),
  }).then((res: any) => res.body);

export const requestShortCookies = async (id: string): Promise<ICookie[]> => {
  const url = `${API_BASE_URL}/browser/${id}/cookies/quick`;
  const data = await http(url, {
    method: 'GET',
  });

  return data.body;
};

export const sendErrorPaymentAnalytics = ({
  messageText,
  extra,
  method,
  paymentError,
}: ISendErrorPaymentAnalytics): void => {
  const modeString = Object.entries(extra).reduce((modeAcc, entry) => (modeAcc += entry.join(': ') + '; '), 'area: front; ');
  let actionString = '';
  actionString = JSON.stringify(messageText) || 'Message is undefined';

  sendActionAnalytics('got error - payment', {
    actionInfo: actionString,
    mode: modeString,
    paymentMethod: method,
    paymentError,
  })
    .catch(() => null);
};

export const getGoogleAnalyticsSessionId = (): string => {
  if (!document) {
    return '';
  }

  const gaSessionMatch = document.cookie.match(/_ga_LQVGW7X8RP=GS\d\.\d\.(.+?)(?:;|$)/);
  const gaSessionString = gaSessionMatch?.length ? gaSessionMatch[1] : '';

  return gaSessionString;
};

export const startContainer = async (profileId: string, workspaceId: string): Promise<IStartContainer> => {
  const failureObject = {
    status: 'profileStatuses.error',
    statusMessage: 'Cloud browser error. Try again later.',
    remoteOrbitaUrl: '',
  };

  return http(`${API_BASE_URL}/browser/${profileId}/web`, { method: 'POST', body: JSON.stringify({ workspaceId }) })
    .then((response?: { body: IStartContainer }) => {
      if (!response?.body) {
        return failureObject;
      }

      return response.body;
    })
    .catch(() => failureObject);
};

export const stopContainer = async (profileId: string): Promise<void> =>
  http(`${API_BASE_URL}/browser/${profileId}/web`, { method: 'DELETE' }).catch(() => null);

export const getVendorsList = (): Promise<{ vendorsList: string[] }> =>
  http(`${API_BASE_URL}/browser/features/vendors-list`, { method: 'GET' }).then((res: Response) => res.body);

export const getRenderersList = (vendor: string): Promise<{ renderersList: string[] }> =>
  http(`${API_BASE_URL}/browser/features/renderers-list?vendor=${encodeURIComponent(vendor)}`, { method: 'GET' }).then((res: Response) => res.body);
