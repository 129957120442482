import React from 'react';
import { Trans } from 'react-i18next';

import { ProxySectionContextMenu } from './proxy-section-context-menu';
import { ProxySectionItemTitle, ProxySectionItemWrapper } from './styles';
import { ProxyManagerListSection } from '../../../../../state/proxy/proxy-groups/interfaces';

type ProxySectionItemProps = Pick<ProxyManagerListSection, 'id' | 'title'> & {
  style: React.CSSProperties;
}

const ProxySectionItem: React.FC<ProxySectionItemProps> = (props) => {
  const { title, style } = props;

  const finalStyle: React.CSSProperties = {
    ...style,
    height: 30,
    margin: '1px 4px',
  };

  return (
    <ProxySectionItemWrapper style={finalStyle}>
      <ProxySectionItemTitle>
        <Trans i18nKey={title} />
      </ProxySectionItemTitle>
      <ProxySectionContextMenu />
    </ProxySectionItemWrapper>
  );
};

export default ProxySectionItem;
