import React from 'react';

import ProxyItemCheckbox from './proxy-item-checkbox';
import { ProxyItemWrapperEl, ProxyTitleBlockEl, ProxyTitleWrapperEl } from './styles/proxy-item';
import { ProxyFlagBlockEl } from './styles/proxy-list-item';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IProxy } from '../../../../interfaces';
import { ProxyCheckTooltipView } from '../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { ProxySelectorLocation } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { useSharedProxies } from '../../../../state/proxy/shared-proxies.atom';
import CheckProxyBtn from '../../check-proxy-button';
import { ISharedProxy } from '../../components/interfaces/shared-proxy.interfaces';
import ProxyFlag from '../../components/proxy-flag';
import { getProxyTitle } from '../../proxy-helpers';

interface IProxyItem {
  proxy?: IProxy;
  profileId?: string;
  isSharedProxy?: boolean;
  showCheckbox?: boolean;
  localProxyElementLocation?: string;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
}

const ProxyItem: React.FC<IProxyItem> = (props) => {
  const { proxy, profileId, isSharedProxy, showCheckbox, localProxyElementLocation, selectorContainerElement } = props;

  const sharedProxies = useSharedProxies();

  if (!proxy) {
    return null;
  }

  const title = getProxyTitle(proxy);
  const isFailed = (proxy?.status === false);
  const titleRes = title || '-';

  let sharedProxyInfo: ISharedProxy | undefined;
  let { country } = proxy;
  let checkProxy = proxy;
  if (isSharedProxy) {
    sharedProxyInfo = sharedProxies.find((el) => el.profileId === profileId);
    if (sharedProxyInfo) {
      ({ country } = sharedProxyInfo);
      checkProxy = {
        ...proxy,
        ...sharedProxyInfo,
      };
    }
  }

  const renderStatus = (): JSX.Element => {
    if (showCheckbox) {
      return <ProxyItemCheckbox proxy={proxy} />;
    }

    let proxyTooltipView: ProxyCheckTooltipView = 'proxy-list-item';
    if (localProxyElementLocation === ProxySelectorLocation.profileSettingsPage) {
      proxyTooltipView =ProxySelectorLocation.profileSettingsPage;
    }

    return (
      <CheckProxyBtn
        proxy={checkProxy}
        profileId={profileId || ''}
        proxyTooltipView={proxyTooltipView}
        selectorContainerElement={selectorContainerElement}
      />
    );
  };

  return (
    <ProxyItemWrapperEl>
      <ProxyFlagBlockEl newStyle={!!NEW_FEATURES.header} isFailed={isFailed} className='proxy-flag-block'>
        {renderStatus()}
        <ProxyFlag region={country} />
      </ProxyFlagBlockEl>
      <ProxyTitleWrapperEl newStyle={!!NEW_FEATURES.header}>
        <ProxyTitleBlockEl className='proxy-title' isFailed={isFailed}>
          <div className='nowrap'>
            {titleRes}
          </div>
        </ProxyTitleBlockEl>
      </ProxyTitleWrapperEl>
    </ProxyItemWrapperEl>
  );
};

export default ProxyItem;
