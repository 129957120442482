import { message } from 'antd';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useFilteredProxyList } from '../../../state/proxy/proxy-list.atom';
import { selectAllProxies, updateSelectedProxies, useSelectedProxies } from '../../../state/proxy/selected-proxies.atom';
import { switchConfirmBlockVisible, switchIsSelectProxyModeOpened, useIsSelectProxyModeOpened } from '../../../state/proxy-select-menu.atom';
import IconBasket from '../../../ui/icons/IconBasket';
import IconProxyClose from '../../../ui/icons/IconProxyClose';
import IconProxyCopy from '../../../ui/icons/IconProxyCopy';
import IconSelectAll from '../../../ui/icons/IconSelectAll';
import TooltipWrapper from '../../../ui/tooltip-custom/tooltip-wrapper';
import { copyProxies } from '../proxy-helpers';
import { FooterBtn } from '../proxy-manager/proxy-edit-view/styles';
import { ButtonsContainerEl, ProxyListMenuEl } from '../styles/proxy-list-menu';

const ProxyListMenu: FC = () => {
  const { selectedProxies } = useSelectedProxies();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const filteredProxies = useFilteredProxyList();

  const { t: translation } = useTranslation();

  const handleCancel = (): void => {
    updateSelectedProxies({
      selectedProxy: null,
      selectedProxies: [],
    });
    switchIsSelectProxyModeOpened(false);
  };

  const handleSelectAll = (): void => {
    const proxies = filteredProxies.filter((el) => !!el.host);
    if (selectedProxies.length === proxies.length) {
      updateSelectedProxies({
        selectedProxies: [],
      });
    } else {
      selectAllProxies();
    }
  };

  const handleDelete = (): void => {
    if (!selectedProxies.length) {
      message.error(translation('proxies.noSelectedProxies'));

      return;
    }

    updateSelectedProxies({
      selectedProxy: null,
    });
    switchConfirmBlockVisible(true);
  };

  const handleCopy = (): void => {
    const proxies = filteredProxies.filter(filteredProxy => selectedProxies.includes(filteredProxy.id));

    if (proxies.length) {
      copyProxies(proxies);
      message.success(translation('base.copiedText'));
    }
  };

  const footerButtons = [{
    title: translation('proxies.selectAllProxies'),
    onClick: handleSelectAll,
    icon: <IconSelectAll />,
  }, {
  /*
    TODO: check proxies
    title: t('proxies.checkProxies'),
    onClick: () => {},
    icon: <IconReload />,
  }, {
  */
    title: (selectedProxies) ? translation('proxies.copyProxy') : translation('proxies.selectProxiesToCopy'),
    onClick: handleCopy,
    icon: <IconProxyCopy />,
    tooltipTrigger: (selectedProxies) ? 'hover' : 'click',
  }, {
    title: (selectedProxies) ? translation('proxies.deleteProxies') : translation('proxies.selectProxiesToDelete'),
    onClick: handleDelete,
    icon: <IconBasket />,
    tooltipTrigger: (selectedProxies) ? 'hover' : 'click',
  }, {
    title: translation('proxies.cancel'),
    onClick: handleCancel,
    icon: <IconProxyClose />,
  }];

  if (!isSelectProxyModeOpened) {
    return null;
  }

  return (
    <ProxyListMenuEl>
      <ButtonsContainerEl>
        {footerButtons.map((button): JSX.Element => (
          <TooltipWrapper key={button.title} isTransition={true} value={<Trans i18nKey={button.title} />}>
            <FooterBtn
              onClick={button.onClick}
              type='button'
            >
              {button.icon}
            </FooterBtn>
          </TooltipWrapper>
        ))}
      </ButtonsContainerEl>
    </ProxyListMenuEl>
  );
};

export default ProxyListMenu;
