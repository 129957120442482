import React from 'react';
import { Trans } from 'react-i18next';

import { UnlinkButtonWrapper, UnlinkIconWrapper } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { getProxyManagerState } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconClose } from '../../../../ui/gologin-header/icons';
import { IconUnlink } from '../../../../ui/gologin-header/icons/icon-unlink';
import { IIconWrapper } from '../../../../ui/gologin-header/icons/wrapper';
import IconCloseCurrentColor from '../../../../ui/icons/IconCloseCurrentColor';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { handleNoProxyClick } from '../../proxy-helpers';

const iconProps: IIconWrapper = {
  padding: 0,
  iconColor: 'var(--B5B5BA-proxy-profiles-counter)',
  iconHoveredColor: 'var(--36363D-proxy-group-header-arrow-active)',
};

export type ProxyUnlinkButtonProps = {
  profileId: string;
  iconType: 'unlink' | 'cross';
}

const ProxyUnlinkButton: React.FC<ProxyUnlinkButtonProps> = (props) => {
  const { profileId, iconType } = props;

  const handleClickUnlick: React.MouseEventHandler<HTMLDivElement> = (event): void => {
    const { handleProxySelect } = getProxyManagerState();
    if (!handleProxySelect) {
      handleNoProxyClick(event, profileId);

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    handleProxySelect(null);
  };

  const renderUnlinkIcon = (): JSX.Element => {
    if (!NEW_FEATURES.proxyGroups) {
      return <IconCloseCurrentColor />;
    }

    if (iconType === 'cross') {
      return (
        <IconClose {...iconProps} />
      );
    }

    return (
      <IconUnlink {...iconProps} />
    );
  };

  return (
    <UnlinkButtonWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
      <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.unlinkProxy' />}>
        <UnlinkIconWrapper
          onClick={handleClickUnlick}
          isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}
        >
          {renderUnlinkIcon()}
        </UnlinkIconWrapper>
      </TooltipWrapper>
    </UnlinkButtonWrapper>
  );
};

export default ProxyUnlinkButton;
