import { isNotUndefined } from '../../common/typescript/predicates';
import countries from '../features/common/countries';
import { IProxy } from '../interfaces';

interface IFoundProxy {
  index: number;
  proxy: IProxy;
}

const splitByIndex = (value: string, index: number): [string, string] =>
  [value.substring(0, index).slice(0, -1), value.substring(index)];

export const proxyFromText = (text: string): IProxy | undefined => {
  text = text.replace(/\r?\n|\r/g, '');

  let changeIpUrl = '';
  const changeIpMatch = text.match(/\[(http.*)\]/);

  if (changeIpMatch) {
    const [matchBlock, matchUrl] = changeIpMatch;
    changeIpUrl = matchUrl;

    text = text.replace(matchBlock, '');
  }

  let mode = 'http';

  if (text.includes('https://') || text.includes('http://')) {
    text = text.includes('https://') ? text.replace('https://', '') : text.replace('http://', '');
    mode = 'http';
  }

  if (text.includes('tcp://')) {
    text = text.replace('tcp://', '');
    mode = 'socks5';
  }

  if (text.includes('udp://')) {
    text = text.replace('udp://', '');
    mode = 'http';
  }

  if (text.includes('socks4://')) {
    text = text.replace('socks4://', '');
    mode = 'socks4';
  }

  if (text.includes('socks5://')) {
    text = text.replace('socks5://', '');
    mode = 'socks5';
  }

  const colonCount = (text.match(/:/g) || []).length;

  if (!colonCount) {
    return;
  }

  const atCount = (text.match(/@/g) || []).length;
  if (colonCount === 1 && atCount === 1) {
    return;
  }

  if ((colonCount === 2 || colonCount === 3) && atCount >= 1) {
    const atLast = text.lastIndexOf('@');
    const splitProxyData = splitByIndex(text, atLast + 1);

    const [credentials, connectionData] = splitProxyData;
    const [username = '', password = ''] = credentials.split(':');
    const [host = '', port = 80, customName = ''] = connectionData.split(':');

    return clearSpaces({ mode, host, port, username, password, customName, changeIpUrl });
  }

  if (colonCount >= 1) {
    let [host = '', port = 80, username = '', password = '', customName = ''] = text.split(':');

    if (username && !password) {
      customName = username;
      username = '';
    }

    // http://192.168.0.1:8000:myproxy:pass:name1
    // socks5://login:password@192.168.0.1:8000:name2
    // socks5://login:password@192.168.0.1:8000[https://change-my-ip.com]:name3
    // http://192.168.0.1:8000:myproxy
    // http://192.168.0.1:8000:myproxy:pass[https://change-my-ip.com]
    // http://192.168.0.1:8000:myproxy:pass[https://change-my-ip.com]:name5

    return clearSpaces({ mode, host, port, username, password, customName, changeIpUrl });
  }
};

const clearSpaces = (p: IProxy): IProxy => ({
  mode: p.mode.trim(),
  host: p.host.trim(),
  port: (typeof p.port === 'string') ? Number(p.port.trim()) : p.port,
  username: p.username.trim(),
  password: p.password.trim(),
  customName: p.customName.trim(),
  changeIpUrl: p.changeIpUrl.trim(),
});

export const proxyFromClipboard = async (): Promise<IProxy | undefined> => {
  const clipboardText = await navigator.clipboard.readText();

  return proxyFromText(clipboardText);
};

export const parseMultipleProxies = async (text: string): Promise<IProxy[]> => {
  const clipboardText = text || await navigator.clipboard.readText();
  const proxyCandidates = clipboardText.trim().split(/\r?\n/);
  const proxies = proxyCandidates.map(proxyFromText);

  return proxies.filter(isNotUndefined);
};

export const getLinkForProxy = (proxy: IProxy): string => {
  const { host, port = 80, username = '', password = '' } = proxy;
  let { mode } = proxy;

  if (mode === 'geolocation') {
    mode = 'http';
  }

  let linkText = mode + '://';
  if (username) {
    linkText += username + (password ? `:${password}@` : '@');
  }

  linkText += host + ':' + port;

  return linkText;
};

const checkProxyBySearch = (proxy: any, search: string) => {
  const proxyLink = getLinkForProxy(proxy);

  let countryString = '';

  if (proxy.country) {
    const country = countries.find((el => el.code === proxy.country));
    if (country) {
      countryString = [proxy.country, country.name, country.nameRus].join(' ');
    }
  }

  return (proxy.profile ?? '').toLowerCase().includes(search)
    || (proxy.port ?? '').toString().toLowerCase().includes(search)
    || (proxyLink ?? '').toLowerCase().includes(search)
    || (proxy.host ?? '').toLowerCase().includes(search)
    || (proxy.username ?? '').toLowerCase().includes(search)
    || (proxy.password ?? '').toLowerCase().includes(search)
    || (proxy.mode ?? '').toLowerCase().includes(search)
    || (proxy.customName ?? '').toLowerCase().includes(search)
    || countryString.toLowerCase().includes(search);
};

export const filterProxiesBySearch = (proxies: any[], search: string, currentProxy?: IProxy): any[] => {
  let currentProxyInList = false;
  let filteredProxies = proxies.filter(proxy => {
    if (!currentProxyInList && currentProxy && currentProxy.id === proxy.id && currentProxy.mode !== 'none') {
      if (['gologin', 'tor'].includes(proxy.mode)) {
        if (proxy.mode === 'gologin' && currentProxy.autoProxyRegion === proxy.autoProxyRegion) {
          currentProxyInList = true;
        } else if (proxy.mode === 'tor' && currentProxy.torProxyRegion === proxy.torProxyRegion)  {
          currentProxyInList = true;
        }
      } else {
        currentProxyInList = true;
      }

      if (currentProxyInList) {
        return false;
      }
    }

    return checkProxyBySearch(proxy, search);
  });

  if (currentProxyInList && !search) {
    filteredProxies = [currentProxy, ...filteredProxies];
  }

  return filteredProxies;
};

export const hideProxiesBySearch = (proxies: any[], search: string): any[] =>
  proxies.map((proxy: any) => {
    const isMatchedProxy = checkProxyBySearch(proxy, search);
    proxy.hidden = !isMatchedProxy;

    return proxy;
  });

const getIndexOf = (proxyParam: string, search: string): number => (proxyParam).toLowerCase().indexOf(search);

const getPreparedArray = (arr: IFoundProxy[]): IFoundProxy[] => arr.sort((a, b): number => a.index - b.index);

export const filterProxies = (proxies: any[], search: string, currentProxy: IProxy|null = null): IProxy[] => {
  const resArray = getProxies(proxies, search, currentProxy);

  if (!search) {
    return resArray;
  }

  return resArray.filter((proxy, index, self) =>
    index === self.findIndex((el) => (
      proxy.mode === el.mode &&
      proxy.autoProxyRegion === el.autoProxyRegion &&
      proxy.torProxyRegion === el.torProxyRegion &&
      proxy.host === el.host &&
      proxy.port === el.port &&
      proxy.username === el.username &&
      proxy.password === el.password
    )));
};

const isCurrentProxyEqual = (proxy: IProxy, currentProxy?: IProxy): boolean => {
  if (!(currentProxy && currentProxy.mode !== 'none' && currentProxy.id === proxy.id)) {
    return false;
  }

  switch (proxy.mode) {
    case 'gologin':
      return currentProxy?.autoProxyRegion === proxy.autoProxyRegion;
    case 'tor':
      return currentProxy?.torProxyRegion === proxy.torProxyRegion;
    default:
      return true;
  }
};

const getProxies = (proxies: any[], search: string, currentProxy: IProxy|null = null): IProxy[] => {
  const foundByName: IFoundProxy[] = [];
  const foundByCountry: IFoundProxy[] = [];
  const foundByHost: IFoundProxy[] = [];
  const foundByPort: IFoundProxy[] = [];
  const foundByType: IFoundProxy[] = [];
  const foundByLogin: IFoundProxy[] = [];
  const foundByPassword: IFoundProxy[] = [];
  const foundByChangeIpUrl: IFoundProxy[] = [];

  let list: IProxy[] = [];

  let currentProxyInList = false;
  for (const proxy of proxies) {
    if (!currentProxyInList && currentProxy) {
      if (isCurrentProxyEqual(proxy, currentProxy)) {
        currentProxyInList = true;
      }

      if (currentProxyInList && !search) {
        continue;
      }
    }

    if (!search) {
      list.push(proxy);

      continue;
    }

    let index;
    index = getIndexOf(proxy.customName ?? '', search);
    if (index !== -1) {
      foundByName.push({
        index,
        proxy,
      });

      continue;
    }

    let countryString = '';

    if (proxy.country) {
      const country = countries.find((el => el.code === proxy.country));
      if (country) {
        countryString = [proxy.country, country.name, country.nameRus].join(' ');
      }
    }

    index = getIndexOf(countryString, search);
    if (index !== -1) {
      foundByCountry.push({
        index,
        proxy,
      });

      continue;
    }

    index = getIndexOf(proxy.host ?? '', search);
    if (index !== -1) {
      foundByHost.push({
        index,
        proxy,
      });

      continue;
    }

    index = getIndexOf(`${proxy.port}`, search);
    if (index !== -1) {
      foundByPort.push({
        index,
        proxy,
      });

      continue;
    }

    // TODO: rename gologin proxy => free proxy
    let { mode }  = proxy;
    if (mode === 'gologin') {
      mode = `${mode} free`;
    }

    index = getIndexOf(mode ?? '', search);
    if (index !== -1) {
      foundByType.push({
        index,
        proxy,
      });

      continue;
    }

    index = getIndexOf(proxy.username ?? '', search);
    if (index !== -1) {
      foundByLogin.push({
        index,
        proxy,
      });

      continue;
    }

    index = getIndexOf(proxy.password ?? '', search);
    if (index !== -1) {
      foundByPassword.push({
        index,
        proxy,
      });

      continue;
    }

    index = getIndexOf(proxy.changeIpUrl ?? '', search);
    if (index !== -1) {
      foundByChangeIpUrl.push({
        index,
        proxy,
      });

      continue;
    }
  }

  if (search) {
    list = getPreparedArray(foundByName).concat(
      getPreparedArray(foundByCountry),
      getPreparedArray(foundByHost),
      getPreparedArray(foundByPort),
      getPreparedArray(foundByType),
      getPreparedArray(foundByLogin),
      getPreparedArray(foundByPassword),
      getPreparedArray(foundByChangeIpUrl),
    )
      .map(el => el.proxy);
  }

  if (currentProxyInList && currentProxy && !search) {
    list = [currentProxy, ...list];
  }

  return list;
};
