import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROXY_GROUP_MODES_LIST, ProxyGroupMode } from './interfaces';

export const visibleProxyGroupModesAtom = atom<ProxyGroupMode[]>(PROXY_GROUP_MODES_LIST);

const getVisibleProxyGroupModes = (): ProxyGroupMode[] => getDefaultStore().get(visibleProxyGroupModesAtom);
const setVisibleProxyGroupModes = (groupModes: ProxyGroupMode[]): void => getDefaultStore().set(visibleProxyGroupModesAtom, groupModes);

export const useVisibleProxyGroupModes = (): ProxyGroupMode[] => useAtomValue(visibleProxyGroupModesAtom);

export const toggleProxyGroupModesVisibility = (groupModesToToggle: ProxyGroupMode[]): void => {
  const currentlyVisibleProxyGroupModes = getVisibleProxyGroupModes();
  const newVisibleGroupsModes = PROXY_GROUP_MODES_LIST.reduce<ProxyGroupMode[]>((acc, groupMode) => {
    if (groupModesToToggle.includes(groupMode)) {
      if (!currentlyVisibleProxyGroupModes.includes(groupMode)) {
        acc.push(groupMode);
      }

      return acc;
    }

    if (currentlyVisibleProxyGroupModes.includes(groupMode)) {
      acc.push(groupMode);
    }

    return acc;
  }, []);

  setVisibleProxyGroupModes(newVisibleGroupsModes);
};
