import React, { FC, memo, useContext } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IUser, userContext, workspaceContext } from '../../../state';
import { updatePricingData, usePricingData } from '../../../state/pricing-data.atom';
import { closeQuickPricing, useIsQuickPricingVisible } from '../../../state/quick-pricing.atom';
import { IconCloseV2 } from '../../../ui/icons/icon-close-v2';
import { postSelectedTrialPlan } from '../../pricing/api';
import PricingPage from '../../pricing/components';
import { PricingModal } from '../components/styles';

const QuickPricing: FC = () => {
  const { firstPlanSelected, plan: userPlan, updateUser } = useContext(userContext);
  const { planId: workspacePlanId } = useContext(workspaceContext);

  const preloadedPricingData = usePricingData();
  const isQuickPricingVisibleAtom = useIsQuickPricingVisible();

  const onQuickPricingCancel = async (): Promise<void> => {
    closeQuickPricing();
    if (firstPlanSelected) {
      return;
    }

    let planId = workspacePlanId;
    if (!NEW_FEATURES.workspaces) {
      planId = userPlan.id;
    }

    const updateObj: Partial<IUser> = {
      hasTrial: true,
      firstPlanSelected: true,
    };

    updateUser(updateObj);
    if (planId) {
      await postSelectedTrialPlan(planId).catch((error: any) => console.log(error.body?.message || error.message));
      preloadedPricingData.userData.firstPlanSelected = true;
      updatePricingData(preloadedPricingData);
    }
  };

  return (
    <PricingModal
      visible={isQuickPricingVisibleAtom}
      onCancel={onQuickPricingCancel}
      footer={null}
      style={{ padding: 0, overflow: 'hidden' }}
      closeIcon={<IconCloseV2 padding={0} iconColor={'var(--696973-new-pricing)'} />}
      bodyStyle={{ padding: 0, overflow: 'hidden' }}
    >
      <PricingPage isPopup={true} />
    </PricingModal>
  );
};

export default memo(QuickPricing);
