import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import useDelayedMount from '../../../hooks/use-should-mount.hook';
import { isArchivedProxy } from '../../proxy/proxy-helpers';
import ProxySelector from '../../proxy/proxy-selector';
import { ITableCell } from '../interfaces';

const ProxyCell: FC<ITableCell> = (props) => {
  const { profileAtom } = props;

  const { id: profileId, proxy, proxyEnabled, role, archivedProxy = null } = useAtomValue(profileAtom);

  const hasProxy = isArchivedProxy(archivedProxy) || (proxyEnabled && proxy.mode !== 'none');
  const { shouldMount } = useDelayedMount(!!hasProxy);
  if (!shouldMount) {
    return null;
  }

  return (
    <ProxySelector
      proxy={isArchivedProxy(archivedProxy) ? archivedProxy : proxy}
      proxyEnabled={!!proxyEnabled || isArchivedProxy}
      profileId={profileId}
      isInDrawer={false}
      isSharedProxy={role !== 'owner'}
      localProxySelectorLocation='profiles-table'
    />
  );
};

export default ProxyCell;
