import React, { FC } from 'react';

import ProxyItem from './proxy-item';
import { GeoProxyType } from '../../../../../common/constants/types';
import { IGeolocationProxyFullData } from '../../../../interfaces';
import PriceList from '../price-list';

interface IProxyList {
  trafficData: IGeolocationProxyFullData;
}

const ProxyList: FC<IProxyList> = (props): JSX.Element => {
  const [anchorElPriceList, setAnchorElPriceList] = React.useState<HTMLElement | null>(null);
  const [selectedType, setSelectedType] = React.useState<GeoProxyType>(GeoProxyType.Mobile);

  const { trafficData } = props;

  const openPriceList = (type: GeoProxyType, currentTarget: HTMLDivElement): void => {
    setSelectedType(type);
    setAnchorElPriceList(currentTarget);
  };

  const onClose = (): void => {
    setAnchorElPriceList(null);
  };

  return (
    <>
      {Object.values(GeoProxyType).map(item => (
        <ProxyItem
          key={item}
          trafficData={trafficData}
          type={item}
          openPriceList={openPriceList}
        />
      ))}
      <PriceList
        anchorEl={anchorElPriceList}
        onClose={onClose}
        price={trafficData.prices}
        geoProxyType={selectedType}
        availableForPurchase={trafficData.availableForPurchase}
      />
    </>
  );
};

export default ProxyList;
