import { Checkbox } from '@material-ui/core';
import React from 'react';

import { CheckboxChecked } from './checkbox-checked';
import { CheckboxIndeterminate } from './checkbox-indeterminate';
import { IGologinCheckbox } from './interfaces';
import { CheckboxContainer, CheckboxWrapper } from './styles';

export const GologinCheckbox = (props: IGologinCheckbox): JSX.Element => {
  const {
    isDisabled,
    hasPadding,
    onChange,
    checked,
    isIndeterminate,
    isShownOnlyOnHover,
    isFilledOnSelection,
    containerStyle,
  } = props;

  return (
    <CheckboxContainer
      style={containerStyle}
      hasPadding={!!hasPadding}
      isShownOnlyOnHover={!!isShownOnlyOnHover}
      checked={checked}
      isFilledOnSelection={!!isFilledOnSelection}
      isDisabled={!!isDisabled}
    >
      <Checkbox
        icon={<CheckboxWrapper />}
        checkedIcon={<CheckboxChecked />}
        indeterminateIcon={<CheckboxIndeterminate />}
        checked={checked}
        indeterminate={!!isIndeterminate}
        onChange={onChange}
      />
    </CheckboxContainer>
  );
};
