import { GeoProxyType } from '../../../../common/constants/types';
import { FreeProxyRegion, IProxy, TorProxyRegion } from '../../../interfaces';
import { getNoIdProxyId } from '../utils/proxy-id';

export const DEFAULT_PROXIES_LIST = [];
export const DEFAULT_HAS_MORE_PROXIES = false;
export const PROXIES_ITERATIONS_LIMIT = 750;
export const DEFAULT_SELECTED_GEOPROXY_TYPE = GeoProxyType.DataCenter;
export const DEFAULT_SELECTED_GEOPROXY_COUNTRY = 'us';
export const DEFAULT_PROXY_SELECTION_COUNT = 0;

export const EMPTY_PROXY: IProxy = {
  id: '',
  mode: 'none',
  username: '',
  password: '',
  host: '',
  port: 80,
  profilesCount: 0,
} as const;

export const DEFAULT_PROXY_FORM_VALUES: IProxy = {
  id: '',
  customName: '',
  mode: 'http',
  host: '',
  port: '',
  username: '',
  password: '',
  changeIpUrl: '',
  profilesCount: 0,
};

type ProxyDefaultCustomField = 'mode'|'autoProxyRegion'|'torProxyRegion'|'country';

interface IDefaultProxy {
  mode: 'gologin' | 'tor';
  customName?: string;
  autoProxyRegion?: FreeProxyRegion;
  torProxyRegion?: TorProxyRegion;
  country: string;
  city?: string;
  status?: boolean;
  host: string;
  port: number;
  username?: string;
  password?: string;
  profilesCount: number;
}

const DEFAULT_PROXY_BASE: Omit<IDefaultProxy, ProxyDefaultCustomField|'id'> = {
  port: 80,
  host: '',
  username: '',
  password: '',
  status: true,
  profilesCount: 0,
};

const DEFAULT_PROXIES_CUSTOM_FIELDS: Pick<IDefaultProxy, ProxyDefaultCustomField>[] = [{
  mode: 'gologin',
  autoProxyRegion: 'us',
  country: 'US',
}, {
  mode: 'gologin',
  autoProxyRegion: 'ca',
  country: 'CA',
}, {
  mode: 'gologin',
  autoProxyRegion: 'uk',
  country: 'UK',
}, {
  mode: 'gologin',
  autoProxyRegion: 'de',
  country: 'DE',
}, {
  mode: 'gologin',
  autoProxyRegion: 'in',
  country: 'IN',
}, {
  mode: 'tor',
  torProxyRegion: 'us',
  country: 'US',
}, {
  mode: 'tor',
  torProxyRegion: 'uk',
  country: 'UK',
}, {
  mode: 'tor',
  torProxyRegion: 'de',
  country: 'DE',
}, {
  mode: 'tor',
  torProxyRegion: 'fr',
  country: 'FR',
}, {
  mode: 'tor',
  torProxyRegion: 'eu',
  country: 'EU',
}];

export const DEFAULT_PROXIES: IDefaultProxy[] = DEFAULT_PROXIES_CUSTOM_FIELDS.map(proxy => ({
  ...DEFAULT_PROXY_BASE,
  ...proxy,
  id: getNoIdProxyId(proxy),
}));

interface IProxyMode {
  value: string;
  label: string;
}

export const USER_PROXY_FORM_MODES: IProxyMode[] = [{
  value: 'http',
  label: 'http',
}, {
  value: 'socks5',
  label: 'socks 5',
}, {
  value: 'socks4',
  label: 'socks 4',
}];
