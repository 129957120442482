import { atom, getDefaultStore } from 'jotai';

import { PROXY_GROUPS_SORTER_FIELD_KEY, PROXY_GROUPS_SORTER_ORDER_KEY } from '../../../../common/constants/local-storage';

type ProxyGroupsSorter = {
  field: 'selectionDate';
  order: 'ascend' | 'descend';
}

export const DEFAULT_PROXY_GROUPS_SORTER: ProxyGroupsSorter = { field: 'selectionDate', order: 'descend' };

const proxyGroupsSorterAtom = atom<ProxyGroupsSorter>(DEFAULT_PROXY_GROUPS_SORTER);

const getProxyGroupsSorterDefault = (): ProxyGroupsSorter => {
  // TODO: make a safer typing
  const field = (localStorage.getItem(PROXY_GROUPS_SORTER_FIELD_KEY) || DEFAULT_PROXY_GROUPS_SORTER.field) as ProxyGroupsSorter['field'];
  const order = (localStorage.getItem(PROXY_GROUPS_SORTER_ORDER_KEY) || DEFAULT_PROXY_GROUPS_SORTER.order) as ProxyGroupsSorter['order'];

  return { field, order };
};

export const proxyGroupsSorterPersistentAtom = atom(
  (get) => {
    const sorterCurrent = get(proxyGroupsSorterAtom);
    if (sorterCurrent) {
      return sorterCurrent;
    }

    return getProxyGroupsSorterDefault();
  },
  (_get, set, sorterUpdated: ProxyGroupsSorter) => {
    if (sorterUpdated.field && sorterUpdated.order) {
      set(proxyGroupsSorterAtom, sorterUpdated);
      localStorage.setItem(PROXY_GROUPS_SORTER_FIELD_KEY, sorterUpdated.field);
      localStorage.setItem(PROXY_GROUPS_SORTER_ORDER_KEY, sorterUpdated.order);

      return;
    }

    set(proxyGroupsSorterAtom, DEFAULT_PROXY_GROUPS_SORTER);
    localStorage.setItem(PROXY_GROUPS_SORTER_FIELD_KEY, DEFAULT_PROXY_GROUPS_SORTER.field);
    localStorage.setItem(PROXY_GROUPS_SORTER_ORDER_KEY, DEFAULT_PROXY_GROUPS_SORTER.order);
  },
);

const getProxyGroupsSorter = (): ProxyGroupsSorter => getDefaultStore().get(proxyGroupsSorterPersistentAtom);
const setProxyGroupsSorter = (sorter: ProxyGroupsSorter): void => getDefaultStore().set(proxyGroupsSorterPersistentAtom, sorter);

export const toggleProxyGroupsOrder = (sorterField: ProxyGroupsSorter['field']): void => {
  const { field: currentSorterField, order: currentSorterOrder } = getProxyGroupsSorter();
  if (currentSorterField !== sorterField) {
    return setProxyGroupsSorter({ field: sorterField, order: DEFAULT_PROXY_GROUPS_SORTER.order });
  }

  setProxyGroupsSorter({ field: sorterField, order: currentSorterOrder === 'ascend' ? 'descend' : 'ascend' });
};
