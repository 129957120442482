import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

const IconContainer = styled('svg')<{ hasSpin: boolean }>`
  ${(props): SerializedStyles|null => props.hasSpin ? css`
    animation: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
      from {
        transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
      }
    }
  ` : null};
`;

interface IIconSpin extends IIconWrapper {
  hasSpin: boolean;
}

export const IconSpin: React.FC<IIconSpin> = (props) => (
  <IconWrapper {...props}>
    <IconContainer hasSpin={props.hasSpin} width={props.size || '16'} height={props.size || '17'} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_9848_75377)'>
        <path d='M1.5 8.86401C1.49974 7.45794 1.95542 6.0897 2.79867 4.96456C3.64193 3.83942 4.82729 3.01806 6.17692 2.62369C7.52655 2.22933 8.96766 2.28323 10.2841 2.77731C11.6005 3.2714 12.7212 4.17902 13.478 5.36401' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5 1.36377V5.36377H9.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.5 8.86377C14.5002 10.2698 14.0446 11.6381 13.2013 12.7632C12.358 13.8884 11.1727 14.7097 9.82305 15.1041C8.47342 15.4984 7.03231 15.4445 5.71591 14.9505C4.39951 14.4564 3.27882 13.5488 2.52197 12.3638' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2.5 16.3638V12.3638H6.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_9848_75377'>
          <rect width='16' height='16' fill='var(--FFFFFF)' transform='translate(0 0.86377)' />
        </clipPath>
      </defs>
    </IconContainer>
  </IconWrapper>
);
