import React from 'react';

import { IconSpinner } from '../../../../ui/gologin-header/icons';

interface ICustomTableLoader {
  areColumnsResizing: boolean;
}

const CustomTableLoader: React.FC<ICustomTableLoader> = (props) => {
  const { areColumnsResizing } = props;

  if (areColumnsResizing) {
    return null;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <IconSpinner size={32} />
    </div>
  );
};

export default CustomTableLoader;
