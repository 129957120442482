import styled from '@emotion/styled';

import { textFont } from '../../../../../ui/style-templates';

export const ProxySectionItemWrapper = styled.div`
  width: 100%;
  padding: 12px 12px 2px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ProxySectionItemTitle = styled.span`
  ${textFont()}

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  color: var(--767676-proxy-manager-list-item);
`;
