import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import ProxyEditFormFooter from './proxy-edit-form-footer';
import { IProxy } from '../../../../../interfaces';
import { mapAndSetProfilesList } from '../../../../../state/profiles-list.atom';
import { updateProxyStatuses } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { handleProxyFormSubmit, setProxyForm, useProxyForm } from '../../../../../state/proxy/proxy-form.atom';
import { useProxyList } from '../../../../../state/proxy/proxy-list.atom';
import { openProxyManagerListView, useProxyManagerState } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxyToForm } from '../../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { updateSelectedProxies } from '../../../../../state/proxy/selected-proxies.atom';
import { switchConfirmBlockVisible } from '../../../../../state/proxy-select-menu.atom';
import { DEFAULT_PROXY_FORM_VALUES } from '../../../constants';
import { copyProxies } from '../../../proxy-helpers';
import ProxyEditFormFields from '../proxy-edit-form-fields';
import { DeleteProxyBtn, FormButtonsContainer, ProxyFormContent, ProxyFormLeft, ProxyFormRight, ProxyFormRowEl } from '../styles';

const ProxyManagerForm: React.FC = () => {
  const formValues = useProxyForm();
  const { currentProfileId, currentProxy, modalEditingProxyId } = useProxyManagerState();
  const proxyList = useProxyList();

  const handlePasteProxy = async (): Promise<void | true> => {
    const clipboardText = await navigator.clipboard.readText();
    await pasteProxyToForm(formValues, clipboardText);
  };

  const deleteProxy = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    const proxyToDelete = proxyList.find(listedProxy => listedProxy.id === modalEditingProxyId);
    updateSelectedProxies({ selectedProxy: proxyToDelete || null });
    openProxyManagerListView();
    switchConfirmBlockVisible(true);
  };

  const clearForm = (): void => {
    setProxyForm(DEFAULT_PROXY_FORM_VALUES);
  };

  const copyProxy = (): void => {
    copyProxies([formValues]);
    message.success(<Trans i18nKey='base.copiedText' />);
  };

  const handleClickSubmit = async (): Promise<void> => {
    const currentProxyId = currentProxy?.id;
    const isProxyCurrent = modalEditingProxyId === currentProxyId;
    const proxyNew = await handleProxyFormSubmit({
      proxyId: modalEditingProxyId,
      profileId: isProxyCurrent ? currentProfileId : null,
      isProxyPage: false,
    });

    if (!proxyNew) {
      return;
    }

    if (isProxyCurrent) {
      mapAndSetProfilesList((prevProfiles) => prevProfiles.map((prevProfile) => {
        if (prevProfile.id !== currentProfileId) {
          return prevProfile;
        }

        const proxyUpdated: IProxy = { ...prevProfile.proxy, ...proxyNew };

        return { ...prevProfile, proxy: proxyUpdated };
      }));
    }

    await updateProxyStatuses([proxyNew]);
  };

  return (
    <>
      <ProxyFormContent>
        <ProxyEditFormFields
          formValues={formValues}
          currentProfileId={currentProfileId}
          currentProxy={currentProxy}
          Row={ProxyFormRowEl}
          RowKey={ProxyFormLeft}
          RowValue={ProxyFormRight}
        />
        {modalEditingProxyId ? (
          <FormButtonsContainer>
            <DeleteProxyBtn onClick={deleteProxy} type='button'>
              <Trans i18nKey='proxies.deleteProxy' />
            </DeleteProxyBtn>
          </FormButtonsContainer>
        ) : null}
      </ProxyFormContent>
      <ProxyEditFormFooter
        clearForm={clearForm}
        copyProxy={copyProxy}
        handlePasteProxy={handlePasteProxy}
        handleClickSubmit={handleClickSubmit}
      />
    </>
  );
};

export default React.memo(ProxyManagerForm);
