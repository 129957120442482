import React from 'react';

import { EmptyProxyFlagWrapper } from './styles';
import IconEmptyProxyFlag from '../../../ui/gologin-header/icons/icon-empty-proxy-flag';

const FLAG_ITEM_CLASS_NAME = 'flag-item';
const DEFAULT_FLAG_WIDTH = 14;
const DEFAULT_FLAG_MARGIN_RIGHT = 8;

type ProxyFlagProps = {
  countryCode?: string;
  width?: number;
}

const ProxyFlag: React.FC<ProxyFlagProps> = (props) => {
  const { countryCode = '', width = DEFAULT_FLAG_WIDTH } = props;

  const finalStyles: React.CSSProperties = {
    marginRight: DEFAULT_FLAG_MARGIN_RIGHT,
    width,
    minWidth: width,
  };

  if (!countryCode) {
    return (
      <EmptyProxyFlagWrapper style={finalStyles} className={FLAG_ITEM_CLASS_NAME}>
        <IconEmptyProxyFlag
          padding={0}
          iconColor='var(--D9D9D9-proxy-manager-empty-flag)'
          iconHoveredColor='var(--D9D9D9-proxy-manager-empty-flag)'
          size={width}
        />
      </EmptyProxyFlagWrapper>
    );
  }

  return (
    <div
      className={`${FLAG_ITEM_CLASS_NAME} fi fi-${countryCode.toLowerCase()}`}
      style={finalStyles}
    />
  );
};

export default ProxyFlag;
