import moment from 'moment';

import { handleMultipleProxiesAddError } from './handle-multiple-proxies-add-error';
import { selectProfileProxy } from './select-proxies.operations';
import { sendActionAnalytics } from '../../../features/common/api';
import { IStatusParams, getProxyStatusParams } from '../../../features/proxy/proxy-helpers';
import { sendPastedProxies } from '../../../features/proxy/send-pasted-proxies';
import { IProxy } from '../../../interfaces';
import { isString } from '../../../utils/is-string';
import { parseMultipleProxies } from '../../../utils/proxy-string';
import { setProxyForm } from '../proxy-form.atom';
import { getProxyList } from '../proxy-list.atom';
import { closeProxyManager, getProxyManagerState } from '../proxy-manager-modal-status.atom';

interface IProxiesPasteParams {
  profileId?: string;
  localProxySelectorLocation: string;
  isInDrawer: boolean;
}

export const pasteProxies = async ({
  profileId = '',
  localProxySelectorLocation = '',
  isInDrawer = false,
}: IProxiesPasteParams): Promise<IProxy[]> => {
  const { currentProfileId } = getProxyManagerState();
  const proxyList = getProxyList();

  const clipboardText = await navigator.clipboard.readText();
  const multipleProxiesAddResult = await sendPastedProxies({
    clipboardText,
    profileInEditProxyMode: profileId || currentProfileId,
    proxyList,
    localProxySelectorLocation,
    isInDrawer,
  });

  if (!multipleProxiesAddResult) {
    return [];
  }

  const { error } = multipleProxiesAddResult;
  if (error) {
    handleMultipleProxiesAddError(error);

    return [];
  }

  const { proxies: addedProxies } = multipleProxiesAddResult;
  if (addedProxies.length === 1 && profileId) {
    const [pastedProxy] = addedProxies;
    await selectProfileProxy(profileId, pastedProxy);
    closeProxyManager();
  }

  return addedProxies;
};

export const pasteProxyToForm = async (formValues: IProxy, clipboardText: string): Promise<IStatusParams | undefined>  => {
  const proxies = await parseMultipleProxies(clipboardText);
  if (!proxies?.length) {
    return;
  }

  const [parsedProxy] = proxies;

  if (!parsedProxy) {
    return;
  }

  const newProxy = {
    id: '',
    profilesCount: 0,
    mode: (isString(parsedProxy.mode)) ? parsedProxy.mode : formValues.mode,
    customName: (isString(parsedProxy.customName)) ? parsedProxy.customName : formValues.customName,
    host: (isString(parsedProxy.host)) ? parsedProxy.host : formValues.host,
    port: parsedProxy.port || formValues.port,
    username: (isString(parsedProxy.username)) ? parsedProxy.username : formValues.username,
    password: (isString(parsedProxy.password)) ? parsedProxy.password : formValues.password,
    changeIpUrl: (isString(parsedProxy.changeIpUrl)) ? parsedProxy.changeIpUrl : formValues.changeIpUrl,
    checkInProgress: true,
    showInfoAfterCheck: true,
  };

  setProxyForm(newProxy);

  const statusParams = await getProxyStatusParams({
    ...newProxy,
    showInfoAfterCheck: true,
  });

  setProxyForm({
    ...newProxy,
    ...statusParams,
    checkDate: moment().toDate(),
    checkInProgress: false,
  });

  sendActionAnalytics('pasted proxy from buffer');

  return statusParams;
};
