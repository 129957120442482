import { Badge, Icon } from 'antd';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StatusMessage from './status-message';
import { getStatusTypeOfProfile, statusColor } from './status-type';
import { ProfileStatusRow, StatusWidthLimitterEl } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { IBasicTableEntity } from '../../../state/profiles-list.atom';
import { IconEllipse } from '../../../ui/gologin-header/icons';
import { IIconWrapper } from '../../../ui/gologin-header/icons/wrapper';
import TooltipCustom from '../../../ui/tooltip-custom';

interface IStatusCell {
  profileAtom: IBasicTableEntity['atom'];
  syncProfile: (profileId: string, onlyUpload?: boolean) => void;
  statusTextWidth: number;
}

const StatusCell: React.FC<IStatusCell> = (props) => {
  const { profileAtom, syncProfile, statusTextWidth } = props;

  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const { id: profileId } = useAtomValue(profileAtom);

  const { status, statusMessage, isWeb } = useProfileRunStatus(profileId);

  const { t: translation } = useTranslation();

  const renderedStatus = getStatusTypeOfProfile(status);
  const text = translation(status || '');
  const iconProps: IIconWrapper = {
    padding: 0,
    margin: '0 12px 0 0',
    iconColor: statusColor[renderedStatus],
  };

  const showTooltip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => setAnchorElTooltip(event.currentTarget);

  const hideTooltip = (): void => setAnchorElTooltip(null);

  const title = statusMessage ? (
    <StatusMessage
      profileId={profileId}
      syncProfile={syncProfile}
      statusMessage={statusMessage}
    />
  ) : null;

  const statusElementForError = (): JSX.Element => {
    if (!NEW_FEATURES.header) {
      return (
        <ProfileStatusRow>
          <Badge dot={true} status={renderedStatus} />
          <StatusWidthLimitterEl newStyle={!!NEW_FEATURES.header} width={statusTextWidth}>
            {text}
            <div
              onMouseEnter={(event): void => showTooltip(event)}
              onMouseLeave={hideTooltip}
            >
              <Icon
                style={{
                  marginLeft: 5,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </div>
          </StatusWidthLimitterEl>
        </ProfileStatusRow>
      );
    }

    return (
      <ProfileStatusRow>
        <IconEllipse {...iconProps} />
        <div
          onMouseEnter={(event): void => showTooltip(event)}
          onMouseLeave={hideTooltip}
        >
          <span>
            {text}
          </span>
          <Icon
            style={{
              marginLeft: 5,
              marginTop: 2,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </div>
      </ProfileStatusRow>
    );
  };

  const processingStatusesElement = (): JSX.Element => (
    <ProfileStatusRow>
      {NEW_FEATURES.header ? (
        <IconEllipse {...iconProps} />
      ) : (
        <Badge dot={true} status={renderedStatus} />
      )}
      <StatusWidthLimitterEl newStyle={!!NEW_FEATURES.header} width={statusTextWidth}>
        {text}
      </StatusWidthLimitterEl>
    </ProfileStatusRow>
  );

  const elementExtendedStatusesForWeb = (): JSX.Element => (
    <div
      onMouseEnter={(event): void => showTooltip(event)}
      onMouseLeave={hideTooltip}
    >
      {processingStatusesElement()}
    </div>
  );

  const baseStatusElementForWeb = (): JSX.Element => (
    <div
      onMouseEnter={(event): void => showTooltip(event)}
      onMouseLeave={hideTooltip}
    >
      <ProfileStatusRow>
        {NEW_FEATURES.header ? (
          <>
            <IconEllipse {...iconProps} />
            <span>
              {text}
            </span>
          </>
        ) : (
          <Badge dot={true} status={renderedStatus} text={text} />
        )}
      </ProfileStatusRow>
    </div>
  );

  const renderContent = (): JSX.Element => {
    if (isWeb) {
      if (status && ['profileStatuses.ready', 'profileStatuses.running', 'profileStatuses.syncing', 'profileStatuses.error'].includes(status)) {
        return baseStatusElementForWeb();
      }

      return elementExtendedStatusesForWeb();
    }

    if (status && ['profileStatuses.error'].includes(status)) {
      return statusElementForError();
    }

    return processingStatusesElement();
  };

  return (
    <>
      {renderContent()}
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={title}
      />
    </>
  );
};

export default React.memo(StatusCell);
