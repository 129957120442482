import { message } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownPopover } from './dropdown-popover';
import { IDropdownMenu, IMenuDivider, IMenuItem } from './interfaces';
import { Divider, DividerContainer, ItemRow, itemRowIconProps } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IProfile } from '../../../interfaces';
import { history } from '../../../services';
import { workspaceContext } from '../../../state';
import { IProfileRunStatus, resetProfilesList } from '../../../state/profiles-list.atom';
import {
  IconBinTrashDelete,
  IconFolder,
  IconPin,
  IconSettings,
  IconShare,
  IconCookies,
  IconUpdate,
  IconClone,
  IconHistory,
  IconCopySmall,
  IconRunProfile,
  IconLink,
  IconTransfer,
} from '../../../ui/gologin-header/icons';
import { DEEP_LINKS_REDIRECT_ORIGIN } from '../../common/deep-links';
import { launchProfileWeb } from '../actions';
import { cloneProfile, cloneWorkspaceProfiles, IPinParams, pin, unpin } from '../api';

const isElectron = !!window.require;

const calculateDeleteAction = (profile: IProfile & IProfileRunStatus): { deleteAction: 'Delete'|'Leave'; isDisabled: boolean } => {
  const { role, canBeRunning, status = '' } = profile;
  const isDisabled = !(canBeRunning && ['profileStatuses.error', 'profileStatuses.ready'].includes(status));
  const deleteAction: 'Delete'|'Leave' = role === 'owner' ? 'Delete' : 'Leave';

  return { deleteAction, isDisabled };
};

const ProfileDropdownMenu: FC<IDropdownMenu> = (props) => {
  const {
    profile,
    selectedFolderName,
    foldersList,
    openShareModal,
    openTransferModal,
    openFoldersModal,
    openCookiesModal,
    openHistoryModal,
    openUpdateProfileModal,
    openDeleteActionModal,
    navigateToUpdatePage,
    isTriggerAlwaysShown,
    modifyProfilePin,
  } = props;

  const { id: profileId, isPinned } = profile;

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const { id: workspaceId } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const { deleteAction, isDisabled: isDeleteDisabled } = calculateDeleteAction(profile);

  const onCloneProfile = async (): Promise<void> => {
    const selectedFolder = (!NEW_FEATURES.header && selectedFolderName === 'all') ? '' : selectedFolderName;
    if (NEW_FEATURES.workspaces) {
      await cloneWorkspaceProfiles(workspaceId, [profileId]);
    } else {
      await cloneProfile(profileId, selectedFolder);
    }

    resetProfilesList();
    history.replace('/');
  };

  const onCopy = (text: string, successMessage: string): void => {
    navigator.clipboard.writeText(text).then(() => message.success(translation(successMessage)));
  };

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const isMenuDivider = (menuItem: IMenuItem | IMenuDivider): menuItem is IMenuDivider => (menuItem as IMenuDivider).divider;

  const renderMenuItem = (menuItem: IMenuItem | IMenuDivider): JSX.Element => {
    if (isMenuDivider(menuItem)) {
      return (
        <DividerContainer>
          <Divider />
        </DividerContainer>
      );
    }

    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    return (
      <ItemRow
        key={name}
        isDisabled={isDisabled}
        onClick={(): void => onActionMenuItem(onAction, isDisabled)}
      >
        {icon}
        <span style={{ marginLeft: 8 }}>
          {translation(translationKey || `profiles.menu.${name}`)}
        </span>
      </ItemRow>
    );
  };

  const actions: (IMenuItem | IMenuDivider)[] = [
    {
      name: 'editProfile',
      icon: <IconSettings {...itemRowIconProps} />,
      onAction: navigateToUpdatePage,
    },
    {
      name: 'folders',
      translationKey: 'folders.title',
      icon: <IconFolder {...itemRowIconProps} />,
      onAction: () => openFoldersModal(),
    },
    {
      name: 'sharing',
      icon: <IconShare {...itemRowIconProps} iconType='stroke' />,
      onAction: () => openShareModal(),
    },
    {
      name: 'clone',
      icon: <IconClone {...itemRowIconProps} />,
      onAction: onCloneProfile,
    },
    {
      name: 'transfer',
      translationKey: 'tableProfiles.menu.transfer',
      icon: <IconTransfer {...itemRowIconProps} />,
      onAction: () => openTransferModal(),
      isDisabled: !profile.permissions?.transferProfile,
      isVisible: NEW_FEATURES.workspaces || false,
    },
    {
      divider: true,
    },
    {
      name: 'cloudRun',
      icon: <IconRunProfile {...itemRowIconProps} />,
      onAction: () => launchProfileWeb(profile),
      isVisible: isElectron,
    },
    {
      name: 'cookies',
      translationKey: 'base.cookies',
      icon: <IconCookies {...itemRowIconProps} />,
      onAction: () => openCookiesModal(),
    },
    {
      name: 'history',
      icon: <IconHistory {...itemRowIconProps} />,
      onAction: () => openHistoryModal(),
    },
    {
      name: 'updateBrowser',
      icon: <IconUpdate {...itemRowIconProps} />,
      onAction: () => openUpdateProfileModal(),
      isVisible: profile.suggestUpdateUA || false,
    },
    {
      divider: true,
    },
    {
      name: 'copyLink',
      translationKey: 'tableProfiles.contextMenu.openLinkCopy',
      icon: <IconLink {...itemRowIconProps} />,
      onAction: () => onCopy(`${DEEP_LINKS_REDIRECT_ORIGIN}/${encodeURIComponent(profile?.name)}`, 'base.copiedText'),
    },
    {
      name: 'copyProfileId',
      icon: <IconCopySmall {...itemRowIconProps} />,
      onAction: () => onCopy(profile?.id, 'base.copiedText'),
    },
    {
      name: 'pin',
      icon: <IconPin {...itemRowIconProps} />,
      onAction: () => modifyProfilePin(isPinned ? 'unpinned' : 'pinned'),
      translationKey: isPinned ? 'profiles.menu.unpin' : 'profiles.menu.pin',
    },
    {
      divider: true,
    },
    {
      name: 'delete',
      translationKey: `base.${deleteAction.toLowerCase()}`,
      icon: <IconBinTrashDelete {...itemRowIconProps} iconType='stroke' />,
      onAction: () => openDeleteActionModal(),
      isDisabled: isDeleteDisabled,
    },
  ];

  const handlePopoverOpen = (): void => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = (): void => {
    setIsPopoverOpen(false);
  };

  return (
    <DropdownPopover
      isPopoverOpen={isPopoverOpen}
      onOpen={handlePopoverOpen}
      onClose={handlePopoverClose}
      isTriggerAlwaysShown={isTriggerAlwaysShown}
    >
      {actions.filter(action => action.isVisible ?? true).map(renderMenuItem)}
    </DropdownPopover>
  );
};

export default ProfileDropdownMenu;
