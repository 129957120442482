import React from 'react';
import { Trans } from 'react-i18next';

import { PROXY_GOLOGIN_GROUP_MODES_LIST, PROXY_GROUP_MODES, ProxyGroupMode } from '../../../../../../state/proxy/proxy-groups/interfaces';
import { DEFAULT_PROXY_GROUPS_SORTER, toggleProxyGroupsOrder } from '../../../../../../state/proxy/proxy-groups/proxy-groups-sorting.atom';
import {
  toggleProxyGroupModesVisibility,
  useVisibleProxyGroupModes,
} from '../../../../../../state/proxy/proxy-groups/proxy-visible-group-modes.atom';
import { openProxyManager, useProxyManagerState } from '../../../../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxies } from '../../../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { IconEyeOpen, IconPaste } from '../../../../../../ui/gologin-header/icons';
import { IconEyeCrossed } from '../../../../../../ui/gologin-header/icons/icon-eye-crossed';
import IconProxyPlus from '../../../../../../ui/gologin-header/icons/icon-proxy-plus';
import { IconSortSmall } from '../../../../../../ui/gologin-header/icons/icon-sort-small';
import GologinPopover from '../../../../../../ui/gologin-popover';
import { GologinPopoverItemRow } from '../../../../../../ui/gologin-popover/gologin-popover-item-row';
import { IMenuDivider, IMenuItem } from '../../../../../quickProfiles/profile-dropdown-menu/interfaces';
import { Divider, DividerContainer, itemRowIconProps } from '../../../../../quickProfiles/profile-dropdown-menu/styles';

type ProxySectionContextMenuPopoverProps = {
  anchorElement: HTMLDivElement | null;
  handleClose: () => void;
}

const ProxySectionContextMenuPopover: React.FC<ProxySectionContextMenuPopoverProps> = (props) => {
  const { anchorElement, handleClose } = props;

  const { containerElement, currentProfileId, proxySelectorLocation: proxySelectorLocationInState } = useProxyManagerState();
  const visibleProxyGroupModes = useVisibleProxyGroupModes();

  let proxySelectorLocation = proxySelectorLocationInState;
  if (!proxySelectorLocation) {
    proxySelectorLocation = 'no-location';
  }

  const profileId = currentProfileId || '';

  const handleAddProxy = (): void => {
    openProxyManager({
      containerElement,
      modalView: 'proxy-add',
      currentProxy: null,
      modalEditingProxyId: null,
      currentProfileId: profileId,
      proxySelectorLocation,
    });
  };

  const handlePasteProxy = async (): Promise<void> => {
    const pastedProxies = await pasteProxies({
      localProxySelectorLocation: proxySelectorLocation,
      isInDrawer: false, // TODO: fix for drawer
    });

    if (pastedProxies.length > 1) {
      openProxyManager({
        containerElement,
        modalView: 'proxy-list',
        currentProxy: null,
        modalEditingProxyId: null,
        currentProfileId: profileId,
        proxySelectorLocation,
      });
    }
  };

  const getAreProxyGroupModesVisible = (proxyGroupModes: ProxyGroupMode[]): boolean =>
    proxyGroupModes.every(proxyGroupMode => visibleProxyGroupModes.includes(proxyGroupMode));

  const getGologinProxiesVisibilityKey = (secondKey: 'GologinProxies' | 'UserProxies', toggledProxyGroupModes: ProxyGroupMode[]): string => {
    const keyWord = getAreProxyGroupModesVisible(toggledProxyGroupModes) ? 'hide' : 'show';

    return `proxies.groupsContextMenu.${keyWord}${secondKey}`;
  };

  const actions: (IMenuItem | IMenuDivider)[] = [
    {
      name: 'addProxy',
      translationKey: 'proxies.addNewProxy',
      icon: <IconProxyPlus {...itemRowIconProps} />,
      onAction: handleAddProxy,
      isVisible: true,
    },
    {
      name: 'pasteProxy',
      translationKey: 'proxies.pasteProxy',
      icon: <IconPaste {...itemRowIconProps} />,
      onAction: () => handlePasteProxy(),
      isVisible: true,
    },
    {
      divider: true,
    },
    {
      name: 'gologinProxiesVisibility',
      translationKey: getGologinProxiesVisibilityKey('GologinProxies', PROXY_GOLOGIN_GROUP_MODES_LIST),
      icon: getAreProxyGroupModesVisible(PROXY_GOLOGIN_GROUP_MODES_LIST) ?
        <IconEyeCrossed {...itemRowIconProps} /> : <IconEyeOpen {...itemRowIconProps} />,
      onAction: () => toggleProxyGroupModesVisibility(PROXY_GOLOGIN_GROUP_MODES_LIST),
      isVisible: true,
    },
    {
      name: 'userProxiesVisibility',
      translationKey: getGologinProxiesVisibilityKey('UserProxies', [PROXY_GROUP_MODES.userProxies]),
      icon: getAreProxyGroupModesVisible([PROXY_GROUP_MODES.userProxies]) ?
        <IconEyeCrossed {...itemRowIconProps} /> : <IconEyeOpen {...itemRowIconProps} />,
      onAction: () => toggleProxyGroupModesVisibility([PROXY_GROUP_MODES.userProxies]),
      isVisible: true,
    },
    {
      divider: true,
    },
    {
      name: 'sortByRecentlyAdded',
      translationKey: 'proxies.groupsContextMenu.sortByRecentlyAdded',
      icon: <IconSortSmall {...itemRowIconProps} />,
      onAction: () => null,
      isVisible: true,
      isDisabled: true,
    },
    {
      name: 'sortByRecentlyUsed',
      translationKey: 'proxies.groupsContextMenu.sortByRecentlySelected',
      icon: <IconSortSmall {...itemRowIconProps} />,
      onAction: () => toggleProxyGroupsOrder(DEFAULT_PROXY_GROUPS_SORTER.field),
      isVisible: true,
    },
  ];

  // TODO: separate commons parts with profile-dropdown-menu
  const isMenuDivider = (menuItem: IMenuItem | IMenuDivider): menuItem is IMenuDivider => (menuItem as IMenuDivider).divider;

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const renderMenuItem = (menuItem: IMenuItem | IMenuDivider): JSX.Element => {
    if (isMenuDivider(menuItem)) {
      return (
        <DividerContainer>
          <Divider />
        </DividerContainer>
      );
    }

    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    return (
      <GologinPopoverItemRow
        key={name}
        style={{ padding: '6px 12px' }}
        onClick={(): void => onActionMenuItem(onAction, isDisabled)}
      >
        {icon}
        <span style={{ marginLeft: 8 }}>
          <Trans i18nKey={translationKey} />
        </span>
      </GologinPopoverItemRow>
    );
  };

  return (
    <GologinPopover
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      extraTranslate={{ left: 2, bottom: 6 }}
      zIndex={1060}
    >
      {actions.filter(action => action.isVisible ?? true).map(renderMenuItem)}
    </GologinPopover>
  );
};

export default ProxySectionContextMenuPopover;
