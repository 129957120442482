import React from 'react';

import ProxyItem from './proxy-item';
import {
  ProxyButtonsContainerEl,
  ProxyListItemEl,
  ProxyListItemInnerEl,
} from './styles/proxy-list-item';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IProxy, IArchivedProxy } from '../../../../interfaces';
import { useIsProxyRestoring } from '../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { updateSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { openProxyContextMenu, updateProxySelectMenuPosition } from '../../../../state/proxy-select-menu.atom';
import ProxyEditButton from '../../check-proxy-tooltip/proxy-info/proxy-edit-button';
import ProxyRemoveButton from '../../check-proxy-tooltip/proxy-info/proxy-remove-button';
import ProxyProfilesCountBlock from '../../components/proxy-profiles-count-block';
import { getIsProxyEditable, isArchivedProxy, restoreProxy } from '../../proxy-helpers';

interface IProxyListItem {
  proxy: IProxy|IArchivedProxy;
  isCurrent: boolean;
  currentProfileId: string | null;
  style: any;
  localProxyElementLocation: string | null;
  isSelectProxyModeOpened: boolean;
  isHoverable: boolean;
  maxWidth: number;
  shouldShowEdit: boolean;
  handleRowClick: React.MouseEventHandler<HTMLAnchorElement>;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
}

const ProxyListItem: React.FC<IProxyListItem> = (props) => {
  const {
    proxy,
    isCurrent,
    currentProfileId,
    style,
    localProxyElementLocation,
    isSelectProxyModeOpened,
    isHoverable,
    maxWidth,
    shouldShowEdit,
    handleRowClick,
    selectorContainerElement,
  } = props;

  const isProxyRestoring = useIsProxyRestoring(proxy.id);

  const handleProxyInfoEditClick = async (): Promise<void> => {
    if (isArchivedProxy(proxy)) {
      await restoreProxy(proxy);
    }

    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: proxy,
      currentProfileId,
      modalEditingProxyId: proxy.id,
    });
  };

  const handleContextMenu: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    updateSelectedProxies({ selectedProxy: proxy });
    updateProxySelectMenuPosition({ x: event.pageX, y: event.pageY });
    openProxyContextMenu();
  };

  const isProxyEditable = shouldShowEdit && getIsProxyEditable(proxy);
  let currentProxy: IProxy | undefined;
  if (isCurrent) {
    currentProxy = proxy;
  }

  const renderProxyListItemButtons = (): JSX.Element|null => {
    if (!(isProxyEditable || isCurrent)) {
      return null;
    }

    return (
      <ProxyButtonsContainerEl onClick={(event): void => {
        event.preventDefault();
        event.stopPropagation();
      }}>
        {isProxyEditable ? <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} isProxyRestoring={isProxyRestoring} /> : null}
        {isCurrent ? <ProxyRemoveButton profileId={currentProfileId || ''} isVisible={isCurrent} /> : null}
      </ProxyButtonsContainerEl>
    );
  };

  return (
    <ProxyListItemEl
      onClick={handleRowClick}
      onContextMenu={handleContextMenu}
      style={style}
      isHoverable={isHoverable}
      maxWidth={maxWidth}
    >
      <ProxyListItemInnerEl newStyle={!!NEW_FEATURES.header} isHoverable={isHoverable}>
        <ProxyItem
          proxy={proxy}
          currentProxy={currentProxy}
          profileId={currentProfileId}
          showCheckbox={!!(isProxyEditable && isSelectProxyModeOpened)}
          localProxyElementLocation={localProxyElementLocation}
          selectorContainerElement={selectorContainerElement}
        />
        {proxy?.profilesCount ? <ProxyProfilesCountBlock profilesCount={proxy.profilesCount} /> : null}
      </ProxyListItemInnerEl>
      {renderProxyListItemButtons()}
    </ProxyListItemEl>
  );
};

export default ProxyListItem;
