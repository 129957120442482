import { atom, getDefaultStore } from 'jotai';

import { getAllProfilesFolderId } from './folders/all-profiles-folder-id.atom';

// TODO: not yet ready for use in places other than profiles list DnD
export const selectedFolderIdAtom = atom<string|null>(null);

export const getSelectedFolderId = (): string|null => getDefaultStore().get(selectedFolderIdAtom);
export const setSelectedFolderId = (newSelectedFolderId: string|null): void => getDefaultStore().set(selectedFolderIdAtom, newSelectedFolderId);

export const getHasSelectedFolder = (): boolean => {
  const selectedFolderId = getSelectedFolderId();
  const allProfilesFolderId = getAllProfilesFolderId();
  let hasSelectedFolder = !!selectedFolderId;
  if (hasSelectedFolder && allProfilesFolderId) {
    hasSelectedFolder = selectedFolderId !== allProfilesFolderId;
  }

  return hasSelectedFolder;
};
