import { Table } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFullProfile, IWebGLMetadata, IWebGLParams } from '../../../../interfaces';
import { InputTitle } from '../../../../ui/closeable-input/styles';
import { IconSettings2 } from '../../../../ui/gologin-header/icons';
import GologinModal from '../../../../ui/gologin-modal';
import { ModernSelect } from '../../../../ui/modern-select';
import TooltipCustom from '../../../../ui/tooltip-custom';
import { getVendorsList, getRenderersList } from '../../../common/api';
import {
  ContainerParams,
  ContainerSubRow,
  ContainerIcon,
  ContainerSpaceBetween,
  ContainerWebGLData, SubContainerWebGL,
} from '../../styles';

interface IWebglMetadataComponent {
  webglParams: IWebGLParams;
  webGLMetadata: IWebGLMetadata;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
}

const WebglMetadata: FC<IWebglMetadataComponent> = ({ webglParams, webGLMetadata, changeSetting }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [webGlModalVisible, setWebGlModalVisible] = useState<boolean>(false);
  const [vendor, setVendor] = useState<string>('');
  const [renderer, setRenderer] = useState<string>('');
  const [maskActive, setMaskActive] = useState<boolean>(false);
  const [vendors, setVendors] = useState<string[]>([]);
  const [renderers, setRenderers] = useState<string[]>([]);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);
  const [tooltipValue, setTooltipValue] = useState<string>('');

  const { t: translation } = useTranslation();

  const canvas = document.createElement('canvas').getContext('webgl');
  const rendererWebGL = canvas?.getExtension('WEBGL_debug_renderer_info');

  useEffect(() => {
    getVendorsList().then(resultVendors => {
      setVendors(resultVendors.vendorsList);
    });
  }, []);

  useEffect(() => {
    if (!(renderer && isEdit)) {
      return;
    }

    updateRenderersList(webGLMetadata.vendor || '');
  }, [webGLMetadata.vendor, isEdit]);

  useEffect(() => {
    const isRealWebGl = webGLMetadata.mode === 'off';
    setVendor(isRealWebGl ? canvas?.getParameter(rendererWebGL?.UNMASKED_VENDOR_WEBGL || 0) : webGLMetadata.vendor);
    setRenderer(isRealWebGl ? canvas?.getParameter(rendererWebGL?.UNMASKED_RENDERER_WEBGL || 1) : webGLMetadata.renderer);
  }, [webGLMetadata.mode, webGLMetadata.vendor, webGLMetadata.renderer]);

  useEffect(() => {
    if (webGLMetadata.mode === 'off') {
      setMaskActive(false);
    }

    if (webGLMetadata.mode === 'mask') {
      setMaskActive(true);
    }
  }, [webGLMetadata.mode]);

  const updateRenderersList = async (vendorName: string): Promise<void> => {
    const resultRenderers = await getRenderersList(vendorName);

    setRenderers(resultRenderers.renderersList);
    onChangeWebGL(resultRenderers.renderersList[0], 'renderer');
  };

  const showTooltip = (event: React.MouseEvent<HTMLSpanElement>, text: string): void => {
    setAnchorElTooltip(event.currentTarget);
    setTooltipValue(text);
  };

  const closeTooltip = (): void => {
    setAnchorElTooltip(null);
    setTooltipValue('');
  };

  const saveWebglMetadata = (opts: { vendor?: string; renderer?: string }): void => changeSetting({ webGLMetadata: opts });

  const getGPUInfo = (): string => {
    const isModeOff = webGLMetadata.mode === 'off';
    const isEmptyDeviceMetadata = !rendererWebGL && isModeOff;
    const isEmptyProfileMetadata = !(isModeOff || (webGLMetadata.vendor || webGLMetadata.renderer));
    if (isEmptyDeviceMetadata || isEmptyProfileMetadata) {
      return '';
    }

    if (isModeOff && rendererWebGL) {
      return canvas?.getParameter(rendererWebGL.UNMASKED_RENDERER_WEBGL);
    }

    return webGLMetadata.vendor + ' / ' + webGLMetadata.renderer;
  };

  const webGParamsTable = (): JSX.Element => {
    const webGLParams = webglParams || {};
    const glParamValues = webGLParams.glParamValues || [];
    const filteredParams = glParamValues.filter(obj => obj.value !== 'n/a');

    const dataSource = filteredParams.map(obj => {
      const { name, value } = obj;
      let resultValue = [value];
      if (value === Object(value)) {
        resultValue = [];
        for (const index of Object.keys(value)) {
          resultValue.push(value[index]);
        }
      }

      return {
        key: name,
        extension: 'webgl',
        name,
        value: resultValue,
      };
    });

    return (
      <Table dataSource={dataSource} pagination={false} size='small'>
        <Table.Column title='Extension' key='extension' dataIndex='extension' />
        <Table.Column
          title='Name'
          key='name'
          dataIndex='name'
          ellipsis={true}
          render={(name): JSX.Element => (
            <div
              onMouseEnter={(event): void => showTooltip(event, name)}
              onMouseLeave={closeTooltip}
            >
              {name}
            </div>
          )}
        />
        <Table.Column
          title='Value'
          key='value'
          dataIndex='value'
          render={(arr): JSX.Element => (
            <span>
              {arr.join(', ')}
            </span>
          )}
        />
      </Table>
    );
  };

  const onChangeWebGL = (value: string, type: 'vendor'|'renderer'): void => {
    if (!value) {
      return;
    }

    const webGLType = {
      vendor: setVendor,
      renderer: setRenderer,
    };

    saveWebglMetadata({ [type]: value });
    webGLType[type](value);
  };

  const renderInputs = (): JSX.Element|null => {
    if (!isEdit) {
      return null;
    }

    const inputsData: { title: string; state: string; type: 'vendor'|'renderer' }[] = [
      { title: translation('quickSettings.hardware.webglVendor'), state: vendor, type: 'vendor' },
      { title: translation('quickSettings.hardware.webglRender'), state: renderer, type: 'renderer' },
    ];

    return (
      <div onClick={(event): void => event.stopPropagation()}>
        <ContainerSubRow
          hasMarginTop={true}
          hasGap={true}
          onClick={(event): void => event.stopPropagation()}
          style={{ marginBottom: 16 }}
        >
          {inputsData.map(({ title, state, type }) => (
            <>
              <InputTitle hasMarginTop={true}>
                {title}
              </InputTitle>
              <ModernSelect
                currentValue={state}
                itemList={(type === 'vendor' ? vendors : renderers).map(name => ({ title: name, value: name }))}
                onSelected={(value): void => onChangeWebGL(value, type)}
                maxWidth='160px'
                popoverWidth='300px'
                margin='8px 0 0 0'
                disabled={!maskActive}
              />
            </>
          ))}
        </ContainerSubRow>
        <ContainerIcon
          onClick={(event): void => {
            event.stopPropagation();
            setWebGlModalVisible(true);
          }}
          iconColor='var(--81818A-profile-new-settings)'
          iconHoveredColor='var(--2B2B31-profile-new-settings)'
          textColor='var(--81818A-profile-new-settings)'
          textHoveredColor='var(--2B2B31-profile-new-settings)'
          iconType='stroke'
        >
          <IconSettings2 padding={0} margin='0 8px 0 0' />
          {translation('quickSettings.hardware.openWebgl')}
        </ContainerIcon>
      </div>
    );
  };

  const selectItems = [{
    title: translation('quickSettings.parameters.real'),
    value: 'off',
  }, {
    title: translation('quickSettings.parameters.masked'),
    value: 'mask',
  }];

  const toggleEditMode = (): void => {
    setIsEdit(!isEdit);
  };

  return (
    <ContainerParams
      onClick={toggleEditMode}
      style={{ flexWrap: 'wrap' }}
      hasCursorPointer={true}
    >
      <ContainerSpaceBetween>
        <div onClick={(event): void => event.stopPropagation()}>
          <ModernSelect
            currentValue={webGLMetadata.mode ||'off'}
            itemList={selectItems}
            onSelected={(value): void => changeSetting({ webGLMetadata: { mode: value as 'mask'|'off' } })}
            maxWidth='200px'
          />
        </div>
        <ContainerWebGLData hasCursorPointer={true}>
          <SubContainerWebGL>
            {getGPUInfo()}
          </SubContainerWebGL>
        </ContainerWebGLData>
      </ContainerSpaceBetween>
      {renderInputs()}
      <div onClick={(event): void => event.stopPropagation()}>
        <GologinModal
          isVisible={webGlModalVisible}
          onClose={(): void => setWebGlModalVisible(false)}
          title='WebGL parameters'
          width='520px'
        >
          {webGParamsTable()}
        </GologinModal>
      </div>
      <TooltipCustom
        value={tooltipValue}
        anchorEl={anchorElTooltip}
      />
    </ContainerParams>
  );
};

export default React.memo(WebglMetadata);
