import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { ProxyItemBase, proxyListItemActiveStyles } from '../styles';

export const ProxyGroupHeaderWrapper = styled(ProxyItemBase)`
  height: 36px;
  padding: 0 0 0 4px;
  border-radius: 4px;
  margin-bottom: 4px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    ${proxyListItemActiveStyles}
  }
`;

export const ProxyGroupHint = styled.span`
  margin: 0 8px;

  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  white-space: nowrap;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--767676-proxy-group-header-arrow);
`;

export const ProxyGroupExpandButtonWrapper = styled.div`
  width: 38px;
  height: 36px;
  min-width: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const expandButtonArrowActiveStyles = css`
  svg path {
    stroke: var(--36363D-proxy-group-header-arrow-active);
  }
`;

export const ProxyGroupExpandButton = styled.div<{ isRotated: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props): SerializedStyles => props.isRotated ? css`
    ${expandButtonArrowActiveStyles}

    svg {
      transform: rotate(0);
    }
  ` : css`
    transform: rotate(-90deg);
  `}

  :hover {
    background-color: var(--E9E9E7-proxy-page-chip-check-hovered); // TODO: create specific var() before the proxyGroups release

    ${expandButtonArrowActiveStyles}
  }
`;

