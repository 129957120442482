import styled from '@emotion/styled/macro';

export const ProxyEditButtonWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  width: 18px;
  height: 18px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: ${(props): React.CSSProperties['color'] => props.isProxyManagerWithGroups ?
    'var(--B5B5BA-proxy-profiles-counter)' :
    'var(--767676-proxy-manager-list-item)'};

  &:hover {
    color: ${(props): React.CSSProperties['color'] => props.isProxyManagerWithGroups ?
    'var(--36363D-proxy-group-header-arrow-active)' :
    'var(--00A987-proxy-manager)'}
  }
`;
