import { STORAGE_KEY_NAME_PREFIX } from './constants';

// legacy
export const LOCAL_STORAGE_SELECTED_FOLDER = 'SelectedFolder';

export const LOCAL_STORAGE_GROUPS_OLD = `${STORAGE_KEY_NAME_PREFIX}groups`;
export const LOCAL_STORAGE_GROUPS_STATUS = `${STORAGE_KEY_NAME_PREFIX}groups-status`;

export const PROXY_SELECTION_DATES_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}proxy-selection-dates`;
export const GEOPROXY_COUNT_KEY_PREFIX = `${STORAGE_KEY_NAME_PREFIX}geoproxy-count:`;
export const GEOPROXY_TYPE_ADDED_LAST_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}geoproxy-type`;
export const GEOPROXY_COUNTRY_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}geoproxy-country`;
export const PROXY_FOLDER_SELECTED = `${STORAGE_KEY_NAME_PREFIX}proxy-folder-selected`;

const PROXY_TABLE_KEY_PREFIX = `${STORAGE_KEY_NAME_PREFIX}proxy-table:`;
export const PROXY_TABLE_COLUMNS_SETTINGS_KEY = `${PROXY_TABLE_KEY_PREFIX}columns-settings`;
export const PROXY_TABLE_SORTER_FIELD_KEY = `${PROXY_TABLE_KEY_PREFIX}sorter-field`;
export const PROXY_TABLE_SORTER_ORDER_KEY = `${PROXY_TABLE_KEY_PREFIX}sorter-order`;
