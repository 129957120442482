import { Button, Icon } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { luminatiCountries } from '../../../../utils/luminati-countries';
import {
  CountryFlagContainer,
  IconSync,
  LoaderDiv,
  ProxyCheckDescription,
  ProxyFirstLineContainer,
  SuccessProxyCheckContainer,
} from '../domElements';
import {
  ICountryListElemInfo,
  IProxyChecked,
} from '../interfaces';
import { checkProxy } from '../utils/check-proxy';

const BYTES_IN_GB = 1024 * 1024 * 1024;
const BYTES_IN_MB = 1024 * 1024;

const isElectron = !!window.require;

declare interface IProxyCheckerForm {
  proxyChecked: IProxyChecked;
  currentDisplay?: 'form'|'result';
  setProxyChecked: (params: any) => void;
  setCurrentDisplay: (params: 'form'|'result') => void;
  isLoading: boolean;
  profileProxy?: any;
  style?: any;
  geolocationShowOption: boolean;
  initialCheck: boolean;
}

const ProxyChecker: FC<IProxyCheckerForm> = (props: IProxyCheckerForm) => {
  const [isCheckLoading, setCheckLoading] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  const { proxyChecked, profileProxy } = props;

  useEffect(() => {
    if (!profileProxy || proxyChecked?.status === 'success') {
      return;
    }

    checkCurrentProxy();
  }, []);

  const getProxyObjToUpdate = (res: any) => {
    let proxyData = {
      ...res,
    };

    if (res.status === 'fail') {
      proxyData = {
        ...proxyChecked,
        status: 'fail',
        error: res.error,
      };
    }

    const baseObj: any = {
      ...proxyData,
      connectionType: proxyChecked.connectionType,
      trafficUsed: proxyChecked.trafficUsed || '',
      trafficLimit: proxyChecked.trafficLimit || 0,
    };

    if (proxyChecked.city) {
      baseObj.city = proxyChecked.city;
      baseObj.country = proxyChecked.country;
    }

    return baseObj;
  };

  const checkCurrentProxy = () => {
    const optionsToCheckProxy: any = {
      proxy: {
        type: props.profileProxy.type,
        host: props.profileProxy.host,
        port: props.profileProxy.port,
        username: props.profileProxy.username,
        password: props.profileProxy.password,
      },
      setProxyChecked: (res: any) => props.setProxyChecked(getProxyObjToUpdate(res)),
    };

    const processCbs = () => {
      setCheckLoading(true);
    };

    const resCbs = () => {
      setCheckLoading(false);
    };

    checkProxy(optionsToCheckProxy, processCbs, resCbs, translation);
  };

  const getSyncLink = () => {
    if (isCheckLoading) {
      return (
        <IconSync
          type='sync'
          spin={true}
        />
      );
    }

    return (
      <IconSync
        type='sync'
        onClick={checkCurrentProxy}
      />
    );
  };

  const getChangeProxyButton = () => {
    if (!(props.geolocationShowOption && props.currentDisplay === 'result')) {
      return null;
    }

    return (
      <Button
        type={'primary'}
        onClick={() => props.setCurrentDisplay('form')}
        style={{ marginTop: '10px' }}
      >
        {translation('proxy.btnChangeProxy')}
      </Button>
    );
  };

  const getProxyPingText = () => {
    if (proxyChecked.status === 'success' && proxyChecked.ping && isElectron) {
      return (
        <div>
          &nbsp;
          {proxyChecked.ping}
          {' '}
          ms
        </div>
      );
    }

    if (proxyChecked.status === 'fail') {
      return (
        <div style={{ color: 'red' }}>
          &nbsp;&nbsp;
          {proxyChecked.error}
&nbsp;
        </div>
      );
    }

    return null;
  };

  const getTrafficWithMeasurement = (trafficLimit: number, trafficUsed: number) => {
    let measurement = 'MB';
    let divider = BYTES_IN_MB;
    let digitsAfterDot = 0;
    if (trafficLimit > BYTES_IN_GB) {
      measurement = 'GB';
      divider = BYTES_IN_GB;
      digitsAfterDot = 2;
    }

    const formattedTrafficLimit = +(trafficLimit / divider).toFixed(digitsAfterDot);
    const formattedTrafficUsed = +(trafficUsed / divider).toFixed(digitsAfterDot);

    return {
      trafficLimit: formattedTrafficLimit,
      trafficUsed: formattedTrafficUsed,
      measurement,
    };
  };

  const getLimitText = () => {
    if (!props.geolocationShowOption) {
      return null;
    }

    if (proxyChecked.trafficLimitBytes) {
      const { trafficLimit, trafficUsed, measurement } = getTrafficWithMeasurement(proxyChecked.trafficLimitBytes, proxyChecked.trafficUsedBytes);

      return `${trafficUsed} ${measurement} ${translation('base.used')} (${trafficLimit} ${measurement} ${translation('base.free')})`;
    }

    let value = 'GB';
    if (proxyChecked.connectionType === GeoProxyType.Mobile) {
      value = 'MB';
    }

    return `${proxyChecked.trafficUsed || '0.00'} ${value} ${translation('base.used')} (${proxyChecked.trafficLimit} ${value} ${translation('base.free')})`;
  };

  const getCheckProxyDescription = () => {
    if (!props.geolocationShowOption) {
      window.scrollTo({ behavior: 'smooth', top: 1000 });

      let mode = 'HTTP';

      if (profileProxy.mode === 'socks4') {
        mode = 'SOCKS4';
      }

      if (profileProxy.mode === 'socks5') {
        mode = 'SOCKS5';
      }

      return `${mode} • ${proxyChecked.origin}`;
    }

    let connectionType = 'Residential';
    if (proxyChecked.connectionType === GeoProxyType.Mobile) {
      connectionType = 'Mobile';
    }

    if (proxyChecked.connectionType === GeoProxyType.DataCenter) {
      connectionType = 'Data Center';
    }

    return `HTTP • ${connectionType} • ${proxyChecked.origin?.includes(':') ? 'IPv6' : 'IPv4'}`;
  };

  const getContent = () => {
    if (props.isLoading) {
      return (
        <LoaderDiv>
          <Icon type='loading' />
        </LoaderDiv>
      );
    }

    if (proxyChecked.status) {
      const countryInfo: ICountryListElemInfo|undefined =
        luminatiCountries.find((el: ICountryListElemInfo) => el.code === proxyChecked?.country?.toLowerCase());

      const countryName = countryInfo?.country || proxyChecked?.country?.toUpperCase();

      return (
        <>
          <SuccessProxyCheckContainer>
            <div className='check-params'>
              <CountryFlagContainer>
                {getUnicodeFlagIcon(proxyChecked.country)}
              </CountryFlagContainer>
              <ProxyFirstLineContainer>
                {translation('proxy.proxyIn')}
                {' '}
                {countryName}
                {' '}
                •
                {' '}
                {proxyChecked.country}
                {' '}
                •
                {' '}
                {proxyChecked.city}
                {' '}
                •
                {getProxyPingText()}
                {getSyncLink()}
              </ProxyFirstLineContainer>
            </div>
            <ProxyCheckDescription>
              {getCheckProxyDescription()}
            </ProxyCheckDescription>
            <ProxyCheckDescription>
              {getLimitText()}
            </ProxyCheckDescription>
            {getChangeProxyButton()}
          </SuccessProxyCheckContainer>
        </>
      );
    }

    return null;
  };

  return getContent();
};

export default ProxyChecker;
