import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';

interface IProxyLimitError {
  body: {
    message?: string;
    currentCount?: number;
    maxCount?: number;
  };
}

export const handleProxiesLimitError = (error: any): void => {
  if (!error?.body) {
    sendReactErrorToSentry({
      message: JSON.stringify(error), transactionName: 'handle-proxies-limit-no-error-body',
      extra: { error },
    });
  } else if (!error.body.message) {
    sendReactErrorToSentry({
      message: JSON.stringify(error), transactionName: 'handle-proxies-limit-no-error-message',
      extra: { error },
    });
  }

  const defaultErrorMessage = 'notifications.error.somethingWentWrongAgainLater';
  const { body } : IProxyLimitError = error || {};
  const { message: errMessage = defaultErrorMessage } = body || {};
  if (!Object.hasOwn(body, 'maxCount')) {
    const errorMessageComponent = <Trans i18nKey={errMessage} />;
    message.error(errorMessageComponent);

    return;
  }

  const { message: errorMessage = defaultErrorMessage, currentCount = 0, maxCount = 0 } = body || {};
  const errorMessageComponent = <Trans i18nKey={errorMessage} values={{ currentCount, maxCount }} />;
  message.error(errorMessageComponent);
};
