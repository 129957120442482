import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProxyPlaceholderText } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../../ui/gologin-header/icons/wrapper';

interface IProps {
  isInDrawer: boolean;
}

const ProxyPlaceholder: FC<IProps> = (props) => {
  const { isInDrawer } = props;
  const { t: translation } = useTranslation();

  const renderPlaceholder = (): JSX.Element => {
    if (!isInDrawer || !NEW_FEATURES.drawer) {
      return (
        <div className='nowrap'>
          {translation('proxies.addOrPasteProxy')}
        </div>
      );
    }

    return (
      <IconWrapperWithDescription
        iconColor='var(--B5B5BA-proxy-manager-placeholder)'
        iconHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        textColor='var(--81818A-proxy-manager-placeholder)'
        textHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        iconType='stroke'
        style={{ lineHeight: 'inherit' }}
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        {translation('proxies.addOrPasteProxy')}
      </IconWrapperWithDescription>
    );
  };

  return (
    <div className='nowrap'>
      <ProxyPlaceholderText isInDrawer={isInDrawer} newDrawer={!!NEW_FEATURES.drawer} newStyle={!!NEW_FEATURES.header}>
        {renderPlaceholder()}
      </ProxyPlaceholderText>
    </div>
  );
};

export default ProxyPlaceholder;
