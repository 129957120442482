import styled from '@emotion/styled/macro';

import { ProxyItemBase, proxyListItemActiveStyles } from '../styles';

export const GroupedProxyItemWrapper = styled(ProxyItemBase)`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 0 0 36px;
  border-radius: 4px;
  margin-bottom: 4px;

  :hover {
    ${proxyListItemActiveStyles}  
  }

  /* gap */
  & > * + * {
    margin-left: 8px;
  }
`;

export const GroupedProxyCheckboxWrapper = styled.div`
  width: 16px;
`;
