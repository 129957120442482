import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { ProxyItemBase } from './styles';
import { GeoProxyType } from '../../../../../common/constants/types';
import { useGeoProxyLastSelectedType } from '../../../../state/proxy/geoproxy-form-data.atom';
import { useProxyContextMenuProxy } from '../../../../state/proxy/proxy-context-menu.atom';
import { createGeoProxy } from '../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { hideProxyContextMenu } from '../../../../state/proxy-select-menu.atom';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../../ui/gologin-header/icons/wrapper';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY } from '../../constants';

const PROXY_GROUP_ADD_BUTTON_STYLES: React.CSSProperties = {
  height: 36,
  // TODO: change margin when the design is ready, probably before the proxyGroups release
  margin: '4px 0 4px 45.5px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
};

type ProxyGroupAddButtonProps = {
  country: string;
  groupId: string;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
}

const ProxyGroupAddButton: React.FC<ProxyGroupAddButtonProps> = (props) => {
  const { country = DEFAULT_SELECTED_GEOPROXY_COUNTRY, groupId, availableTypes, style } = props;

  const proxyContextMenuProxy = useProxyContextMenuProxy();
  const geoProxyLastSelectedType = useGeoProxyLastSelectedType();

  const handleClick = async (): Promise<void> => {
    if (proxyContextMenuProxy) {
      hideProxyContextMenu();
    }

    if (!availableTypes.includes(geoProxyLastSelectedType)) {
      message.error(<Trans i18nKey='tableProfiles.notification.proxyNotFoundForCountryAndType' />);
      // TODO: sendReactErrorToSentry({ ... }) before the proxyGroups release

      return;
    }

    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      country,
      connectionType: geoProxyLastSelectedType,
      profileId: '',
      checkTooltipView: 'proxy-list-item', // TODO: test before the proxyGroups release
    });

    if (typeof geoProxyFormSubmitResult === 'string') {
      message.error(<Trans i18nKey={geoProxyFormSubmitResult} />);
    }
  };

  return (
    <ProxyItemBase
      onClick={handleClick}
      style={{ ...style, ...PROXY_GROUP_ADD_BUTTON_STYLES }}
    >
      <IconWrapperWithDescription
        iconColor='var(--B5B5BA-proxy-manager-placeholder)'
        iconHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        textColor='var(--81818A-proxy-manager-placeholder)'
        textHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        iconType='stroke'
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        <Trans i18nKey='proxies.proxyGroupAddButton.default' />
      </IconWrapperWithDescription>
    </ProxyItemBase>
  );
};

export default ProxyGroupAddButton;
